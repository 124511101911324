/** 
 * Kendo UI v2020.1.406 (http://www.telerik.com/kendo-ui)                                                                                                                                               
 * Copyright 2020 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.                                                                                      
 *                                                                                                                                                                                                      
 * Kendo UI commercial licenses may be obtained at                                                                                                                                                      
 * http://www.telerik.com/purchase/license-agreement/kendo-ui-complete                                                                                                                                  
 * If you do not own a commercial license, this file shall be governed by the trial license terms.                                                                                                      
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       

*/

.k-flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.k-flex-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.k-flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse
}

.k-flex {
    -ms-flex: 1 1 0px;
    flex: 1 1 0
}

.k-flex-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.k-no-flex {
    -ms-flex: 0 0 0px;
    flex: 0 0 0
}

.k-no-flex-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.k-flex-grow {
    -ms-flex-positive: 1;
    flex-grow: 1
}

.k-no-flex-grow {
    -ms-flex-positive: 0;
    flex-grow: 0
}

.k-flex-shrink {
    -ms-flex-negative: 1;
    flex-shrink: 1
}

.k-no-flex-shrink {
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.k-align-items-start {
    -ms-flex-align: start;
    align-items: flex-start
}

.k-align-items-end {
    -ms-flex-align: end;
    align-items: flex-end
}

.k-align-items-center {
    -ms-flex-align: center;
    align-items: center
}

.k-align-items-stretch {
    -ms-flex-align: stretch;
    align-items: stretch
}

.k-align-items-baseline {
    -ms-flex-align: baseline;
    align-items: baseline
}

.k-align-content-start {
    -ms-flex-line-pack: start;
    align-content: flex-start
}

.k-align-content-end {
    -ms-flex-line-pack: end;
    align-content: flex-end
}

.k-align-content-center {
    -ms-flex-line-pack: center;
    align-content: center
}

.k-align-content-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch
}

.k-align-content-baseline {
    -ms-flex-line-pack: baseline;
    align-content: baseline
}

.k-align-self-start {
    -ms-flex-item-align: start;
    align-self: flex-start
}

.k-align-self-end {
    -ms-flex-item-align: end;
    align-self: flex-end
}

.k-align-self-center {
    -ms-flex-item-align: center;
    align-self: center
}

.k-align-self-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch
}

.k-align-self-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline
}

.k-justify-content-start {
    -ms-flex-pack: start;
    justify-content: flex-start
}

.k-justify-content-end {
    -ms-flex-pack: end;
    justify-content: flex-end
}

.k-justify-content-center {
    -ms-flex-pack: center;
    justify-content: center
}

.k-justify-content-between {
    -ms-flex-pack: justify;
    justify-content: space-between
}

.k-justify-content-around {
    -ms-flex-pack: distribute;
    justify-content: space-around
}

.k-justify-content-evenly {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly
}

.k-hbox, .k-vbox {
    display: -ms-flexbox;
    display: flex
}

.k-ihbox, .k-ivbox {
    display: -ms-inline-flexbox;
    display: inline-flex
}

.k-hbox, .k-ihbox {
    -ms-flex-direction: row;
    flex-direction: row
}

.k-ivbox, .k-vbox {
    -ms-flex-direction: column;
    flex-direction: column
}

.k-column {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0
}

.fake {
    color: red
}

.k-common-test-class {
    opacity: 0
}

.k-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    font-size: 100%;
    list-style: none
}

.k-no-click {
    pointer-events: none
}

.k-floatwrap:after, .k-grid-toolbar:after, .k-slider-items:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    overflow: hidden
}

.k-floatwrap, .k-grid-toolbar, .k-slider-items {
    display: inline-block
}

.k-floatwrap, .k-grid-toolbar, .k-slider-items {
    display: block
}

.k-autocomplete, .k-autocomplete.k-state-hover, .k-block, .k-draghandle, .k-dropdown-wrap, .k-dropdown-wrap.k-state-hover, .k-grid-header, .k-grouping-header, .k-header, .k-link.k-state-hover, .k-numeric-wrap, .k-numeric-wrap.k-state-hover, .k-pager-wrap, .k-picker-wrap, .k-picker-wrap.k-state-hover, .k-tabstrip-items .k-item, .k-textbox, .k-textbox:hover, .k-toolbar, .k-tooltip {
    background-repeat: repeat;
    background-position: 0 center
}

.k-link:hover {
    text-decoration: none
}

.k-state-highlight > .k-link {
    color: inherit
}

.k-input[type=text], .k-input[type=number], .k-multiselect .k-input, .k-picker-wrap .k-input, .k-textbox, .k-textbox > input {
    font-size: 100%;
    font-family: inherit;
    border-style: solid;
    border-width: 1px;
    -webkit-appearance: none
}

.k-block, .k-draghandle, .k-inline-block, .k-widget {
    border-style: solid;
    border-width: 1px;
    -webkit-appearance: none
}

.k-block, .k-widget {
    line-height: normal;
    outline: 0
}

    .k-list-filter ::-ms-clear, .k-widget .k-input::-ms-clear {
        width: 0;
        height: 0
    }

.k-block, .k-content, .k-grid, .k-header-column-menu, .k-panelbar, .k-slider, .k-splitter, .k-treeview, .k-widget {
    outline: 0;
    -webkit-tap-highlight-color: transparent
}

.k-block, .k-calendar, .k-grid-header .k-i-link-horizontal, .k-header-column-menu, .k-pager-wrap, .k-slider, .k-splitbar, .k-treeview {
    -webkit-touch-callout: none
}

.k-header-column-menu {
    color: inherit
}

.k-block {
    padding: 2px
}

    .k-block > .k-header {
        margin: -2px 0 10px -2px;
        padding: .3em 2px;
        width: 100%;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        font-size: 1.2em;
        white-space: nowrap;
        min-height: 16px;
        position: relative
    }

a.k-button-expand {
    display: block
}

button.k-button-expand, input[type=submit].k-button-expand, input[type=button].k-button-expand, input[type=reset].k-button-expand {
    width: 100%
}

.k-toolbar .k-button-icontext {
    padding-right: .8em
}

.k-link {
    cursor: pointer;
    outline: 0;
    text-decoration: none
}

.k-grid-header span.k-link {
    cursor: default
}

.k-state-disabled, .k-state-disabled .k-draghandle, .k-state-disabled .k-icon, .k-state-disabled .k-link, .k-state-disabled .k-upload-button input {
    cursor: default !important;
    pointer-events: none;
    outline: 0
}

.k-state-disabled {
    opacity: .7
}

    .k-state-disabled > .k-button {
        opacity: 1
    }

@media print {
    .k-state-disabled, .k-state-disabled .k-input {
        opacity: 1 !important
    }
}

.k-state-error {
    border-style: ridge
}

.k-state-empty {
    font-style: italic
}

.k-icon.k-i-none {
    background-image: none !important
}

.k-image {
    border: 0
}

.k-widget .k-icon.k-i-collapse, .k-widget .k-icon.k-i-expand {
    cursor: pointer
}

html .k-success-colored {
    color: #507f50;
    border-color: #d0dfd0;
    background-color: #f0fff0
}

html .k-info-colored {
    color: #50607f;
    border-color: #d0d9df;
    background-color: #f0f9ff
}

html .k-error-colored {
    color: #7f5050;
    border-color: #dfd0d0;
    background-color: #fff0f0
}

.k-inline-block {
    padding: 0 2px
}

.k-loading, .k-loading-image {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center
}

.k-icon.k-i-loading {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center
}

    .k-icon.k-i-loading::before {
        content: "";
        display: none
    }

.k-loading-image, .k-loading-mask, .k-loading-text {
    position: absolute
}

.k-loading-mask {
    z-index: 100
}

    .k-loading-mask.k-opaque .k-loading-color {
        opacity: 1
    }

    .k-loading-mask .k-loading-progress {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0
    }

.k-loading-text {
    text-indent: -4000px;
    text-align: center
}

.k-loading-color, .k-loading-image {
    width: 100%;
    height: 100%
}

.k-loading-image {
    top: 0;
    left: 0;
    z-index: 2
}

.k-loading-color {
    filter: alpha(opacity=30);
    opacity: .3
}

.k-content-frame {
    border: 0;
    width: 100%;
    height: 100%
}

.k-pane > .k-splitter-overlay {
    filter: alpha(opacity=0);
    opacity: 0;
    position: absolute
}

.k-drag-clue {
    position: absolute;
    z-index: 10003;
    border-style: solid;
    border-width: 1px;
    font-size: .9em;
    padding: .2em .4em;
    white-space: nowrap;
    cursor: default
}

.k-item.k-drag-clue {
    font-size: inherit
}

.k-drag-status {
    margin-top: -3px;
    margin-right: 4px;
    vertical-align: middle
}

.k-reorder-cue {
    position: absolute;
    width: 1px;
    overflow: visible
}

    .k-reorder-cue .k-icon {
        position: absolute;
        left: -4px;
        width: 8px;
        height: 4px
    }

    .k-reorder-cue .k-i-arrow-60-down {
        top: -4px
    }

    .k-reorder-cue .k-i-arrow-60-up {
        bottom: -4px
    }

.k-scrollbar {
    position: absolute;
    overflow: scroll
}

.k-scrollbar-vertical {
    top: 0;
    right: 0;
    width: 17px;
    height: 100%;
    overflow-x: hidden
}

.k-touch-scrollbar {
    display: none;
    position: absolute;
    z-index: 200000;
    height: 8px;
    width: 8px;
    border: 1px solid #8a8a8a;
    background-color: #858585
}

@media only screen and (-webkit-min-device-pixel-ratio:2) {
    body .k-touch-scrollbar {
        height: 12px;
        width: 12px;
        border-radius: 7px
    }
}

.k-virtual-scrollable-wrap {
    overflow-x: auto
}

.k-current-time {
    background: red;
    position: absolute
}

.k-current-time-arrow-down {
    width: 0;
    height: 0;
    background: 0 0;
    border-bottom: 4px solid transparent;
    border-top: 4px solid red;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent
}

.k-current-time-arrow-left {
    width: 0;
    height: 0;
    background: 0 0;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    border-left: 4px solid transparent;
    border-right: 4px solid red
}

.k-current-time-arrow-right {
    width: 0;
    height: 0;
    background: 0 0;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    border-left: 4px solid red;
    border-right: 4px solid transparent
}

.k-animation-container, .k-animation-container *, .k-animation-container :after, .k-block .k-header, .k-dialog .k-window-content, .k-list-container, .k-widget, .k-widget *, .k-widget :before {
    box-sizing: content-box
}

.k-autocomplete, .k-block, .k-edit-cell .k-widget, .k-grid-edit-row .k-widget, .k-grid-edit-row .text-box, .k-tabstrip > .k-content > .km-scroll-container, .k-textbox, .km-actionsheet > li, .km-shim, div.k-window-content {
    box-sizing: border-box
}

.input-group .form-control {
    box-sizing: border-box
}

.form-control.k-widget {
    padding: 0
}

.km-widget, .km-widget * {
    -moz-background-clip: border-box;
    -webkit-background-clip: border-box;
    background-clip: border-box
}

.k-pdf-export-shadow {
    position: absolute;
    overflow: hidden;
    left: -15000px;
    width: 14400px
}

.km-native-scroller {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-touch-action: pan-x pan-y;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-scroll-snap-type: proximity
}

.k-hr {
    margin: .5em auto;
    padding: 0;
    height: 0;
    border-width: 1px 0 0;
    border-style: solid;
    display: block;
    float: none;
    clear: both
}

.k-icon, .k-tool-icon {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 1em;
    height: 1em;
    text-align: center;
    vertical-align: middle;
    background-image: none;
    font: 16px/1 WebComponentsIcons;
    speak: none;
    font-variant: normal;
    text-transform: none;
    text-indent: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit
}

.k-column-menu .k-sprite, .k-gantt-views > .k-current-view > .k-link:after, .k-grid-mobile .k-resize-handle-inner:after, .k-grid-mobile .k-resize-handle-inner:before, .k-i-drag-and-drop, .k-pager-numbers .k-current-page .k-link:after, .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 1em;
    height: 1em;
    text-align: center;
    vertical-align: middle;
    background-image: none;
    font: 16px/1 WebComponentsIcons;
    speak: none;
    font-variant: normal;
    text-transform: none;
    text-indent: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.k-icon:before {
    margin: auto;
    width: 1em;
    height: 1em;
    line-height: 1;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

.k-button.k-button-icon .k-icon, .k-grid-filter .k-icon, .k-header .k-icon {
    text-indent: -99999px;
    overflow: hidden
}

    .k-button.k-button-icon .k-icon:before, .k-grid-filter .k-icon:before, .k-header .k-icon:before {
        text-indent: 0
    }

.k-sprite {
    width: 16px;
    height: 16px;
    font-size: 0;
    line-height: 0;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    -ms-high-contrast-adjust: none
}

.k-list-container .k-footer:after, .k-list-container .k-footer:before {
    content: "";
    display: block;
    overflow: hidden
}

.k-reorder-cue {
    position: absolute
}

    .k-reorder-cue::after, .k-reorder-cue::before {
        content: "";
        width: 0;
        height: 0;
        border: 3px solid transparent;
        position: absolute;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .k-reorder-cue::before {
        border-bottom-width: 0;
        border-top-color: currentColor;
        top: -4px
    }

    .k-reorder-cue::after {
        border-top-width: 0;
        border-bottom-color: currentColor;
        bottom: -4px
    }

.k-flip-h {
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1)
}

.k-flip-v {
    -webkit-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1)
}

.k-flip-h.k-flip-v {
    -webkit-transform: scale(-1,-1);
    -ms-transform: scale(-1,-1);
    transform: scale(-1,-1)
}

.k-text-align-left, .k-text-left {
    text-align: left
}

.k-text-align-center, .k-text-center {
    text-align: center
}

.k-text-align-right, .k-text-right {
    text-align: right
}

.k-text-align-justify, .k-text-justify {
    text-align: justify
}

.k-button {
    margin: 0;
    padding: 4px 8px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    background-repeat: repeat-x;
    background-position: 0 center;
    font: inherit;
    line-height: 1.42857143;
    text-align: center;
    text-decoration: none;
    display: -ms-inline-flexbox;
    display: inline-flex;
    overflow: hidden;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    -webkit-appearance: none;
    position: relative
}

.k-no-flexbox .k-button {
    display: inline-block
}

.k-button::-moz-focus-inner {
    padding: 0;
    border: 0;
    outline: 0
}

.k-button:focus, .k-button:hover {
    text-decoration: none;
    outline: 0
}

.k-button .k-text {
    overflow: hidden
}

.k-button .k-icon, .k-button .k-image, .k-button .k-sprite {
    color: inherit;
    -ms-flex-item-align: center;
    align-self: center;
    pointer-events: none
}

.k-button-icontext {
    overflow: visible
}

    .k-button-icontext .k-icon, .k-button-icontext .k-image, .k-button-icontext .k-sprite {
        margin-right: 3px;
        margin-right: .1875em;
        margin-left: -2px;
        margin-left: -.125em
    }

.k-button.k-state-disabled, .k-button[disabled], .k-state-disabled .k-button {
    cursor: default;
    outline: 0;
    box-shadow: none;
    opacity: .6
}

.k-ie9 .k-button {
    display: inline-block
}

a.k-button-expand {
    display: block
}

button.k-button-expand, input[type=submit].k-button-expand, input[type=button].k-button-expand, input[type=reset].k-button-expand {
    width: 100%
}

.k-button-icon {
    height: calc(1.4285714286em + 10px)
}

.k-split-button-arrow {
    display: inline-block
}

.k-button-icon, .k-split-button-arrow {
    width: calc(1.4285714286em + 10px);
    padding: 4px 4px
}

.k-overflow-group .k-button-icon {
    width: auto
}

.k-button.k-bare, .k-button.k-flat {
    border-color: transparent !important;
    color: inherit;
    background: 0 0 !important;
    box-shadow: none !important;
    transition: color .2s ease-in-out
}

    .k-button.k-bare.k-state-active, .k-button.k-bare.k-state-hover, .k-button.k-bare:active, .k-button.k-bare:hover, .k-button.k-bare:hover.k-state-active, .k-button.k-bare:hover:active, .k-button.k-flat.k-state-active, .k-button.k-flat.k-state-hover, .k-button.k-flat:active, .k-button.k-flat:hover, .k-button.k-flat:hover.k-state-active, .k-button.k-flat:hover:active {
        color: inherit
    }

    .k-button.k-bare::before, .k-button.k-flat::before {
        display: block
    }

    .k-button.k-bare::after, .k-button.k-flat::after {
        display: block
    }

    .k-button.k-bare.k-state-focused::after, .k-button.k-bare:focus::after, .k-button.k-flat.k-state-focused::after, .k-button.k-flat:focus::after {
        box-shadow: inset 0 0 0 2px currentColor;
        opacity: .12
    }

    .k-button.k-bare.k-state-disabled, .k-button.k-bare[disabled], .k-button.k-flat.k-state-disabled, .k-button.k-flat[disabled], .k-state-disabled .k-button.k-bare, .k-state-disabled .k-button.k-flat {
        pointer-events: none
    }

.k-button::before {
    border-radius: inherit;
    content: "";
    background: currentColor;
    opacity: 0;
    display: none;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    transition: opacity .2s ease-in-out
}

.k-button.k-state-hover::before, .k-button:hover::before {
    opacity: .08
}

.k-button.k-no-focus:not(.k-state-hover)::before, .k-button.k-no-focus:not(:hover)::before {
    opacity: 0
}

.k-button.k-state-active::before, .k-button:active::before {
    opacity: .16
}

.k-button.k-state-selected::before {
    opacity: .2
}

.k-button::after {
    border-radius: inherit;
    content: "";
    opacity: 0;
    display: none;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    transition: opacity .2s ease-in-out
}

.k-button-group {
    margin: 0;
    padding: 0;
    border-width: 0;
    list-style: none;
    white-space: nowrap;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    vertical-align: middle;
    position: relative;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

    .k-button-group .k-button {
        position: relative
    }

        .k-button-group .k-button + .k-button {
            margin-left: -1px
        }

    .k-button-group.k-button-group-stretched .k-button {
        -ms-flex: 1 0 auto;
        flex: 1 0 auto
    }

    .k-button-group .k-button.k-state-active, .k-button-group .k-button:active {
        z-index: 2
    }

    .k-button-group .k-button.k-state-hover, .k-button-group .k-button:hover {
        z-index: 3
    }

    .k-button-group .k-button.k-state-disabled, .k-button-group.k-state-disabled .k-button {
        z-index: auto
    }

    .k-button-group .k-button.k-state-focused, .k-button-group .k-button:focus {
        z-index: 4
    }

    .k-button-group label input[type=radio], .k-button-group label input[type=checkbox], .k-button-group > input[type=radio], .k-button-group > input[type=checkbox] {
        margin: 0;
        padding: 0;
        clip: rect(0,0,0,0);
        position: absolute;
        pointer-events: none
    }

.k-overflow-container .k-overflow-tool-group {
    display: block
}

.k-rtl .k-button-group .k-button {
    margin-left: 0
}

    .k-rtl .k-button-group .k-button + .k-button {
        margin-right: -1px
    }

.k-no-flexbox .k-button-group {
    display: inline-block
}

    .k-no-flexbox .k-button-group:after {
        content: "";
        display: block;
        clear: both
    }

    .k-no-flexbox .k-button-group .k-button {
        display: inline-block;
        vertical-align: top
    }

.k-action-buttons {
    margin: 1em 0 0;
    padding: 6px 8px;
    text-align: right;
    position: relative;
    clear: both
}

    .k-action-buttons .k-button {
        min-width: 75px
    }

        .k-action-buttons .k-button + .k-button {
            margin-left: 6px
        }

        .k-action-buttons .k-button.k-left {
            float: left;
            margin: 0 0 0 1em
        }

.k-rpanel-left {
    -webkit-transform: translateX(-100%) translateZ(0);
    -ms-transform: translateX(-100%) translateZ(0);
    transform: translateX(-100%) translateZ(0);
    left: 0
}

.k-rpanel-right {
    -webkit-transform: translateX(100%) translateZ(0);
    -ms-transform: translateX(100%) translateZ(0);
    transform: translateX(100%) translateZ(0);
    right: 0
}

.k-rpanel-left, .k-rpanel-right {
    position: fixed;
    display: block;
    overflow: auto;
    min-width: 320px;
    height: 100%;
    top: 0
}

    .k-rpanel-left.k-rpanel-expanded, .k-rpanel-right.k-rpanel-expanded {
        -webkit-transform: translateX(0) translateZ(0);
        -ms-transform: translateX(0) translateZ(0);
        transform: translateX(0) translateZ(0)
    }

    .k-rpanel-left + *, .k-rpanel-right + * {
        overflow: auto
    }

.k-ie9 .k-rpanel-left {
    left: -100%
}

    .k-ie9 .k-rpanel-left.k-rpanel-expanded {
        left: 0
    }

.k-rpanel-top {
    position: static;
    max-height: 0
}

    .k-rpanel-top.k-rpanel-expanded {
        max-height: 568px;
        overflow: visible !important
    }

.k-rpanel-toggle {
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

.k-edit-form {
    margin: 0;
    padding: 0
}

.k-window > div.k-popup-edit-form {
    padding: 1em 0
}

.k-grid-edit-row .k-edit-form td {
    border-bottom-width: 0
}

.k-edit-form-container {
    position: relative;
    width: 400px
}

    .k-edit-form-container .editor-label, .k-edit-label {
        float: left;
        clear: both;
        width: 30%;
        padding: .4em 0 1em;
        margin-left: 2%;
        text-align: right
    }

    .k-edit-field, .k-edit-form-container .editor-field {
        float: right;
        clear: right;
        width: 60%;
        margin-right: 2%;
        padding: 0 0 .6em
    }

        .k-edit-field.k-no-editor, .k-edit-form-container .editor-field.k-no-editor {
            padding: .4em 0 1em
        }

    .k-edit-form-container .k-button {
        margin: 0 .16em
    }

.k-edit-field > .k-button:first-child {
    margin-left: 0
}

.k-edit-form-container .k-edit-buttons {
    clear: both;
    text-align: right;
    border-width: 1px 0 0;
    border-style: solid;
    position: relative;
    bottom: -1em;
    padding: .6em
}

.k-window {
    padding: 0;
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    position: absolute;
    z-index: 10001
}

    .k-window .k-overlay {
        position: absolute;
        opacity: 0
    }

.k-window-sm {
    width: 300px
}

.k-window-md {
    width: 800px
}

.k-window-lg {
    width: 1200px
}

.k-window-titlebar {
    padding: 8px 12px;
    border-width: 0 0 1px;
    border-style: solid;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    min-height: 16px
}

.k-window-title {
    font-size: 1.2em;
    line-height: 1.25;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: default
}

    .k-window-title::before {
        content: "";
        display: inline-block
    }

.k-window-titlebar .k-window-actions {
    position: absolute;
    top: 4px;
    right: 6px
}

.k-window-titlebar .k-window-action {
    padding: 2px;
    width: 20px;
    height: 20px;
    border-width: 0;
    box-sizing: content-box;
    opacity: .7
}

    .k-window-titlebar .k-window-action:hover {
        opacity: 1
    }

.k-prompt-container, .k-window-content {
    padding: 12px 12px;
    height: 100%;
    overflow: auto;
    position: relative;
    outline: 0
}

    .k-window-content + .k-prompt-container {
        margin-top: -12px
    }

    .k-prompt-container .k-textbox {
        width: 100%
    }

.k-window-iframecontent {
    padding: 0;
    overflow: visible
}

    .k-window-iframecontent .k-content-frame {
        vertical-align: top;
        border: 0;
        width: 100%;
        height: 100%
    }

.k-window-content > .km-scroll-container {
    height: 100%
}

.k-window > .k-resize-handle {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    font-size: 0;
    line-height: 6px;
    opacity: 0;
    zoom: 1
}

.k-resize-n {
    top: -3px;
    left: 0;
    width: 100%;
    height: 6px;
    cursor: n-resize
}

.k-resize-e {
    top: 0;
    right: -3px;
    width: 6px;
    height: 100%;
    cursor: e-resize
}

.k-resize-s {
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 6px;
    cursor: s-resize
}

.k-resize-w {
    top: 0;
    left: -3px;
    width: 6px;
    height: 100%;
    cursor: w-resize
}

.k-resize-se {
    bottom: -3px;
    right: -3px;
    width: 6px;
    height: 6px;
    cursor: se-resize
}

.k-resize-sw {
    bottom: -3px;
    left: -3px;
    width: 6px;
    height: 6px;
    cursor: sw-resize
}

.k-resize-ne {
    top: -3px;
    right: -3px;
    width: 6px;
    height: 6px;
    cursor: ne-resize
}

.k-resize-nw {
    top: -3px;
    left: -3px;
    width: 6px;
    height: 6px;
    cursor: nw-resize
}

.k-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .5;
    -webkit-backface-visibility: hidden
}

.k-window-content > .k-action-buttons, .k-window > .k-action-buttons {
    border-width: 1px 0 0;
    border-style: solid
}

.k-ie .k-window-content {
    border-radius: 0
}

.k-tabstrip {
    margin: 0;
    padding: 0;
    zoom: 1;
    position: relative
}

.k-tabstrip-items {
    padding: .3em .3em 0
}

.k-tabstrip-scrollable .k-tabstrip-items {
    white-space: nowrap;
    overflow: hidden
}

.k-tabstrip > .k-button.k-bare {
    position: absolute;
    top: .27em;
    z-index: 2;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.k-tabstrip-bottom > .k-button.k-bare {
    top: auto;
    bottom: .15em
}

.k-tabstrip-prev {
    left: .4em
}

.k-tabstrip-next {
    right: .4em
}

.k-panelbar .k-tabstrip-items .k-item, .k-tabstrip-items .k-item {
    list-style-type: none;
    display: inline-block;
    position: relative;
    border-style: solid;
    border-width: 1px 1px 0;
    padding: 0;
    vertical-align: top
}

.k-tabstrip-items > .k-item {
    overflow: hidden
}

.k-panelbar .k-tabstrip-items .k-state-active, .k-tabstrip-items .k-state-active, .k-tabstrip-items .k-tab-on-top {
    margin-bottom: -1px;
    padding-bottom: 1px
}

.k-panelbar .k-tabstrip-items .k-item, .k-tabstrip-top > .k-tabstrip-items .k-item {
    margin: 0 -1px 0 0
}

.k-panelbar .k-tabstrip-top > .k-tabstrip-items .k-state-active, .k-tabstrip-top > .k-tabstrip-items .k-state-active {
    border-bottom-width: 1px;
    margin-bottom: -1px;
    padding-bottom: 0
}

.k-tabstrip-items .k-tab-on-top {
    z-index: 1
}

.k-panelbar .k-tabstrip-items .k-link, .k-tabstrip-items .k-link {
    display: inline-block;
    border-bottom-width: 0;
    padding: .5em .92em
}

.k-panelbar .k-tabstrip-items .k-icon, .k-tabstrip-items .k-icon {
    margin: -1px 4px 0 -3px;
    vertical-align: top
}

.k-panelbar .k-tabstrip-items .k-item .k-image, .k-panelbar .k-tabstrip-items .k-item .k-sprite, .k-tabstrip-items .k-item .k-image, .k-tabstrip-items .k-item .k-sprite {
    margin: -3px 3px 0 -6px;
    vertical-align: middle
}

.k-tabstrip-items .k-loading {
    top: 0;
    left: 0;
    height: 0;
    width: 20%;
    position: absolute;
    background: 0 0;
    border-top: 1px solid transparent;
    border-color: inherit;
    transition: width .2s linear;
    -webkit-animation: k-tab-loader 1s ease-in-out infinite;
    animation: k-tab-loader 1s ease-in-out infinite
}

.k-tabstrip-items .k-progress {
    -webkit-animation: none;
    animation: none
}

.k-tabstrip-items .k-loading.k-complete {
    width: 100%;
    -webkit-animation: none;
    animation: none
}

.k-panelbar .k-tabstrip > .k-content, .k-tabstrip > .k-content {
    position: static;
    border-style: solid;
    border-width: 1px;
    margin: 0 .286em .3em;
    padding: .92em;
    zoom: 1
}

.k-tabstrip > .k-content {
    display: none;
    overflow: auto
}

    .k-tabstrip > .k-content.km-scroll-wrapper {
        padding: 0
    }

    .k-tabstrip > .k-content > .km-scroll-container {
        padding: .3em .92em
    }

@-webkit-keyframes k-tab-loader {
    0% {
        left: 0
    }

    50% {
        left: 80%
    }

    100% {
        left: 0
    }
}

@keyframes k-tab-loader {
    0% {
        left: 0
    }

    50% {
        left: 80%
    }

    100% {
        left: 0
    }
}

.k-tabstrip-left > div.k-content, .k-tabstrip-right > div.k-content {
    margin: .286em .3em
}

.k-tabstrip-left > .k-tabstrip-items .k-item, .k-tabstrip-right > .k-tabstrip-items .k-item {
    display: block;
    margin-bottom: -1px
}

.k-tabstrip-left > .k-tabstrip-items .k-link, .k-tabstrip-right > .k-tabstrip-items .k-link {
    display: block
}

.k-panelbar .k-tabstrip-left > .k-tabstrip-items .k-state-active, .k-panelbar .k-tabstrip-right > .k-tabstrip-items .k-state-active, .k-tabstrip-left > .k-tabstrip-items .k-state-active, .k-tabstrip-left > .k-tabstrip-items .k-tab-on-top, .k-tabstrip-right > .k-tabstrip-items .k-state-active, .k-tabstrip-right > .k-tabstrip-items .k-tab-on-top {
    margin-bottom: -1px;
    padding-bottom: 0
}

.k-tabstrip-left > .k-tabstrip-items {
    float: left;
    padding: .25em 0 .3em .3em
}

    .k-tabstrip-left > .k-tabstrip-items .k-item {
        border-width: 1px 0 1px 1px;
        border-radius: 3px 0 0 3px
    }

    .k-tabstrip-left > .k-tabstrip-items .k-state-active {
        border-width: 1px 0 1px 1px
    }

    .k-panelbar .k-tabstrip-left > .k-tabstrip-items .k-state-active, .k-tabstrip-left > .k-tabstrip-items .k-state-active, .k-tabstrip-left > .k-tabstrip-items .k-tab-on-top {
        margin-right: -1px;
        padding-right: 1px
    }

.k-tabstrip-right > .k-tabstrip-items {
    float: right;
    padding: .25em .3em .3em 0
}

    .k-tabstrip-right > .k-tabstrip-items .k-item {
        border-width: 1px 1px 1px 0;
        border-radius: 0 3px 3px 0
    }

    .k-tabstrip-right > .k-tabstrip-items .k-state-active {
        border-width: 1px 1px 1px 0
    }

    .k-panelbar .k-tabstrip-right > .k-tabstrip-items .k-state-active, .k-tabstrip-right > .k-tabstrip-items .k-state-active, .k-tabstrip-right > .k-tabstrip-items .k-tab-on-top {
        margin-left: -1px;
        padding-left: 1px
    }

.k-tabstrip-bottom > .k-tabstrip-items {
    margin-top: -1px;
    padding: 0 .3em .3em
}

.k-panelbar .k-tabstrip-bottom > .k-content, .k-tabstrip-bottom > .k-content {
    margin: .3em .286em 0;
    z-index: 1;
    position: relative
}

.k-tabstrip-bottom > .k-tabstrip-items .k-item {
    border-width: 0 1px 1px;
    border-radius: 0 0 4px 4px
}

.k-tabstrip-bottom > .k-tabstrip-items .k-state-active {
    margin-bottom: 0;
    padding-bottom: 0
}

.k-tabstrip-bottom > .k-content {
    min-height: 100px
}

.k-tabstrip-bottom > .k-tabstrip-items .k-loading {
    top: auto;
    bottom: 0
}

.k-panelbar {
    zoom: 1
}

    .k-panel > .k-item, .k-panelbar > .k-item {
        list-style-type: none;
        display: block;
        border-width: 0;
        margin: 0;
        zoom: 1;
        border-radius: 0
    }

    .k-panelbar .k-link > .k-image, .k-panelbar .k-link > .k-sprite {
        margin-top: -2px;
        margin-right: 5px;
        vertical-align: middle
    }

    .k-panel > .k-item > .k-link, .k-panelbar > .k-item > .k-link {
        color: inherit;
        display: block;
        position: relative;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        padding: 0 1em;
        line-height: 2.34em;
        text-decoration: none;
        zoom: 1
    }

.k-panelbar-collapse.k-icon, .k-panelbar-expand.k-icon {
    position: absolute;
    top: 50%;
    right: 4px;
    margin-top: -8px
}

.k-panelbar .k-content, .k-panelbar .k-panel {
    position: relative;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    margin: 0;
    padding: 0;
    zoom: 1
}

.k-panel > .k-item > .k-link {
    border-bottom: 0;
    font-size: .95em;
    line-height: 2.2
}

.k-panel .k-panel > .k-item > .k-link {
    padding-left: 2em
}

.k-panelbar .k-i-arrow-end-right .k-link {
    border-bottom: 0
}

.k-panel .k-panel {
    border-bottom: 0
}

.k-pager-wrap {
    padding: .333em .25em;
    border-width: 1px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    cursor: default;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

    .k-pager-wrap .k-link, .k-pager-wrap .k-state-selected {
        width: 2em;
        height: 2em;
        margin: .08333em;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        text-align: center;
        border-style: solid;
        border-width: 1px;
        border-radius: 1.0833em;
        z-index: 1
    }

        .k-pager-wrap .k-link > .k-icon, .k-pager-wrap .k-state-selected > .k-icon {
            vertical-align: middle
        }

        .k-pager-wrap .k-link:hover, .k-pager-wrap .k-state-selected {
            z-index: 2
        }

        .k-pager-wrap .k-link:focus, .k-pager-wrap .k-state-selected {
            text-decoration: none;
            outline: 0
        }

        .k-pager-wrap .k-link.k-state-disabled {
            color: inherit
        }

    .k-pager-wrap > .k-link {
        border-style: solid;
        border-width: 1px
    }

    .k-pager-wrap .k-pager-numbers-wrap {
        width: auto;
        height: auto;
        position: relative;
        display: -ms-inline-flexbox;
        display: inline-flex
    }

    .k-pager-wrap .k-pager-numbers {
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-direction: row;
        flex-direction: row
    }

        .k-pager-wrap .k-pager-numbers li {
            display: inline-block
        }

        .k-pager-wrap .k-pager-numbers .k-current-page {
            display: none
        }

            .k-pager-wrap .k-pager-numbers .k-current-page + li {
                margin-left: 0
            }

    .k-pager-wrap .k-label {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 1em
    }

    .k-pager-wrap .k-pager-input .k-textbox {
        margin: 0 1ex;
        width: 3em
    }

    .k-pager-wrap .k-pager-sizes .k-dropdown, .k-pager-wrap .k-pager-sizes > select {
        margin: 0 .4em 0 0;
        width: 5.2em
    }

    .k-pager-wrap .k-pager-refresh {
        -ms-flex-order: 10;
        order: 10;
        border-width: 0
    }

    .k-pager-wrap .k-pager-info {
        -ms-flex: 1;
        flex: 1;
        text-align: right;
        -ms-flex-order: 9;
        order: 9;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .k-pager-wrap .k-widget {
        font-size: inherit
    }

    .k-pager-wrap[dir=rtl] .k-i-arrow-60-left, .k-pager-wrap[dir=rtl] .k-i-arrow-60-right, .k-pager-wrap[dir=rtl] .k-i-arrow-end-left, .k-pager-wrap[dir=rtl] .k-i-arrow-end-right, .k-rtl .k-pager-wrap .k-i-arrow-60-left, .k-rtl .k-pager-wrap .k-i-arrow-60-right, .k-rtl .k-pager-wrap .k-i-arrow-end-left, .k-rtl .k-pager-wrap .k-i-arrow-end-right, [dir=rtl] .k-pager-wrap .k-i-arrow-60-left, [dir=rtl] .k-pager-wrap .k-i-arrow-60-right, [dir=rtl] .k-pager-wrap .k-i-arrow-end-left, [dir=rtl] .k-pager-wrap .k-i-arrow-end-right {
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1);
        transform: scaleX(-1)
    }

    .k-pager-wrap[dir=rtl] .k-pager-sizes .k-dropdown, .k-pager-wrap[dir=rtl] .k-pager-sizes > select, .k-rtl .k-pager-wrap .k-pager-sizes .k-dropdown, .k-rtl .k-pager-wrap .k-pager-sizes > select, [dir=rtl] .k-pager-wrap .k-pager-sizes .k-dropdown, [dir=rtl] .k-pager-wrap .k-pager-sizes > select {
        margin-right: 0;
        margin-left: 2em
    }

    .k-pager-wrap.k-pager-lg, .k-pager-wrap.k-pager-md, .k-pager-wrap.k-pager-sm {
        position: relative;
        overflow: visible
    }

        .k-pager-wrap.k-pager-lg .k-pager-numbers-wrap, .k-pager-wrap.k-pager-md .k-pager-numbers-wrap, .k-pager-wrap.k-pager-sm .k-pager-numbers-wrap {
            width: calc(4em + 2px);
            height: calc(2em + 2px);
            box-sizing: border-box;
            border-color: inherit;
            margin: 0 .08333em
        }

        .k-pager-wrap.k-pager-lg .k-pager-numbers, .k-pager-wrap.k-pager-md .k-pager-numbers, .k-pager-wrap.k-pager-sm .k-pager-numbers {
            position: absolute;
            overflow: hidden;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            height: auto;
            margin: 0;
            bottom: 0;
            border-style: solid;
            border-width: 1px;
            border-color: inherit;
            z-index: 3;
            border-radius: 1.0833em
        }

            .k-pager-wrap.k-pager-lg .k-pager-numbers li:not(.k-current-page), .k-pager-wrap.k-pager-md .k-pager-numbers li:not(.k-current-page), .k-pager-wrap.k-pager-sm .k-pager-numbers li:not(.k-current-page) {
                display: none
            }

            .k-pager-wrap.k-pager-lg .k-pager-numbers .k-current-page, .k-pager-wrap.k-pager-md .k-pager-numbers .k-current-page, .k-pager-wrap.k-pager-sm .k-pager-numbers .k-current-page {
                display: -ms-inline-flexbox;
                display: inline-flex;
                cursor: pointer
            }

            .k-pager-wrap.k-pager-lg .k-pager-numbers .k-link, .k-pager-wrap.k-pager-lg .k-pager-numbers .k-state-selected, .k-pager-wrap.k-pager-md .k-pager-numbers .k-link, .k-pager-wrap.k-pager-md .k-pager-numbers .k-state-selected, .k-pager-wrap.k-pager-sm .k-pager-numbers .k-link, .k-pager-wrap.k-pager-sm .k-pager-numbers .k-state-selected {
                margin: 0;
                border-width: 0;
                width: 4em;
                height: 2em
            }

            .k-pager-wrap.k-pager-lg .k-pager-numbers.k-state-expanded, .k-pager-wrap.k-pager-md .k-pager-numbers.k-state-expanded, .k-pager-wrap.k-pager-sm .k-pager-numbers.k-state-expanded {
                border-radius: 3px 3px 1.0833em 1.0833em
            }

                .k-pager-wrap.k-pager-lg .k-pager-numbers.k-state-expanded .k-pager-nav, .k-pager-wrap.k-pager-md .k-pager-numbers.k-state-expanded .k-pager-nav, .k-pager-wrap.k-pager-sm .k-pager-numbers.k-state-expanded .k-pager-nav {
                    border-radius: 0 0 1.0833em 1.0833em
                }

                .k-pager-wrap.k-pager-lg .k-pager-numbers.k-state-expanded li, .k-pager-wrap.k-pager-md .k-pager-numbers.k-state-expanded li, .k-pager-wrap.k-pager-sm .k-pager-numbers.k-state-expanded li {
                    display: -ms-inline-flexbox;
                    display: inline-flex
                }

                .k-pager-wrap.k-pager-lg .k-pager-numbers.k-state-expanded .k-current-page, .k-pager-wrap.k-pager-md .k-pager-numbers.k-state-expanded .k-current-page, .k-pager-wrap.k-pager-sm .k-pager-numbers.k-state-expanded .k-current-page {
                    border-radius: 0 0 1.0833em 1.0833em;
                    border-style: solid;
                    border-width: 1px 0 0;
                    border-color: inherit
                }

                .k-pager-wrap.k-pager-lg .k-pager-numbers.k-state-expanded .k-link, .k-pager-wrap.k-pager-lg .k-pager-numbers.k-state-expanded .k-state-selected, .k-pager-wrap.k-pager-md .k-pager-numbers.k-state-expanded .k-link, .k-pager-wrap.k-pager-md .k-pager-numbers.k-state-expanded .k-state-selected, .k-pager-wrap.k-pager-sm .k-pager-numbers.k-state-expanded .k-link, .k-pager-wrap.k-pager-sm .k-pager-numbers.k-state-expanded .k-state-selected {
                    border-radius: 3px
                }

                .k-pager-wrap.k-pager-lg .k-pager-numbers.k-state-expanded .k-pager-nav, .k-pager-wrap.k-pager-md .k-pager-numbers.k-state-expanded .k-pager-nav, .k-pager-wrap.k-pager-sm .k-pager-numbers.k-state-expanded .k-pager-nav {
                    border-radius: 0
                }

        .k-pager-wrap.k-pager-lg[dir=rtl] .k-pager-numbers + .k-link, .k-pager-wrap.k-pager-lg[dir=rtl] .k-pager-numbers + .k-pager-input, .k-pager-wrap.k-pager-md[dir=rtl] .k-pager-numbers + .k-link, .k-pager-wrap.k-pager-md[dir=rtl] .k-pager-numbers + .k-pager-input, .k-pager-wrap.k-pager-sm[dir=rtl] .k-pager-numbers + .k-link, .k-pager-wrap.k-pager-sm[dir=rtl] .k-pager-numbers + .k-pager-input, .k-rtl .k-pager-wrap.k-pager-lg .k-pager-numbers + .k-link, .k-rtl .k-pager-wrap.k-pager-lg .k-pager-numbers + .k-pager-input, .k-rtl .k-pager-wrap.k-pager-md .k-pager-numbers + .k-link, .k-rtl .k-pager-wrap.k-pager-md .k-pager-numbers + .k-pager-input, .k-rtl .k-pager-wrap.k-pager-sm .k-pager-numbers + .k-link, .k-rtl .k-pager-wrap.k-pager-sm .k-pager-numbers + .k-pager-input, [dir=rtl] .k-pager-wrap.k-pager-lg .k-pager-numbers + .k-link, [dir=rtl] .k-pager-wrap.k-pager-lg .k-pager-numbers + .k-pager-input, [dir=rtl] .k-pager-wrap.k-pager-md .k-pager-numbers + .k-link, [dir=rtl] .k-pager-wrap.k-pager-md .k-pager-numbers + .k-pager-input, [dir=rtl] .k-pager-wrap.k-pager-sm .k-pager-numbers + .k-link, [dir=rtl] .k-pager-wrap.k-pager-sm .k-pager-numbers + .k-pager-input {
            margin-left: 0
        }

        .k-pager-wrap.k-pager-md .k-pager-info, .k-pager-wrap.k-pager-sm .k-pager-info {
            display: none
        }

        .k-pager-wrap.k-pager-md .k-pager-refresh, .k-pager-wrap.k-pager-sm .k-pager-refresh {
            margin-left: auto
        }

        .k-pager-wrap.k-pager-md[dir=rtl] .k-pager-refresh, .k-pager-wrap.k-pager-sm[dir=rtl] .k-pager-refresh, .k-rtl .k-pager-wrap.k-pager-md .k-pager-refresh, .k-rtl .k-pager-wrap.k-pager-sm .k-pager-refresh, [dir=rtl] .k-pager-wrap.k-pager-md .k-pager-refresh, [dir=rtl] .k-pager-wrap.k-pager-sm .k-pager-refresh {
            margin-left: 0;
            margin-right: auto
        }

        .k-pager-wrap.k-pager-sm .k-pager-sizes {
            display: none
        }

.k-menu {
    cursor: default
}

    .k-menu .k-link {
        white-space: nowrap
    }

    .k-menu .k-item > .k-link {
        color: inherit
    }

    .k-menu, .k-menu .k-menu-group, .k-menu-scroll-wrapper .k-menu-group, .k-popups-wrapper .k-menu-group {
        list-style: none;
        margin: 0;
        padding: 0;
        zoom: 1
    }

        .k-menu:after {
            content: '';
            display: block;
            width: 99%;
            height: 0;
            float: inherit;
            clear: both
        }

        .k-menu .k-item, .k-menu-scroll-wrapper .k-item, .k-popups-wrapper .k-item {
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none
        }

            .k-menu .k-item .k-item, .k-menu-scroll-wrapper .k-item .k-item, .k-menu-scroll-wrapper.vertical > .k-item, .k-popups-wrapper .k-item .k-item, .k-popups-wrapper.vertical > .k-item, ul.k-menu-vertical > .k-item {
                display: block;
                float: none;
                border-width: 0
            }

            .k-menu .k-image, .k-menu .k-item > .k-link > .k-icon, .k-menu .k-sprite, .k-menu-scroll-wrapper .k-image, .k-menu-scroll-wrapper .k-item > .k-link > .k-icon, .k-menu-scroll-wrapper .k-sprite, .k-popups-wrapper .k-image, .k-popups-wrapper .k-item > .k-link > .k-icon, .k-popups-wrapper .k-sprite {
                margin: -2px 4px 0 -4px;
                vertical-align: middle
            }

            .k-menu .k-item > .k-link, .k-menu-scroll-wrapper .k-item > .k-link, .k-popups-wrapper .k-item > .k-link {
                display: block;
                padding: .5em 1.1em .4em;
                line-height: 1.34em;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none
            }

        .k-menu .k-menu-group, .k-menu-scroll-wrapper .k-menu-group, .k-popups-wrapper .k-menu-group {
            display: none;
            border-style: solid;
            border-width: 1px;
            overflow: visible;
            white-space: nowrap
        }

            .k-menu .k-menu-group > .k-item, .k-menu-scroll-wrapper .k-menu-group > .k-item, .k-popups-wrapper .k-menu-group > .k-item {
                display: block;
                border-width: 0
            }

        .k-menu .k-item, .k-menu-scroll-wrapper .k-item, .k-menu-scroll-wrapper.horizontal > .k-item, .k-popups-wrapper .k-item, .k-popups-wrapper.horizontal > .k-item, .k-widget.k-menu-horizontal > .k-item {
            position: relative;
            float: left;
            border-style: solid;
            border-width: 0 1px 0 0;
            vertical-align: top;
            zoom: 1;
            box-sizing: content-box
        }

        .k-context-menu.k-menu-vertical > .k-item > .k-link, .k-menu .k-menu-group .k-item > .k-link, .k-menu-scroll-wrapper .k-menu-group .k-item > .k-link, .k-popups-wrapper.vertical > .k-item > .k-link {
            padding: .28em 1.8em .38em .9em
        }

.k-context-menu.k-menu-horizontal > .k-separator, .k-popups-wrapper.horizontal > .k-separator {
    display: none
}

.k-context-menu.k-menu-horizontal > .k-item, .k-popups-wrapper.horizontal > .k-item {
    box-sizing: border-box
}

.k-context-menu.k-menu-horizontal > .k-last, .k-popups-wrapper.horizontal > .k-last {
    border: 0
}

.k-menu-horizontal > .k-item > .k-link > .k-menu-expand-arrow, .k-popups-wrapper .k-item > .k-link > .k-menu-expand-arrow {
    margin: -2px 0 0
}

.k-menu .k-item > .k-link > .k-i-arrow-60-down, .k-menu-scroll-wrapper .k-item > .k-link > .k-i-arrow-60-down, .k-popups-wrapper .k-item > .k-link > .k-i-arrow-60-down {
    margin-right: -8px
}

.k-menu-group .k-item > .k-link > .k-menu-expand-arrow, .k-menu-scroll-wrapper .k-item > .k-link > .k-i-arrow-60-right, .k-menu-vertical .k-item > .k-link > .k-menu-expand-arrow, .k-popups-wrapper .k-item > .k-link > .k-i-arrow-60-right {
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: .2rem
}

.k-menu .k-animation-container, .k-menu-scroll-wrapper .k-animation-container, .k-popups-wrapper .k-animation-container {
    border: 0
}

.k-menu .k-animation-container, .k-menu .k-menu-group, .k-menu-scroll-wrapper .k-animation-container, .k-menu-scroll-wrapper .k-menu-group, .k-popups-wrapper .k-animation-container, .k-popups-wrapper .k-menu-group {
    position: absolute;
    left: 0
}

    .k-menu .k-animation-container .k-animation-container, .k-menu .k-menu-group .k-menu-group, .k-menu-scroll-wrapper .k-animation-container .k-animation-container, .k-menu-scroll-wrapper .k-menu-group .k-menu-group, .k-menu-scroll-wrapper.vertical .k-animation-container, .k-menu-scroll-wrapper.vertical .k-menu-group, .k-menu-vertical .k-animation-container, .k-menu-vertical .k-menu-group, .k-popups-wrapper .k-animation-container .k-animation-container, .k-popups-wrapper .k-menu-group .k-menu-group, .k-popups-wrapper.vertical .k-animation-container, .k-popups-wrapper.vertical .k-menu-group {
        top: 0;
        left: 0
    }

    .k-menu .k-animation-container .k-menu-group, .k-menu-scroll-wrapper .k-animation-container .k-menu-group, .k-popups-wrapper .k-animation-container .k-menu-group {
        top: auto;
        left: auto;
        margin-left: -1px
    }

.k-menu .k-animation-container, .k-menu-scroll-wrapper .k-animation-container, .k-popup .k-animation-container, .k-popups-wrapper .k-animation-container {
    margin-top: -1px;
    padding-left: 1px
}

.k-ie .k-menu .k-animation-container, .k-ie .k-menu-scroll-wrapper .k-animation-container, .k-ie .k-popup .k-animation-container, .k-ie .k-popups-wrapper .k-animation-container {
    margin-top: -2px
}

.k-popup .k-animation-container .k-popup {
    margin-left: -1px
}

.k-menu-scroll-wrapper .k-separator, .k-popups-wrapper .k-separator, ul.k-menu .k-separator {
    padding: .25em 0;
    height: 100%;
    width: 1px;
    font-size: 0;
    line-height: 0;
    border-width: 0 1px 0 0
}

.k-menu .k-menu-group .k-separator, .k-menu-scroll-wrapper.vertical .k-menu-group .k-separator, .k-menu-scroll-wrapper.vertical .k-separator, .k-popups-wrapper.vertical .k-menu-group .k-separator, .k-popups-wrapper.vertical .k-separator, ul.k-menu-vertical .k-separator {
    padding: 0;
    height: 1px;
    width: 100%;
    border-width: 1px 0 0
}

.k-menu-scroll-wrapper .k-widget.k-menu, .k-popups-wrapper .k-widget.k-menu {
    white-space: nowrap;
    overflow: hidden
}

.k-menu-scroll-wrapper.vertical .k-menu.k-menu-vertical, .k-popups-wrapper.vertical .k-menu.k-menu-vertical {
    height: 100%;
    box-sizing: border-box
}

.k-menu-scroll-wrapper.horizontal .k-menu.k-menu-horizontal > .k-item, .k-popups-wrapper.horizontal .k-menu.k-menu-horizontal > .k-item {
    display: inline-block;
    overflow: hidden;
    float: none
}

.k-menu-scroll-wrapper.vertical .k-menu.k-menu-vertical > .k-item, .k-popups-wrapper.vertical .k-menu.k-menu-vertical > .k-item {
    overflow: hidden
}

.k-menu-scroll-wrapper, .k-popups-wrapper {
    position: relative;
    border: 0;
    margin: 0;
    padding: 0
}

    .k-menu-scroll-wrapper.vertical, .k-popups-wrapper.vertical {
        height: 100%
    }

    .k-menu-scroll-wrapper > .k-animation-container .k-item, .k-popups-wrapper > .k-animation-container .k-item {
        float: none
    }

    .k-menu-scroll-wrapper .k-menu-scroll-button, .k-popups-wrapper .k-menu-scroll-button {
        border-radius: 0;
        position: absolute;
        display: none
    }

    .k-menu-scroll-wrapper .k-scroll-up, .k-popups-wrapper .k-scroll-up {
        top: 0;
        left: 0
    }

    .k-menu-scroll-wrapper .k-scroll-down, .k-popups-wrapper .k-scroll-down {
        bottom: 0;
        left: 0
    }

    .k-menu-scroll-wrapper .k-scroll-down, .k-menu-scroll-wrapper .k-scroll-up, .k-popups-wrapper .k-scroll-down, .k-popups-wrapper .k-scroll-up {
        width: 100%;
        height: 16px;
        padding: 0 .7em;
        line-height: .2em
    }

    .k-menu-scroll-wrapper .k-scroll-left, .k-popups-wrapper .k-scroll-left {
        top: 0;
        left: 0
    }

    .k-menu-scroll-wrapper .k-scroll-right, .k-popups-wrapper .k-scroll-right {
        top: 0;
        right: 0
    }

    .k-menu-scroll-wrapper .k-scroll-left, .k-menu-scroll-wrapper .k-scroll-right, .k-popups-wrapper .k-scroll-left, .k-popups-wrapper .k-scroll-right {
        width: 16px;
        height: 100%;
        padding-left: 0;
        padding-right: 0
    }

        .k-menu-scroll-wrapper .k-scroll-left .k-icon, .k-menu-scroll-wrapper .k-scroll-right .k-icon, .k-popups-wrapper .k-scroll-left .k-icon, .k-popups-wrapper .k-scroll-right .k-icon {
            margin-top: -.5em;
            position: absolute;
            top: 50%;
            left: 0
        }

.k-rtl .k-menu .k-item, .k-rtl .k-widget.k-menu-horizontal > .k-item, [dir=rtl] .k-menu .k-item, [dir=rtl] .k-widget.k-menu-horizontal > .k-item {
    border-width: 0 0 0 1px
}

    .k-rtl .k-menu .k-image, .k-rtl .k-menu .k-item > .k-link > .k-icon, .k-rtl .k-menu .k-sprite, .k-rtl .k-menu-scroll-wrapper .k-image, .k-rtl .k-menu-scroll-wrapper .k-item > .k-link > .k-icon, .k-rtl .k-menu-scroll-wrapper .k-sprite, .k-rtl .k-popups-wrapper .k-image, .k-rtl .k-popups-wrapper .k-item > .k-link > .k-icon, .k-rtl .k-popups-wrapper .k-sprite, [dir=rtl] .k-menu .k-image, [dir=rtl] .k-menu .k-item > .k-link > .k-icon, [dir=rtl] .k-menu .k-sprite, [dir=rtl] .k-menu-scroll-wrapper .k-image, [dir=rtl] .k-menu-scroll-wrapper .k-item > .k-link > .k-icon, [dir=rtl] .k-menu-scroll-wrapper .k-sprite, [dir=rtl] .k-popups-wrapper .k-image, [dir=rtl] .k-popups-wrapper .k-item > .k-link > .k-icon, [dir=rtl] .k-popups-wrapper .k-sprite {
        margin-right: -4px;
        margin-left: 4px
    }

.k-rtl .k-menu .k-menu-group > .k-item, .k-rtl .k-menu-scroll-wrapper .k-menu-group > .k-item, .k-rtl .k-popups-wrapper .k-menu-group > .k-item, [dir=rtl] .k-menu .k-menu-group > .k-item, [dir=rtl] .k-menu-scroll-wrapper .k-menu-group > .k-item, [dir=rtl] .k-popups-wrapper .k-menu-group > .k-item {
    border: 0
}

.k-rtl .k-context-menu.k-menu-vertical > .k-item > .k-link, .k-rtl .k-menu .k-menu-group .k-item > .k-link, .k-rtl .k-menu-scroll-wrapper .k-menu-group .k-item > .k-link, .k-rtl .k-popups-wrapper.vertical > .k-item > .k-link, [dir=rtl] .k-context-menu.k-menu-vertical > .k-item > .k-link, [dir=rtl] .k-menu .k-menu-group .k-item > .k-link, [dir=rtl] .k-menu-scroll-wrapper .k-menu-group .k-item > .k-link, [dir=rtl] .k-popups-wrapper.vertical > .k-item > .k-link {
    padding: .28em .9em .38em 1.8em
}

.k-context-menu {
    border: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.k-grid, .k-listview {
    position: relative;
    zoom: 1
}

    .k-grid table {
        width: 100%;
        margin: 0;
        max-width: none;
        border-collapse: separate;
        border-spacing: 0;
        empty-cells: show;
        border-width: 0;
        outline: 0
    }

.k-header.k-drag-clue {
    padding: .5em .6em .4em .6em;
    line-height: 1.6;
    overflow: hidden
}

.k-filter-row th, .k-grid-header th.k-header {
    overflow: hidden;
    border-style: solid;
    border-width: 0 0 1px 1px;
    padding: .5em .6em .4em .6em;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left
}

.k-grid-header th.k-header {
    vertical-align: bottom;
    cursor: default
}

    .k-grid-header th.k-header .k-checkbox {
        vertical-align: top
    }

.k-filtercell, .k-filtercell .k-widget, .k-filtercell > span {
    display: block;
    width: auto
}

    .k-filtercell > span {
        padding-right: 4.8em;
        position: relative;
        min-height: 2em;
        line-height: 2em
    }

    .k-filtercell > .k-operator-hidden {
        padding-right: 2.3em
    }

    .k-filter-row .k-dropdown-operator, .k-filtercell > span > .k-button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0
    }

.k-filter-row .k-dropdown-operator {
    width: 2.1em;
    right: 2.5em
}

.k-filtercell > span > label {
    vertical-align: middle
}

.k-filter-row label > input[type=radio] {
    vertical-align: middle;
    position: relative;
    bottom: 2px
}

.k-ff .k-grid .k-filter-row .k-i-filter-clear {
    top: 1px
}

.k-ie10 .k-grid-header a:active {
    background-color: transparent
}

.k-grid-header th.k-header > .k-link {
    display: block;
    min-height: 18px;
    line-height: 18px;
    margin: -.5em -.6em -.4em 0;
    padding: .5em .6em .4em 0;
    overflow: hidden;
    text-overflow: ellipsis
}

.k-grid-header th.k-with-icon .k-link {
    margin-right: 18px
}

.k-grid-header th > .k-link > .k-icon {
    vertical-align: text-top
}

.k-grid .k-state-hover {
    cursor: pointer
}

.k-grid-column-resizing, .k-grid-column-resizing .k-button, .k-grid-column-resizing .k-grid-filter, .k-grid-column-resizing .k-grid-toolbar, .k-grid-column-resizing .k-link {
    cursor: col-resize
}

.k-grid td {
    border-style: solid;
    border-width: 0 0 0 1px;
    padding: .4em .6em;
    overflow: hidden;
    line-height: 1.6em;
    vertical-align: middle;
    text-overflow: ellipsis
}

.k-grid .k-grouping-row td, .k-grid .k-hierarchy-cell {
    overflow: visible
}

.k-grid-edit-row td {
    text-overflow: clip
}

.k-grid-edit-row .k-textbox, .k-grid-edit-row .text-box {
    margin-top: 0;
    margin-bottom: 0
}

.k-grid-footer-wrap, .k-grid-header-wrap {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-style: solid;
    border-width: 0 1px 0 0;
    zoom: 1
}

div.k-grid-footer, div.k-grid-header {
    padding-right: 17px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    zoom: 1
}

.k-grid-header-locked > table, .k-grid-header-wrap > table {
    margin-bottom: -1px
}

.k-grid-content {
    position: relative;
    width: 100%;
    overflow: auto;
    overflow-x: auto;
    overflow-y: scroll;
    zoom: 1;
    min-height: 0
}

.k-mobile .k-grid tbody {
    -webkit-backface-visibility: hidden
}

.k-mobile .k-grid-backface tbody {
    -webkit-backface-visibility: visible
}

.k-grid-content-expander {
    position: absolute;
    visibility: hidden;
    height: 1px
}

.k-grid-norecords {
    width: 100%;
    height: 100%;
    text-align: center
}

.k-grid-norecords-template {
    width: 20em;
    height: 4em;
    line-height: 4em;
    vertical-align: middle;
    margin: 0 auto
}

.k-grid-content > .k-grid-norecords > .k-grid-norecords-template {
    top: 50%;
    left: 50%;
    margin-left: -10em;
    margin-top: -2em;
    position: absolute
}

@media print {
    .k-grid {
        height: auto !important
    }

    .k-grid-header {
        padding: 0 !important
    }

    .k-grid-content, .k-grid-header-wrap {
        overflow: visible;
        height: auto !important
    }
}

.k-grid .k-scrollbar {
    -ms-overflow-style: scrollbar
}

.k-virtual-scrollable-wrap {
    height: 100%;
    overflow-y: hidden;
    position: relative
}

.k-grid-content table, .k-grid-content-locked > table, .k-grid-footer table, .k-grid-header table {
    table-layout: fixed
}

.k-grid-lockedcolumns {
    white-space: nowrap
}

.k-grid-content, .k-grid-content-locked, .k-pager-wrap {
    white-space: normal
}

.k-grid-content-locked, .k-grid-footer-locked, .k-grid-header-locked {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    position: relative;
    border-style: solid;
    border-width: 0 1px 0 0
}

    .k-grid-content-locked + .k-grid-content, .k-grid-footer-locked + .k-grid-footer-wrap, .k-grid-header-locked + .k-grid-header-wrap {
        display: inline-block;
        vertical-align: top
    }

.k-grid-toolbar {
    border-style: solid;
    border-width: 0 0 1px
}

.k-filter-row > th:first-child, .k-grid tbody td:first-child, .k-grid tfoot td:first-child, .k-grid-header th.k-header:first-child {
    border-left-width: 0
}

.k-grid-header th.k-header.k-first {
    border-left-width: 1px
}

.k-footer-template td {
    border-style: solid;
    border-width: 1px 0 0 1px
}

.k-group-footer td {
    border-style: solid;
    border-width: 1px 0
}

.k-group-footer .k-group-cell + td {
    border-left-width: 1px
}

.k-grid-footer {
    border-style: solid;
    border-width: 1px 0 0
}

    .k-grid-footer td {
        border-top-width: 0
    }

    .k-grid-footer > td {
        border-top-width: 1px
    }

.k-grid-pager {
    border-width: 1px 0 0
}

.k-header > .k-grid-filter, .k-header > .k-header-column-menu {
    float: right;
    margin: -.5em -.6em -.4em 0;
    padding: .5em .2em .4em;
    position: relative;
    z-index: 1;
    color: inherit
}

.k-grid .k-animation-container {
    position: absolute
}

.k-filter-menu {
    padding: .5em
}

.k-list-filter {
    display: block
}

form.k-filter-menu .k-textbox, form.k-filter-menu .k-widget {
    display: block
}

form.k-filter-menu .k-textbox {
    width: 100%;
    margin-bottom: 3px
}

.k-filter-help-text, .k-filter-menu .k-textbox, .k-filter-menu .k-widget {
    margin: .19em 0 0
}

.k-filter-menu span.k-filter-and {
    width: 6em;
    margin: .5em 0 .5em
}

.k-filter-menu .k-action-buttons {
    margin: 0;
    padding: 0;
    text-align: initial
}

    .k-filter-menu .k-action-buttons .k-button {
        width: 48%;
        margin: .5em 4% 0 0;
        min-width: 0
    }

        .k-filter-menu .k-action-buttons .k-button + .k-button {
            margin-right: 0
        }

.k-filter-menu .k-filter-selected-items {
    font-weight: 700;
    margin: .5em
}

.k-multicheck-wrap {
    overflow: auto;
    overflow-x: hidden;
    white-space: nowrap;
    max-height: 300px
}

    .k-multicheck-wrap .k-item {
        line-height: 2.2em
    }

.k-grouping-row .k-icon {
    margin: -3px 4px 0 2px
}

.k-grouping-row p {
    display: inline-block;
    vertical-align: middle;
    margin-left: -.6em;
    padding: 0 .6em
}

.k-grouping-row + tr td {
    border-top-width: 1px
}

.k-grouping-row .k-group-cell, .k-grouping-row + tr .k-group-cell {
    border-top-width: 0;
    text-overflow: clip
}

.k-grid .k-hierarchy-cell + td {
    border-left-width: 0
}

.k-grid .k-group-col, .k-grid .k-hierarchy-col {
    width: 27px
}

.k-grouping-header {
    border-bottom-style: solid;
    border-bottom-width: 1px
}

.k-grouping-header {
    line-height: 2;
    position: relative;
    white-space: normal
}

.k-grouping-dropclue {
    position: absolute;
    top: 3px;
    width: 6px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: -165px -148px
}

    .k-grouping-dropclue:after, .k-grouping-dropclue:before {
        content: "";
        width: 0;
        height: 0;
        border: 3px solid;
        border-left-color: transparent;
        border-right-color: transparent;
        position: absolute;
        left: 0
    }

    .k-grouping-dropclue:before {
        top: 0;
        border-bottom: 0
    }

    .k-grouping-dropclue:after {
        bottom: 0;
        border-top: 0
    }

.k-group-indicator {
    padding: .15em .15em .15em .4em;
    border-width: 1px;
    border-style: solid;
    line-height: 1.5em;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    vertical-align: top
}

.k-grouping-header .k-group-indicator {
    margin: 0 3px
}

.k-group-indicator .k-button.k-bare, .k-group-indicator .k-link {
    padding: 0;
    border-width: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center
}

    .k-group-indicator .k-link .k-icon {
        margin-right: 4px
    }

.k-group-indicator .k-button.k-bare {
    margin-left: 8px;
    padding: 0;
    width: auto;
    height: auto;
    opacity: .5
}

    .k-group-indicator .k-button.k-bare::after, .k-group-indicator .k-button.k-bare::before {
        display: none
    }

    .k-group-indicator .k-button.k-bare:hover {
        opacity: 1
    }

.k-no-flexbox .k-group-indicator {
    display: inline-block
}

    .k-no-flexbox .k-group-indicator .k-button, .k-no-flexbox .k-group-indicator .k-link {
        display: inline-block;
        vertical-align: middle
    }

.k-dirty-cell:before {
    content: "\a0";
    display: inline-block;
    width: 0;
    float: left
}

.k-dirty-cell {
    position: relative
}

    .k-dirty-cell.k-edit-cell {
        position: static
    }

    .k-dirty-cell .k-dirty {
        margin: 0;
        top: 0;
        left: 0
    }

.k-dirty {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px;
    border-color: red transparent transparent red;
    margin: -.45em 0 0 -.6em;
    padding: 0;
    overflow: hidden;
    vertical-align: top
}

.k-grid-toolbar, .k-grouping-header {
    margin: 0;
    padding: .22em .2em .28em;
    cursor: default
}

.k-grid .k-edit-container {
    padding: 0
}

.k-grid .field-validation-error {
    display: block
}

.k-grid .input-validation-error {
    border-style: ridge;
    border-color: red;
    background-color: pink
}

.k-grid-toolbar .k-button {
    vertical-align: middle
}

.k-grid-actions {
    display: inline-block
}

.k-grid .k-button, .k-grid .k-grid-toolbar > * {
    margin: .16em
}

.k-grid tbody .k-button {
    min-width: 64px
}

.k-grid tbody button.k-button {
    min-width: 78px
}

html body .k-grid tbody .k-button-icon {
    width: auto;
    min-width: 0
}

.k-detail-row {
    position: relative
}

.k-grid .k-detail-cell {
    overflow: visible
}

.k-grid .k-edit-cell {
    padding: 0 .3em;
    white-space: nowrap
}

    .k-grid .k-edit-cell .k-tooltip {
        white-space: normal
    }

.k-edit-cell > .k-textbox, .k-edit-cell > .k-widget:not(.k-switch), .k-edit-cell > .text-box, .k-grid-edit-row > td > .k-textbox, .k-grid-edit-row > td > .k-widget:not(.k-switch), .k-grid-edit-row > td > .text-box {
    width: 100%
}

html .k-edit-cell .k-widget.k-tooltip, html .k-grid-edit-row .k-widget.k-tooltip {
    width: auto;
    max-width: 300px
}

.k-edit-cell input[type=checkbox] {
    margin-left: .6em
}

.k-grid-resize-indicator {
    position: absolute;
    width: 2px;
    background-color: #aaa
}

.k-grid-header .k-resize-handle, .k-grid > .k-resize-handle {
    position: absolute;
    height: 25px;
    cursor: col-resize;
    z-index: 2
}

.k-marquee {
    position: absolute;
    z-index: 100000
}

.k-marquee-color, .k-marquee-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.k-marquee-color {
    filter: alpha(opacity=60);
    opacity: .6
}

.k-ie9 .k-column-menu {
    width: 160px
}

.k-column-menu {
    min-width: 160px
}

    .k-column-menu .k-sprite {
        margin-right: 10px
    }

    .k-column-menu > .k-menu {
        border-width: 0
    }

    .k-column-menu .k-calendar .k-link {
        white-space: normal
    }

.k-columns-item .k-group {
    max-height: 200px;
    overflow: auto
}

.k-pdf-export-shadow .k-grid {
    float: left;
    width: auto !important
}

.k-pdf-export-shadow .k-grid-content.k-auto-scrollable {
    padding-right: 0 !important
}

.k-pdf-export-shadow .k-grid, .k-pdf-export-shadow .k-grid-content, .k-pdf-export-shadow .k-grid-content-locked {
    height: auto !important;
    overflow: visible
}

    .k-pdf-export-shadow .k-grid-content-locked + .k-grid-content, .k-pdf-export-shadow .k-grid-footer-locked + .k-grid-footer-wrap, .k-pdf-export-shadow .k-grid-header-locked + .k-grid-header-wrap {
        width: auto !important
    }

    .k-pdf-export-shadow .k-grid-header, .k-pdf-export-shadow .k-grid[data-role=grid] .k-grid-footer {
        padding: 0 !important
    }

.k-loading-pdf-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100
}

    .k-loading-pdf-mask .k-loading-color {
        filter: alpha(opacity=50);
        opacity: .5
    }

    .k-loading-pdf-mask .k-loading-pdf-progress {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0
    }

.k-pdf-export .k-loading-pdf-mask {
    display: none
}

.k-pdf-export .k-grid-filter {
    text-decoration: none
}

.k-autofitting {
    width: auto !important;
    table-layout: auto !important
}

    .k-autofitting td, .k-autofitting th.k-header {
        white-space: nowrap !important
    }

    .k-autofitting .k-detail-row {
        display: none !important
    }

.k-grid .k-grouping-row td {
    border-bottom-width: 0
}

.k-grid .k-grouping-row + tr td {
    border-top-width: 1px
}

    .k-grid .k-grouping-row + tr td.k-group-cell {
        border-top-width: 0
    }

.k-grid .k-group-cell {
    border-bottom-width: 0;
    border-top-width: 0
}

.k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm {
    margin-left: 7px
}

.k-grid-header .k-sort-order {
    display: inline-block;
    height: 16px;
    line-height: 16px;
    margin-top: 1px;
    margin-left: -1px;
    vertical-align: text-top;
    font-size: 12px
}

.k-grid-header .k-link .k-icon.k-i-sort-asc-sm, .k-grid-header .k-link .k-icon.k-i-sort-desc-sm {
    margin-top: 1px;
    vertical-align: text-top
}

.k-grid .k-header .k-grid-search {
    width: 15.7em;
    float: right;
    display: -ms-flexbox;
    display: flex
}

.k-treelist .k-status {
    padding: .4em .6em;
    line-height: 1.6em
}

    .k-treelist .k-status .k-loading {
        vertical-align: baseline;
        margin-right: 5px
    }

.k-treelist tr.k-hidden {
    display: none
}

.k-treelist.k-treelist-dragging, .k-treelist.k-treelist-dragging .k-state-hover {
    cursor: default
}

.k-treelist .k-i-drag-and-drop {
    position: absolute;
    z-index: 10000;
    visibility: hidden;
    width: 80px;
    height: 5px;
    margin-top: -3px;
    background-color: transparent;
    background-repeat: no-repeat
}

.k-treelist .k-i-arrow-45-down-right, .k-treelist .k-i-arrow-60-right {
    cursor: pointer
}

.k-treelist .k-i-arrow-45-down-right {
    margin-top: -.5em
}

.k-drag-separator {
    display: inline-block;
    border-right: 1px solid;
    height: 1em;
    vertical-align: top;
    margin: 0 .5em
}

.k-gantt {
    white-space: nowrap;
    position: relative
}

.k-gantt-layout {
    display: inline-block;
    white-space: normal;
    vertical-align: top
}

.k-gantt .k-splitbar {
    position: relative;
    cursor: e-resize;
    width: 5px;
    border-width: 0 1px;
    background-repeat: repeat-y
}

.k-gantt .k-gantt-layout .k-grid-header td, .k-gantt .k-gantt-layout th {
    vertical-align: bottom
}

.k-gantt td {
    overflow: hidden;
    white-space: nowrap;
    vertical-align: top
}

.k-gantt .k-grid .k-edit-cell {
    vertical-align: middle
}

.k-gantt-timeline > .k-timeline, .k-gantt-treelist > .k-treelist {
    border-width: 0;
    height: 100%
}

.k-gantt-toolbar {
    border-style: solid;
    border-width: 0 0 1px;
    line-height: 2.4em;
    padding: .5em
}

.k-gantt-layout + .k-gantt-toolbar {
    border-width: 1px 0 0
}

.k-gantt-actions, .k-gantt-toolbar > ul {
    float: left;
    margin-right: .6em
}

    .k-gantt-actions > .k-button {
        margin-right: .5em;
        vertical-align: top
    }

.k-gantt-toolbar > .k-gantt-views {
    float: right;
    margin-right: 0
}

    .k-gantt-toolbar > .k-gantt-views > li.k-current-view {
        display: none
    }

.k-gantt-toolbar > ul > li {
    display: inline-block;
    border-style: solid;
    border-width: 1px 1px 1px 0
}

    .k-gantt-toolbar > ul > li:first-child + li {
        border-left-width: 1px
    }

.k-gantt-toolbar .k-link {
    display: inline-block;
    padding: 0 1.1em
}

.k-gantt-toolbar li:first-child + li, .k-gantt-toolbar li:first-child + li > .k-link {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.k-gantt-toolbar li:last-child, .k-gantt-toolbar li:last-child > .k-link {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.k-gantt-toolbar li.k-button {
    line-height: inherit;
    padding-top: 0;
    padding-bottom: 0
}

.k-gantt-treelist .k-grid-header tr {
    height: 5em
}

.k-gantt .k-treelist .k-grid-header {
    padding: 0 !important
}

.k-gantt .k-treelist .k-grid-content {
    overflow-y: hidden;
    overflow-x: scroll
}

.k-treelist-group > tr > span {
    font-weight: 700
}

.k-treelist-group .k-widget {
    font-weight: 400
}

.k-gantt-timeline .k-grid-header tr {
    height: 2.5em
}

.k-gantt-timeline .k-grid-header .k-header {
    vertical-align: bottom;
    cursor: default
}

.k-gantt-tasks {
    position: relative
}

    .k-gantt .k-grid-content tr, .k-gantt-rows tr, .k-gantt-tasks tr {
        height: 2.3em
    }

.k-gantt .k-gantt-tasks td:after {
    content: "\a0"
}

.k-gantt-timeline {
    background: 0 0
}

.k-gantt-columns, .k-gantt-dependencies, .k-gantt-rows {
    position: absolute;
    top: 0;
    left: 0
}

.k-gantt-tables {
    position: relative
}

.k-gantt .k-timeline .k-grid-content {
    overflow-x: scroll
}

.k-gantt .k-gantt-timeline th, .k-gantt-timeline .k-grid-header td {
    text-align: center
}

.k-gantt .k-gantt-timeline .k-grid-header tr:first-child td, .k-gantt .k-gantt-timeline tr:first-child th {
    border-bottom-width: 1px
}

.k-gantt-timeline td.k-header {
    overflow: hidden;
    border-style: solid;
    border-width: 0 0 1px 1px;
    padding: .5em .6em .4em .6em;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis
}

.k-task-summary {
    height: 10px;
    display: inline-block;
    vertical-align: top;
    margin-top: 3px
}

.k-task-summary-complete {
    height: 10px;
    position: relative;
    z-index: 2
}

.k-task-summary-progress {
    height: 15px;
    overflow: hidden
}

.k-task-summary-complete:after, .k-task-summary-complete:before, .k-task-summary:after, .k-task-summary:before {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px;
    border-color: transparent
}

.k-task-summary-complete:before, .k-task-summary:before {
    left: 0;
    border-left-color: inherit
}

.k-task-summary-complete:after, .k-task-summary:after {
    right: 0;
    border-right-color: inherit
}

.k-gantt-line-h, .k-gantt-line-v {
    position: absolute
}

.k-gantt-line-h {
    height: 2px
}

.k-gantt-line-v {
    width: 2px
}

.k-arrow-e, .k-arrow-w {
    position: absolute;
    top: -4px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px
}

.k-arrow-e {
    right: -6px;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent
}

.k-arrow-w {
    left: -6px;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent
}

.k-task-milestone {
    width: 13px;
    height: 13px;
    margin-top: 3px;
    border-style: solid;
    border-width: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.k-gantt .k-gantt-tasks .k-button-icon, .k-gantt .k-gantt-treelist .k-button {
    padding-top: 0;
    padding-bottom: 0
}

.k-gantt .k-gantt-tasks .k-button-icon {
    margin-top: 4px
}

.k-gantt .k-gantt-treelist .k-button {
    margin-top: -4px;
    margin-bottom: -2px
}

.k-gantt .k-gantt-tasks .k-button-icon {
    padding-left: 2px;
    padding-right: 2px
}

.k-gantt .k-gantt-tasks .k-button .k-icon, .k-gantt .k-gantt-treelist .k-button .k-icon {
    vertical-align: text-top
}

.k-rel .k-button-icon {
    position: absolute;
    left: 200px
}

.k-rel {
    position: relative;
    height: 0;
    top: -.3em
}

.k-task-wrap {
    position: absolute;
    display: inline;
    padding: 0 23px 5px;
    margin: -1px -23px 0;
    z-index: 2
}

    .k-gantt-line.k-state-selected, .k-task-wrap:hover {
        z-index: 3
    }

.k-milestone-wrap {
    margin: 0 -13px 0 -27px
}

.k-task-content {
    position: relative;
    z-index: 2
}

.k-task-complete {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 20%;
    z-index: 1
}

.k-task-dot {
    position: absolute;
    top: 0;
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: none;
    cursor: pointer
}

    .k-task-dot.k-state-hover {
        background-color: transparent
    }

.k-task-single + .k-task-dot, .k-task-single + .k-task-dot + .k-task-dot {
    top: .2em
}

.k-task-wrap-active .k-task-dot, .k-task-wrap:hover .k-task-dot {
    display: block
}

.k-task-dot:before {
    content: "\a0";
    display: inline-block;
    width: 0;
    height: 16px
}

.k-task-dot:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-left: 4px
}

.k-task-dot.k-state-hover:after, .k-task-dot:hover:after, .k-task-wrap-active .k-task-dot:after {
    border-style: solid;
    border-width: 1px;
    margin-left: 3px
}

.k-task-start {
    left: 0
}

.k-task-end {
    right: 0
}

.k-task-single {
    border-style: solid;
    border-width: 1px;
    text-align: left;
    overflow: hidden;
    cursor: default;
    min-height: 1.3em;
    white-space: nowrap
}

.k-task-template {
    padding: .2em 1.4em .2em .6em;
    line-height: normal
}

.k-task-actions, .k-task-content > .k-link {
    position: absolute;
    top: 0;
    right: 4px;
    white-space: nowrap
}

.k-task-actions {
    z-index: 1
}

    .k-task-actions:first-child {
        position: static;
        float: left;
        margin: 4px 2px 0 4px
    }

.k-webkit .k-task-actions:first-child {
    margin-top: 3px
}

.k-task-actions:first-child > .k-link {
    display: inline-block
}

.k-task-delete {
    display: none
}

.k-task-wrap-active .k-task-delete, .k-task-wrap:hover .k-task-delete {
    display: inline-block
}

.k-task-single .k-resize-handle {
    position: absolute;
    visibility: hidden;
    z-index: 2;
    height: auto
}

.k-task-single:hover .k-resize-handle, .k-task-wrap-active .k-resize-handle {
    visibility: visible
}

.k-task-single .k-resize-handle:after {
    content: "";
    position: absolute;
    filter: alpha(opacity=50);
    opacity: .5
}

.k-task-content > .k-resize-e {
    right: 0;
    top: 0;
    bottom: 0;
    width: .4em
}

.k-task-content > .k-resize-w {
    left: 0;
    top: 0;
    bottom: 0;
    width: .4em
}

    .k-task-content > .k-resize-e:after, .k-task-content > .k-resize-w:after {
        left: 1px;
        top: 50%;
        margin-top: -.7em;
        height: 1.4em;
        width: 1px
    }

.k-task-content > .k-resize-e:after {
    left: auto;
    right: 1px
}

.k-task-draghandle {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    margin: 0 18px;
    border-width: 5px;
    border-style: solid;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    display: none;
    cursor: e-resize
}

.k-task-wrap-active .k-task-draghandle, .k-task-wrap:hover .k-task-draghandle {
    display: block
}

.k-gantt-dependency-hint {
    z-index: 4
}

.k-gantt-rowheight .k-task-dot, .k-gantt-rowheight .k-task-milestone, .k-gantt-rowheight .k-task-single + .k-task-dot, .k-gantt-rowheight .k-task-single + .k-task-dot + .k-task-dot, .k-gantt-rowheight .k-task-summary {
    top: 50%
}

.k-gantt-rowheight .k-task-milestone, .k-gantt-rowheight .k-task-summary {
    margin-top: -6px
}

.k-gantt-rowheight .k-task-dot, .k-gantt-rowheight .k-task-single + .k-task-dot, .k-gantt-rowheight .k-task-single + .k-task-dot + .k-task-dot {
    margin-top: -11px
}

.k-gantt-rowheight .k-task-single {
    height: calc(100% - 2px)
}

.k-ie .k-gantt-rowheight .k-task-single {
    height: 99%
}

.k-gantt-rowheight .k-task-content {
    height: 100%
}

    .k-gantt-rowheight .k-task-content > .k-resize-e:after, .k-gantt-rowheight .k-task-content > .k-resize-w:after {
        top: 0;
        margin-top: 0;
        height: 100%
    }

.k-task-details {
    padding: .4em;
    text-align: left;
    white-space: nowrap
}

    .k-task-details > strong {
        font-size: 120%;
        display: block
    }

.k-task-pct {
    margin: .5em 0 .1em;
    font-size: 170%
}

.k-task-details > ul {
    line-height: 1.2
}

.k-resources-wrap {
    position: absolute;
    display: inline;
    z-index: 2;
    zoom: 1;
    margin-left: 20px;
    margin-top: -2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

    .k-resources-wrap .k-resource {
        margin: 0 5px
    }

.k-gantt-edit-form > .k-edit-form-container {
    width: 430px
}

.k-gantt-edit-form > .k-resources-form-container {
    width: 506px
}

.k-resources-form-container > .k-grid {
    margin: 0 .9em
}

.k-gantt-edit-form > .k-edit-form-container .k-numerictextbox, .k-gantt-edit-form > .k-edit-form-container .k-textbox {
    width: 15em
}

.k-gantt-edit-form .k-edit-buttons .k-gantt-delete {
    float: left
}

.k-pdf-export-shadow .k-gantt {
    float: left
}

    .k-pdf-export-shadow .k-gantt, .k-pdf-export-shadow .k-gantt .k-grid-content, .k-pdf-export-shadow .k-gantt-timeline {
        width: auto !important;
        height: auto !important;
        overflow: visible !important
    }

.k-pdf-export-shadow .k-gantt-treelist {
    height: auto !important;
    overflow: visible !important
}

.k-pdf-export-shadow .k-gantt-timeline .k-grid-header {
    padding: 0 !important
}

.k-pdf-export-shadow .k-gantt .k-splitbar, .k-pdf-export-shadow .k-pdf-export {
    display: none
}

button.k-gantt-toggle {
    display: none;
    float: left;
    margin-right: .5em
}

@media only screen and (max-width:1024px) {
    .k-gantt-toolbar > ul.k-gantt-views {
        position: absolute;
        right: 6px;
        top: 6px;
        z-index: 10000
    }

    .k-rtl .k-gantt-toolbar > ul.k-gantt-views {
        right: auto;
        left: 6px
    }

    .k-gantt-toolbar > ul.k-gantt-views > li:not(.k-current-view) {
        display: none
    }

    .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view {
        display: block;
        border-width: 1px
    }

    .k-rtl .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view {
        text-align: left;
        padding-left: 1em
    }

    .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view > .k-link {
        display: block;
        position: relative;
        padding-right: 2.5em;
        padding-left: 1em
    }

    .k-rtl .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view > .k-link {
        padding-left: 0
    }

    .k-gantt-toolbar > ul.k-gantt-views > li.k-current-view > .k-link:after {
        content: "\E006";
        position: absolute;
        top: 50%;
        right: .6em;
        margin-top: -.5em;
        line-height: 1em
    }

    .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li, .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded > li:first-child + li {
        display: block;
        border: 0;
        border-radius: 0
    }

    .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded {
        border: 1px solid #c5c5c5;
        background-color: #fff;
        background-image: none;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,.3)
    }

    .k-rtl .k-gantt-toolbar > ul.k-gantt-views.k-state-expanded {
        text-align: left
    }
}

@media only screen and (max-width:480px) {
    button.k-gantt-toggle {
        display: inline-block
    }

    .k-gantt-create span + span, .k-gantt-pdf span + span {
        display: none
    }

    .k-gantt-create .k-icon, .k-gantt-pdf .k-icon {
        margin: 0
    }

    .k-gantt .k-splitbar {
        display: none;
        width: 0;
        border-width: 0
    }

    .k-gantt .k-gantt-treelist {
        display: none;
        max-width: 0
    }

    .k-gantt .k-treelist .k-grid-content {
        overflow-y: scroll
    }

    .k-gantt .k-gantt-timeline {
        width: 100%
    }
}

.k-pivot {
    position: relative
}

.k-pivot-toolbar {
    padding: .2em;
    border-bottom-width: 1px;
    border-bottom-style: solid
}

.k-pivot .k-pivot-toolbar {
    padding: .6em
}

.k-pivot-toolbar .k-button {
    margin-right: .4em;
    line-height: 1.2em;
    font-size: .9em;
    text-align: left;
    position: relative;
    padding: .3em 5em .3em .3em;
    cursor: move
}

.k-field-actions {
    position: absolute;
    right: 2px;
    top: 3px;
    cursor: pointer
}

.k-pivot .k-grid td {
    white-space: nowrap
}

.k-pivot-layout {
    border-spacing: 0;
    table-layout: auto
}

    .k-pivot-layout > tbody > tr > td {
        vertical-align: top;
        padding: 0
    }

.k-pivot td {
    vertical-align: top
}

.k-pivot-rowheaders > .k-grid, .k-pivot-table > .k-grid {
    border-width: 0
}

    .k-pivot-rowheaders > .k-grid td:first-child, .k-pivot-table .k-grid-header .k-header.k-first {
        border-left-width: 1px
    }

    .k-pivot-rowheaders > .k-grid td.k-first {
        border-left-width: 0
    }

.k-pivot-rowheaders > .k-grid {
    overflow: hidden
}

.k-pivot-table {
    border-left-width: 1px;
    border-left-style: solid
}

    .k-pivot-table .k-grid-header-wrap > table {
        height: 100%
    }

.k-pivot .k-grid-header .k-header {
    vertical-align: top
}

.k-header.k-alt, td.k-alt {
    font-weight: 700
}

.k-header.k-alt {
    background-image: none
}

.k-pivot-layout .k-grid td {
    border-bottom-width: 1px
}

.k-pivot-layout .k-grid-footer > td {
    border-top-width: 0
}

.k-pivot-filter-window .k-treeview {
    max-height: 600px
}

.k-fieldselector .k-edit-buttons {
    bottom: auto
}

.k-fieldselector .k-edit-label {
    width: 16%
}

.k-fieldselector .k-edit-field {
    width: 77%
}

    .k-fieldselector .k-edit-field > .k-textbox, .k-fieldselector .k-edit-field > .k-widget {
        width: 99%
    }

.k-fieldselector .k-edit-buttons > input, .k-fieldselector .k-edit-buttons > label {
    float: left;
    margin-top: .4em
}

.k-fieldselector p {
    margin: 0 0 .2em .5em;
    text-transform: uppercase
}

    .k-fieldselector p .k-icon {
        margin: 0 5px 0 0
    }

.k-fieldselector .k-columns {
    border-style: solid;
    border-width: 0
}

    .k-fieldselector .k-columns > div {
        overflow: auto;
        padding: .6em;
        border-style: solid;
        border-width: 0 0 0 1px;
        float: left;
        width: 45%
    }

        .k-fieldselector .k-columns > div:first-child {
            border-width: 0;
            margin-right: -1px
        }

        .k-fieldselector .k-columns > div + div {
            float: right;
            border-width: 0
        }

.k-fieldselector div.k-treeview {
    border-width: 0;
    margin-right: -1px;
    padding-left: 4px;
    overflow: visible
}

.k-fieldselector .k-list-container {
    margin-left: .5em;
    margin-bottom: 1em;
    padding: .2em 0 0;
    border-style: solid;
    border-width: 1px
}

.k-fieldselector .k-list {
    padding-bottom: 2em
}

    .k-fieldselector .k-list li.k-item {
        padding: .3em 3.3em .3em .3em;
        margin: 0 .2em .2em;
        position: relative;
        font-size: .9em;
        line-height: 1.2em;
        min-height: 1em
    }

.k-i-kpi-trend-decrease, .k-i-kpi-trend-equal, .k-i-kpi-trend-increase {
    color: #858585
}

.k-i-kpi-status-hold {
    color: #f9ce1d
}

.k-i-kpi-status-deny {
    color: #f44336
}

.k-i-kpi-status-open {
    color: #4caf50
}

.k-pdf-export-shadow .k-pivot .k-grid-content {
    overflow: visible !important
}

.k-pdf-export-shadow .k-pivot .k-grid-content, .k-pdf-export-shadow .k-pivot .k-pivot-rowheaders .k-grid {
    height: 100% !important
}

.k-pdf-export-shadow .k-pivot .k-grid-header {
    padding-right: 0 !important
}

.k-pdf-export-shadow .k-pivot {
    width: auto !important;
    height: auto !important
}

    .k-pdf-export-shadow .k-pivot .k-grid-content > table, .k-pdf-export-shadow .k-pivot .k-grid-header-wrap > table {
        width: 100% !important;
        height: auto !important
    }

.k-pivot .k-field-actions .k-icon {
    pointer-events: all
}

div.k-treeview {
    border-width: 0;
    background: 0 0;
    overflow: auto;
    white-space: nowrap
}

.k-treeview .k-item {
    display: block;
    border-width: 0;
    margin: 0;
    padding: 0 0 0 16px
}

    .k-treeview .k-content, .k-treeview .k-item > .k-group, .k-treeview > .k-group {
        margin: 0;
        padding: 0;
        background: 0 0;
        list-style-type: none;
        position: relative
    }

.k-treeview .k-checkbox, .k-treeview .k-icon, .k-treeview .k-image, .k-treeview .k-in, .k-treeview .k-sprite {
    display: inline-block;
    vertical-align: top
}

.k-checkbox-wrapper {
    display: inline-block;
    vertical-align: middle
}

.k-treeview .k-checkbox-wrapper + .k-in {
    margin-left: 4px
}

.k-treeview .k-icon, .k-treeview .k-in {
    vertical-align: middle
}

.k-treeview .k-request-retry {
    vertical-align: baseline
}

.k-treeview .k-i-collapse, .k-treeview .k-i-expand, .k-treeview .k-i-minus, .k-treeview .k-i-plus {
    margin-left: -16px;
    cursor: pointer
}

.k-treeview .k-i-collapse {
    margin-top: -4px
}

.k-treeview .k-image, .k-treeview .k-sprite {
    margin-right: 3px
}

.k-treeview .k-in {
    margin: 0;
    padding: 1px .3333em 1px .25em;
    line-height: 1.3333em;
    text-decoration: none;
    border-style: solid;
    border-width: 1px
}

.k-treeview span.k-in {
    cursor: default
}

.k-treeview .k-i-drag-and-drop {
    position: absolute;
    z-index: 10000;
    visibility: hidden;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background-color: transparent;
    background-repeat: no-repeat
}

.k-treeview .k-state-hover {
    cursor: pointer
}

.k-rtl .k-treeview .k-i-collapse, .k-rtl .k-treeview .k-i-expand {
    margin-left: 0;
    margin-right: -16px
}

.k-rtl .k-treeview .k-checkbox-wrapper + .k-in {
    margin-left: 0;
    margin-right: 4px
}

.k-toolbar .k-split-button, span.k-colorpicker, span.k-combobox, span.k-datepicker, span.k-datetimepicker, span.k-dropdown, span.k-dropdowntree, span.k-numerictextbox, span.k-timepicker {
    background-image: none
}

.k-autocomplete, .k-colorpicker, .k-combobox, .k-datepicker, .k-datetimepicker, .k-dropdown, .k-dropdowntree, .k-listbox, .k-multiselect, .k-numerictextbox, .k-selectbox, .k-textbox, .k-timepicker, .k-toolbar .k-split-button {
    position: relative;
    display: inline-block;
    width: 12.4em;
    overflow: visible;
    border-width: 0;
    vertical-align: middle
}

    .k-autocomplete > .k-i-close, .k-dropdown-wrap > .k-i-close, .k-multiselect-wrap > .k-i-close {
        font-size: 100%;
        display: none;
        position: absolute;
        cursor: pointer
    }

    .k-autocomplete > .k-i-close {
        right: calc(1em - 8px)
    }

.k-multiselect-wrap > .k-i-close {
    right: calc(1em - 7px)
}

.k-autocomplete > .k-i-close, .k-dropdown-wrap > .k-i-close {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.k-dropdown-wrap > .k-i-close {
    right: calc(3em - 8px)
}

.k-dropdowntree > .k-multiselect-wrap > .k-i-close, .k-multiselect-wrap > .k-i-close {
    top: calc(1.00357143em - 7px)
}

.k-dropdown-wrap .k-readonly, .k-multiselect-wrap .k-readonly {
    opacity: .5
}

.k-autocomplete.k-state-focused > .k-i-close, .k-autocomplete.k-state-hover > .k-i-close, .k-dropdown-wrap.k-state-focused > .k-i-close, .k-dropdown-wrap.k-state-hover > .k-i-close, .k-multiselect-wrap.k-state-focused > .k-i-close, .k-multiselect-wrap.k-state-hover > .k-i-close {
    display: inline-block;
    outline: 0
}

.k-multiselect.k-state-focused > .k-multiselect-wrap > .k-i-close, .k-multiselect.k-state-hover > .k-multiselect-wrap > .k-i-close {
    display: inline-block;
    outline: 0
}

.k-autocomplete > .k-hidden, .k-dropdown-wrap .k-hidden, .k-multiselect-wrap .k-hidden {
    display: none !important
}

.k-autocomplete, .k-colorpicker, .k-combobox, .k-datepicker, .k-datetimepicker, .k-dropdown, .k-dropdowntree, .k-numerictextbox, .k-selectbox, .k-timepicker, .k-toolbar .k-split-button {
    white-space: nowrap
}

.k-filter-menu .k-autocomplete, .k-filter-menu .k-combobox, .k-filter-menu .k-datepicker, .k-filter-menu .k-datetimepicker, .k-filter-menu .k-dropdown, .k-filter-menu .k-dropdowntree, .k-filter-menu .k-numerictextbox, .k-filter-menu .k-textbox, .k-filter-menu .k-timepicker {
    width: 13.2em
}

.k-colorpicker, .k-toolbar .k-split-button {
    width: auto
}

.k-datetimepicker {
    width: 15em
}

.k-autocomplete, .k-numeric-wrap, .k-picker-wrap {
    position: relative;
    cursor: default
}

.k-dropdown-wrap, .k-multiselect-wrap, .k-numeric-wrap, .k-picker-wrap {
    display: block
}

.k-list-scroller {
    position: relative;
    overflow: auto
}

.k-popup.k-calendar-container, .k-popup.k-list-container {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    padding: 2px;
    border-width: 1px;
    border-style: solid
}

.k-list-container.k-popup-dropdowntree {
    padding: 0
}

    .k-list-container.k-popup-dropdowntree .k-check-all {
        margin: 10px 10px 0
    }

    .k-list-container.k-popup-dropdowntree .k-treeview {
        box-sizing: border-box;
        padding: 10px
    }

.k-autocomplete.k-state-border-down, .k-list-container.k-state-border-down, .k-numeric-wrap.k-state-border-down, .k-picker-wrap.k-state-border-down {
    border-bottom-width: 0;
    padding-bottom: 1px
}

.k-list-container .km-scroll-container {
    padding-bottom: 6px
}

.k-autocomplete, .k-dropdown-wrap, .k-multiselect-wrap, .k-numeric-wrap, .k-picker-wrap, .k-textbox {
    border-width: 1px;
    border-style: solid
}

.k-dropdown-wrap {
    position: relative
}

.k-dropdown-wrap, .k-numeric-wrap, .k-picker-wrap {
    padding-right: 2em
}

    .k-numeric-wrap.k-expand-padding {
        padding-right: 0
    }

        .k-numeric-wrap.k-expand-padding .k-i-warning {
            right: 0
        }

.k-autocomplete, .k-textbox {
    padding: 0
}

    .k-textbox.k-space-left {
        padding-left: 2em
    }

    .k-textbox.k-space-right {
        padding-right: 2em
    }

.k-picker-wrap .k-input {
    margin: 0
}

.k-textbox .k-icon {
    top: 50%;
    margin: -8px 0 0;
    position: absolute
}

.k-space-left .k-icon {
    left: 3px
}

.k-space-right .k-icon {
    right: 3px
}

span.k-textbox:after {
    content: "\a0";
    display: block;
    height: .4px;
    overflow: hidden
}

.k-autocomplete, .k-dropdown-wrap, .k-numeric-wrap, .k-picker-wrap {
    transition: box-shadow .15s ease-out
}

    .k-dropdown-wrap .k-input, .k-numeric-wrap .k-input, .k-picker-wrap .k-input, .k-textbox > input {
        width: 100%;
        box-sizing: border-box
    }

    .k-dropdown-wrap .k-input, .k-numeric-wrap .k-input, .k-picker-wrap .k-input, .k-selectbox .k-input {
        font-family: inherit;
        border-width: 0;
        outline: 0
    }

.k-dropdown .k-input, .k-dropdowntree .k-dropdown-wrap .k-input, .k-selectbox .k-input {
    color: inherit;
    background: 0 0
}

.k-dropdown-wrap .k-select, .k-numeric-wrap .k-select, .k-picker-wrap .k-select {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    vertical-align: top;
    text-decoration: none
}

.k-combobox .k-select, .k-numeric-wrap .k-select, .k-picker-wrap .k-select {
    border-style: solid;
    border-width: 0 0 0 1px;
    border-color: inherit
}

span.k-datetimepicker .k-select, span.k-datetimepicker .k-select + .k-select {
    right: 0
}

.k-autocomplete .k-input, .k-textbox > input {
    display: block;
    width: 100%
}

.k-dropdown-wrap .k-select, .k-selectbox .k-select {
    overflow: hidden;
    border: 0;
    text-decoration: none;
    color: inherit
}

.k-dropdown .k-input, .k-dropdowntree .k-dropdown-wrap .k-input, .k-selectbox .k-input {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis
}

.k-autocomplete .k-input, .k-dropdown-wrap .k-input, .k-multiselect-wrap .k-input, .k-numeric-wrap .k-input, .k-picker-wrap .k-input, .k-selectbox .k-input, .k-textbox > input {
    line-height: 1.65em;
    padding: .17857143em 0;
    text-indent: .571em;
    border: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis
}

    .k-dropdown-wrap .k-input:before {
        content: "";
        display: inline-block;
        width: 0
    }

.k-ie .k-autocomplete .k-input {
    height: 1.65em
}

.k-ie .k-combobox .k-input, .k-ie .k-numeric-wrap .k-input, .k-ie .k-picker-wrap .k-input, .k-ie .k-textbox > input {
    height: 2em
}

.k-ie input.k-textbox {
    height: calc(2em + 2px)
}

.k-webkit .k-combobox .k-dropdown-wrap:before, .k-webkit .k-numeric-wrap:before, .k-webkit .k-picker-wrap:before {
    padding-bottom: .38em
}

.km.root .k-combobox .k-dropdown-wrap:before, .km.root .k-numeric-wrap:before, .km.root .k-picker-wrap:before {
    content: none
}

.k-combobox .k-input, .k-numeric-wrap .k-input, .k-picker-wrap .k-input {
    display: inline
}

.k-dropdown-wrap .k-select, .k-numeric-wrap .k-select, .k-picker-wrap .k-select {
    line-height: 2em;
    vertical-align: middle;
    -moz-box-sizing: border-box;
    text-align: center;
    width: 2em;
    height: 100%
}

.k-numeric-wrap .k-select {
    padding: 0
}

body .k-datetimepicker .k-select {
    border-radius: 0
}

.k-combobox .k-select, .k-dropdown, .k-dropdowntree .k-dropdown-wrap, .k-selectbox .k-icon {
    cursor: pointer
}

.k-popup {
    border-style: solid;
    border-width: 1px
}

    .k-list-optionlabel, .k-popup .k-item {
        cursor: default
    }

    .k-popup .k-calendar {
        border: 0
    }

.k-list {
    height: auto
}

.k-nodata {
    min-height: 138px;
    width: 100%;
    display: table;
    text-transform: uppercase;
    font-size: .85em;
    font-weight: lighter
}

    .k-nodata > div {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding: 11px
    }

.k-fieldselector .k-list .k-item, .k-item.k-drag-clue, .k-list-optionlabel, .k-listbox .k-item, .k-popup .k-list .k-item, .k-popup > .k-group-header, .k-popup > .k-virtual-wrap > .k-group-header {
    padding: 1px 5px 1px 5px;
    line-height: 1.8em;
    min-height: 1.8em
}

.k-listbox .k-item, .k-popup .k-list .k-item {
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    padding: 0 4px
}

    .k-popup .k-list .k-item > .k-group {
        top: -1px
    }

.k-group-header + div > .k-list > .k-item.k-first:before {
    content: " ";
    display: block;
    border-top-width: 1px;
    border-top-style: solid;
    position: absolute;
    top: -1px;
    left: 0;
    right: 0
}

.k-popup > .k-group-header, .k-popup > .k-virtual-wrap > .k-group-header {
    padding-right: 22px
}

.k-overflow-container .k-item {
    padding: 1px
}

.k-overflow-container .k-button.k-state-disabled, .k-overflow-container .k-button.k-state-disabled:hover, .k-overflow-container > .k-state-disabled .k-button, .k-overflow-container > .k-state-disabled .k-button:hover {
    border-color: transparent;
    background: 0 0
}

.k-fieldselector .k-list .k-item, .k-list-optionlabel.k-state-focused, .k-list-optionlabel.k-state-selected, .k-listbox .k-item, .k-overflow-container .k-state-focused, .k-overflow-container .k-state-hover, .k-overflow-container .k-state-selected, .k-popup .k-list .k-state-focused, .k-popup .k-list .k-state-hover, .k-popup .k-list .k-state-selected {
    padding: 0 4px;
    border-width: 1px;
    border-style: solid
}

.k-list-filter {
    position: relative;
    margin-bottom: 2px
}

    .k-list-filter > .k-textbox {
        padding-right: 20px;
        width: 100%
    }

    .k-list-filter > .k-icon {
        position: absolute;
        right: 6px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%)
    }

.km-root .k-list-filter > .k-textbox {
    padding-left: 0;
    padding-right: 0;
    border-left-width: 0;
    border-right-width: 0
}

.k-multiselect {
    display: block;
    width: auto
}

.k-multiselect-wrap {
    border-radius: 4px;
    padding-right: 22px
}

    .k-multiselect-wrap .k-input {
        min-width: 25px;
        min-height: 1.65em;
        padding: .17857143em;
        text-indent: .571em;
        background-color: transparent;
        border: 0;
        margin: 0;
        float: left
    }

        .k-multiselect-wrap .k-input::-ms-clear {
            display: none
        }

    .k-multiselect-wrap li {
        margin: 1px 0 1px 1px;
        padding: .1em 1.6em .1em .4em;
        line-height: 1.5em;
        min-height: calc(1.7em + 2px);
        float: left;
        position: relative
    }

.k-autocomplete .k-i-loading, .k-multiselect .k-i-loading {
    position: absolute;
    top: auto;
    right: .4em;
    bottom: .4em
}

.k-multiselect-wrap .k-select {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: .03em .2em
}

.k-autocomplete-clearable .k-input, .k-combobox-clearable .k-input, .k-dropdowntree-clearable .k-input, .k-multiselect-clearable .k-input {
    overflow: hidden;
    text-overflow: ellipsis
}

.k-combobox-clearable .k-input, .k-dropdowntree-clearable .k-dropdown-wrap .k-input, .k-dropdowntree-clearable .k-multiselect-wrap, .k-multiselect-clearable .k-multiselect-wrap {
    padding-right: 2em
}

.k-datetimepicker .k-picker-wrap {
    padding-right: 4em
}

.k-datetimepicker .k-select {
    width: 4em
}

    .k-datetimepicker .k-select .k-link {
        display: inline-block;
        width: 50%
    }

.k-datetimepicker .k-link {
    color: inherit
}

.k-datetimepicker .k-picker-wrap .k-icon {
    margin: 0 2px
}

.k-picker-wrap .k-icon {
    cursor: pointer
}

.k-datepicker, .k-datetimepicker, .k-textbox, .k-timepicker {
    display: inline-block;
    vertical-align: middle
}

.k-time-popup .k-item {
    padding: 1px 3px
}

.k-input {
    font-size: inherit;
    padding: .25em 0
}

.k-input, .k-textbox > input {
    outline: 0;
    -webkit-tap-highlight-color: transparent
}

.k-textbox {
    outline: 0
}

input.k-textbox, textarea.k-textbox {
    padding: .17857143em 0
}

input.k-textbox {
    line-height: 1.65em;
    text-indent: .571em
}

.k-ie input.k-textbox {
    text-indent: .2855em
}

textarea.k-textbox {
    height: auto
}

.k-input-icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 2em;
    position: relative
}

    .k-input-icon .k-icon {
        position: static;
        margin: 0
    }

.k-ie9 .k-input-icon {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0
}

.k-ie9 .k-rtl .k-input-icon, .k-ie9 [dir=rtl] .k-input-icon {
    left: 0;
    right: auto
}

span.k-numerictextbox {
    background-color: transparent
}

.k-numerictextbox .k-input {
    margin: 0
}

.k-numerictextbox .k-link {
    color: inherit;
    display: block;
    height: 1em;
    line-height: 1em;
    vertical-align: middle;
    border-width: 0;
    padding: 0;
    overflow: hidden
}

.k-numerictextbox .k-icon {
    display: block;
    margin: auto;
    height: 100%
}

.k-numerictextbox .k-i-arrow-60-up {
    top: 0
}

.k-numerictextbox .k-i-arrow-60-down {
    bottom: 0
}

.k-numeric-wrap .k-input::-webkit-inner-spin-button {
    -webkit-appearance: none
}

.k-rtl .k-multiselect-wrap {
    padding-right: 0;
    padding-left: 22px
}

.k-maskedtextbox {
    position: relative;
    display: inline-block;
    background-color: transparent;
    border-width: 0
}

    .k-maskedtextbox ::-ms-clear {
        display: none;
        width: 0;
        height: 0
    }

    .k-maskedtextbox .k-i-warning {
        display: none;
        position: absolute;
        width: 2em;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    .k-maskedtextbox.k-state-invalid .k-i-warning {
        display: inline-block
    }

.k-dateinput {
    position: relative;
    display: inline-block;
    border-width: 0
}

    .k-dateinput.k-widget {
        background-color: transparent
    }

    .k-dateinput ::-ms-clear {
        display: none;
        width: 0;
        height: 0
    }

    .k-dateinput .k-i-warning {
        display: none;
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        overflow: visible
    }

    .k-dateinput.k-state-invalid .k-i-warning {
        display: inline-block
    }

.k-datepicker .k-picker-wrap .k-i-warning, .k-datetimepicker .k-picker-wrap .k-i-warning, .k-timepicker .k-picker-wrap .k-i-warning {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: visible
}

.k-datepicker .k-picker-wrap.k-state-invalid .k-i-warning, .k-datetimepicker .k-picker-wrap.k-state-invalid .k-i-warning, .k-timepicker .k-picker-wrap.k-state-invalid .k-i-warning {
    display: inline-block
}

.k-listbox {
    box-sizing: border-box;
    height: 200px
}

    .k-listbox.k-widget {
        background-color: transparent
    }

    .k-listbox .k-list-scroller {
        height: 100%;
        overflow: auto;
        border-width: 1px;
        border-style: solid;
        box-sizing: border-box
    }

        .k-listbox .k-list-scroller li {
            cursor: default;
            -moz-user-select: none;
            -ms-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -webkit-touch-callout: none
        }

    .k-listbox .k-listbox-toolbar {
        box-sizing: border-box
    }

    .k-listbox.k-listbox-toolbar-top {
        padding: 15px 0 1.42857143em
    }

        .k-listbox.k-listbox-toolbar-top .k-listbox-toolbar {
            margin-top: -15px;
            margin-bottom: 5px
        }

            .k-listbox.k-listbox-toolbar-top .k-listbox-toolbar li {
                display: inline-block
            }

                .k-listbox.k-listbox-toolbar-top .k-listbox-toolbar li + li {
                    margin-left: 5px
                }

    .k-listbox.k-listbox-toolbar-left .k-listbox-toolbar {
        float: left;
        margin-right: 5px
    }

        .k-listbox.k-listbox-toolbar-left .k-listbox-toolbar li + li {
            margin-top: 5px
        }

    .k-listbox.k-listbox-toolbar-right .k-listbox-toolbar {
        float: right;
        margin-left: 5px
    }

        .k-listbox.k-listbox-toolbar-right .k-listbox-toolbar li:not(:last-child) {
            margin-bottom: 5px
        }

    .k-listbox.k-listbox-toolbar-bottom {
        padding: 15px 0 1.45em
    }

        .k-listbox.k-listbox-toolbar-bottom .k-list-scroller {
            margin-top: -15px
        }

        .k-listbox.k-listbox-toolbar-bottom .k-listbox-toolbar {
            margin-top: 5px
        }

            .k-listbox.k-listbox-toolbar-bottom .k-listbox-toolbar li {
                display: inline-block
            }

                .k-listbox.k-listbox-toolbar-bottom .k-listbox-toolbar li + li {
                    margin-left: 5px
                }

    .k-listbox .k-ghost {
        opacity: .5
    }

.k-rtl .k-combobox-clearable .k-input, .k-rtl .k-dropdown-wrap, .k-rtl .k-dropdowntree-clearable .k-dropdown-wrap .k-input, .k-rtl .k-dropdowntree-clearable .k-multiselect-wrap, .k-rtl .k-multiselect-clearable .k-multiselect-wrap, .k-rtl .k-numeric-wrap, .k-rtl .k-picker-wrap {
    padding-right: 0;
    padding-left: 2em
}

    .k-rtl .k-dropdown-wrap > .k-i-close {
        left: calc(3em - 8px)
    }

.k-rtl .k-autocomplete > .k-i-close {
    left: calc(1em - 8px)
}

.k-rtl .k-multiselect-wrap > .k-i-close {
    left: calc(1em - 7px)
}

.k-rtl .k-numeric-wrap.k-state-invalid.k-expand-padding .k-i-warning {
    left: 0
}

.k-rtl .k-listbox.k-listbox-toolbar-left .k-listbox-toolbar, .k-rtl .k-listbox.k-listbox-toolbar-right .k-listbox-toolbar {
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1)
}

.k-rtl .k-listbox.k-listbox-toolbar-left .k-listbox-toolbar {
    float: right;
    margin-right: 0;
    margin-left: 5px
}

.k-rtl .k-listbox.k-listbox-toolbar-right .k-listbox-toolbar {
    float: left;
    margin-right: 5px;
    margin-left: 0
}

.k-multiple-selection .k-in.k-state-selected {
    border-color: transparent;
    color: inherit;
    background: 0 0
}

.k-popup-flush, .k-popup-flush.k-list-container {
    padding: 0
}

.k-dropdowngrid-popup {
    overflow: hidden
}

    .k-dropdowngrid-popup .k-footer, .k-dropdowngrid-popup .k-grid-header, .k-dropdowngrid-popup .k-group-header, .k-dropdowngrid-popup .k-list-scroller, .k-dropdowngrid-popup .k-virtual-content {
        line-height: 1.42857143
    }

    .k-dropdowngrid-popup .k-virtual-wrap {
        margin: 0
    }

    .k-dropdowngrid-popup .k-grid-header table {
        margin: 0 0 -1px 0;
        width: 100%;
        max-width: none;
        border-width: 0;
        border-collapse: separate;
        border-spacing: 0;
        table-layout: fixed;
        empty-cells: show;
        outline: 0
    }

.k-grid-list {
    width: 100%;
    max-width: none;
    border-width: 0;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    empty-cells: show;
    outline: 0;
    display: table
}

    .k-grid-list > .k-item {
        box-sizing: border-box;
        display: table-row;
        position: relative
    }

        .k-grid-list > .k-item > .k-cell, .k-grid-list > .k-item > .k-group, .k-grid-list > .k-item > .k-spacer-group {
            box-sizing: border-box;
            display: table-cell;
            vertical-align: middle
        }

    .k-grid-list.k-virtual-list > .k-item > .k-cell, .k-grid-list.k-virtual-list > .k-item > .k-group, .k-grid-list.k-virtual-list > .k-item > .k-spacer-group {
        display: inline-block
    }

    .k-dropdowngrid-popup .k-grid-header .k-header, .k-grid-list > .k-item > .k-cell {
        border-width: 0 0 1px 1px;
        border-style: solid;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden
    }

.k-dropdowngrid-popup .k-grid-header .k-header {
    padding: 6px 8px
}

.k-grid-list > .k-item > .k-cell {
    padding: 6px 8px
}

    .k-dropdowngrid-popup .k-grid-header .k-header:first-child, .k-grid-list > .k-item > .k-cell:first-child {
        border-left-width: 0
    }

div.k-dropdowngrid-popup .k-group-header {
    margin: 0;
    padding: 6px 8px;
    min-height: auto;
    text-align: left
}

.k-grid-list > .k-item > .k-group-cell, .k-grid-list > .k-item > .k-spacer-cell {
    padding-left: 0;
    padding-right: 0;
    width: 0;
    border-left-width: 0;
    border-right-width: 0;
    overflow: visible;
    position: relative
}

    .k-grid-list > .k-item > .k-group-cell > span {
        padding: 0 4px;
        font-size: .875em;
        position: absolute;
        top: 0;
        right: 0
    }

.k-grid-list > .k-item:last-child > .k-cell, .k-grid-list > .k-item:last-child > .k-group-cell, .k-grid-list > .k-item:last-child > .k-spacer-cell {
    border-bottom-width: 0
}

.k-dropdowngrid-popup .k-footer {
    padding: 6px 8px;
    border-width: 1px 0 0 0;
    border-style: solid;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative
}

.k-rtl .k-grid-list > .k-item > .k-cell {
    border-left-width: 1px;
    border-right-width: 1px;
    text-align: right
}

    .k-rtl .k-grid-list > .k-item > .k-cell:first-child {
        border-right-width: 0
    }

.k-rtl div.k-dropdowngrid-popup .k-group-header {
    text-align: right
}

.k-rtl .k-grid-list > .k-item > .k-group-cell > span {
    right: auto;
    left: 0
}

.k-textbox-container {
    position: relative;
    padding-top: 1.2375em;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 12.4em;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: stretch;
    justify-content: stretch
}

    .k-textbox-container > .k-label {
        pointer-events: none;
        position: absolute;
        line-height: 1.65em;
        cursor: text;
        top: calc(1.41607143em + 1px);
        left: 1px;
        transition: color .2s ease-out,-webkit-transform .2s ease-out;
        transition: transform .2s ease-out,color .2s ease-out;
        transition: transform .2s ease-out,color .2s ease-out,-webkit-transform .2s ease-out
    }

    .k-textbox-container > .k-textarea, .k-textbox-container > .k-textbox, .k-textbox-container > .k-widget {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: auto
    }

    .k-textbox-container.k-state-empty > .k-label {
        -webkit-transform: translate(0,0) scale(1);
        -ms-transform: translate(0,0) scale(1);
        transform: translate(0,0) scale(1)
    }

    .k-textbox-container.k-state-focused {
        box-shadow: none
    }

        .k-textbox-container.k-state-focused > .k-label, .k-textbox-container > .k-label {
            -webkit-transform: translate(0,-.1339285725em) translate(-1px,-1.2375em) translate(-12.5%,-9.375%) scale(.75);
            -ms-transform: translate(0,-.1339285725em) translate(-1px,-1.2375em) translate(-12.5%,-9.375%) scale(.75);
            transform: translate(0,-.1339285725em) translate(-1px,-1.2375em) translate(-12.5%,-9.375%) scale(.75)
        }

    .k-rtl .k-textbox-container > .k-label, .k-textbox-container[dir=rtl] > .k-label {
        left: auto;
        right: 1px
    }

    .k-rtl .k-textbox-container.k-state-empty > .k-label, .k-textbox-container[dir=rtl].k-state-empty > .k-label {
        -webkit-transform: translate(0,0) scale(1);
        -ms-transform: translate(0,0) scale(1);
        transform: translate(0,0) scale(1)
    }

    .k-rtl .k-textbox-container.k-state-focused > .k-label, .k-rtl .k-textbox-container > .k-label, .k-textbox-container[dir=rtl].k-state-focused > .k-label, .k-textbox-container[dir=rtl] > .k-label {
        -webkit-transform: translate(0,-.1339285725em) translate(1px,-1.2375em) translate(12.5%,-9.375%) scale(.75);
        -ms-transform: translate(0,-.1339285725em) translate(1px,-1.2375em) translate(12.5%,-9.375%) scale(.75);
        transform: translate(0,-.1339285725em) translate(1px,-1.2375em) translate(12.5%,-9.375%) scale(.75)
    }

.k-colorpicker .k-picker-wrap {
    line-height: 2.00714286em
}

.k-colorpicker .k-selected-color {
    vertical-align: top;
    display: inline-block;
    height: 2em;
    width: 2em
}

    .k-colorpicker .k-selected-color .k-i-line {
        font-size: 2em;
        color: #FF525E;
        display: inline-block;
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1);
        transform: scaleX(-1);
        -webkit-filter: FlipH;
        filter: FlipH;
        -ms-filter: "FlipH"
    }

        .k-colorpicker .k-selected-color .k-i-line:before {
            content: "\e510"
        }

.k-colorpicker .k-tool-icon {
    position: relative;
    top: -2px;
    display: inline-block;
    padding: 3px 3px 2px;
    margin-right: 3px;
    margin-left: 2px;
    margin-bottom: 3px;
    background-repeat: no-repeat;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    -ms-high-contrast-adjust: none
}

    .k-colorpicker .k-tool-icon .k-selected-color {
        display: block;
        height: 3px;
        width: 16px;
        position: absolute;
        left: 3px;
        bottom: -3px;
        border-radius: 0 !important
    }

.k-colorpicker .k-select {
    cursor: pointer
}

.k-colorpicker .k-picker-wrap .k-icon {
    overflow: visible
}

.k-flatcolorpicker {
    position: relative;
    display: inline-block;
    width: 265px;
    padding-bottom: 5px;
    background-color: transparent;
    background-image: none
}

    .k-flatcolorpicker .k-selected-color {
        background-image: url(textures/transtexture.png);
        background-position: 50% 50%;
        text-align: right
    }

        .k-flatcolorpicker .k-selected-color input.k-color-value {
            font-family: Consolas,"Ubuntu Mono","Lucida Console","Courier New",monospace;
            padding: .75em .1em .65em .1em;
            border: 0;
            margin: 0;
            width: 84%
        }

        .k-flatcolorpicker .k-selected-color .k-color-input {
            background-color: #fff;
            display: -ms-inline-flexbox;
            display: inline-flex;
            width: 84%;
            text-align: left;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: center;
            justify-content: center;
            vertical-align: middle
        }

        .k-flatcolorpicker .k-selected-color .k-clear-color {
            padding: .4em;
            line-height: 0
        }

            .k-flatcolorpicker .k-selected-color .k-clear-color .k-i-reset-color:before {
                color: #000;
                opacity: .8
            }

            .k-flatcolorpicker .k-selected-color .k-clear-color .k-i-reset-color:active, .k-flatcolorpicker .k-selected-color .k-clear-color .k-i-reset-color:hover {
                opacity: 1
            }

    .k-flatcolorpicker .k-clear-color-container .k-clear-color.k-state-focused:active {
        color: inherit
    }

    .k-flatcolorpicker .k-clear-color-container .k-clear-color:active {
        color: #000;
        opacity: 1
    }

    .k-flatcolorpicker .k-clear-color-container .k-clear-color .k-i-reset-color, .k-flatcolorpicker .k-selected-color .k-clear-color .k-i-reset-color {
        background-image: none
    }

    .k-flatcolorpicker .k-clear-color-container {
        text-align: left
    }

    .k-flatcolorpicker .k-hsv-rectangle {
        position: relative;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -ms-touch-action: pinch-zoom double-tap-zoom
    }

        .k-flatcolorpicker .k-hsv-rectangle .k-draghandle {
            cursor: pointer;
            position: absolute;
            z-index: 10;
            left: 50%;
            top: 50%;
            width: 8px;
            height: 8px;
            border: 1px solid #eee;
            margin-left: -5px;
            margin-top: -5px;
            border-radius: 6px;
            box-shadow: 0 1px 2px #444;
            background: 0 0
        }

            .k-flatcolorpicker .k-hsv-rectangle .k-draghandle:focus, .k-flatcolorpicker .k-hsv-rectangle .k-draghandle:hover {
                background: 0 0;
                border-color: #fff;
                box-shadow: 0 1px 5px #000
            }

        .k-flatcolorpicker .k-hsv-rectangle.k-dragging, .k-flatcolorpicker .k-hsv-rectangle.k-dragging * {
            cursor: none
        }

    .k-flatcolorpicker .k-slider-horizontal {
        height: 20px;
        width: 90%;
        margin: 0 5%
    }

        .k-flatcolorpicker .k-slider-horizontal .k-slider-track {
            box-shadow: 0 1px 0 #fff,0 -1px 0 #999
        }

    .k-flatcolorpicker .k-hue-slider, .k-flatcolorpicker .k-transparency-slider {
        display: block
    }

        .k-flatcolorpicker .k-hue-slider .k-slider-selection, .k-flatcolorpicker .k-transparency-slider .k-slider-selection {
            background: 0 0
        }

        .k-flatcolorpicker .k-hue-slider .k-draghandle, .k-flatcolorpicker .k-transparency-slider .k-draghandle {
            background: 0 0;
            border: 3px solid #eee;
            margin-top: 1px;
            height: 8px;
            width: 8px;
            box-shadow: 0 1px 4px #444
        }

            .k-flatcolorpicker .k-hue-slider .k-draghandle:focus, .k-flatcolorpicker .k-hue-slider .k-draghandle:hover, .k-flatcolorpicker .k-transparency-slider .k-draghandle:focus, .k-flatcolorpicker .k-transparency-slider .k-draghandle:hover {
                background: 0 0;
                border-color: #fff;
                box-shadow: 0 1px 5px #000;
                border-width: 2px;
                padding: 1px
            }

        .k-flatcolorpicker .k-hue-slider .k-slider-track {
            background: linear-gradient(to right,red 0,#ff0 16%,#0f0 33%,#0ff 50%,#00f 67%,#f0f 84%,#ff0004 100%)
        }

        .k-flatcolorpicker .k-transparency-slider .k-slider-track {
            background-image: url(textures/transparency.png);
            background-size: 100% auto;
            background-position: 100% 50%;
            background-repeat: no-repeat
        }

    .k-flatcolorpicker .k-controls {
        margin-top: 10px;
        margin-bottom: 5px;
        text-align: center;
        font-size: 90%
    }

        .k-flatcolorpicker .k-controls .k-button {
            width: 6em
        }

    .k-flatcolorpicker .k-hsv-gradient {
        background: linear-gradient(to bottom,rgba(0,0,0,0) 0,#000 100%),linear-gradient(to right,#fff 0,rgba(255,255,255,0) 100%);
        height: 180px;
        margin-bottom: 5px
    }

.k-ie9 .k-flatcolorpicker .k-hue-slider .k-slider-track {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjE2JSIgc3RvcC1jb2xvcj0iI2ZmZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjMzJSIgc3RvcC1jb2xvcj0iIzAwZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzAwZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY3JSIgc3RvcC1jb2xvcj0iIzAwMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijg0JSIgc3RvcC1jb2xvcj0iI2ZmMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjAwMDQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+)
}

.k-ie9 .k-flatcolorpicker .k-hsv-gradient {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+),url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+)
}

.k-colorpalette {
    position: relative;
    line-height: 0;
    border-width: 0;
    display: inline-block
}

    .k-colorpalette .k-palette {
        border-collapse: collapse;
        position: relative;
        width: 100%;
        height: 100%
    }

    .k-colorpalette .k-item {
        width: 14px;
        height: 14px;
        overflow: hidden;
        -ms-high-contrast-adjust: none
    }

        .k-colorpalette .k-item.k-state-selected, .k-colorpalette .k-item.k-state-selected:hover {
            z-index: 100;
            background: 0 0;
            box-shadow: 0 1px 3px 1px rgba(0,0,0,.7),inset 0 0 0 1px rgba(255,255,255,.45);
            position: relative
        }

        .k-colorpalette .k-item:hover {
            z-index: 101;
            position: relative;
            box-shadow: 0 1px 3px 1px rgba(0,0,0,.5),inset 0 0 0 1px rgba(255,255,255,.3)
        }

table.k-editor {
    width: 100%;
    height: 250px;
    table-layout: fixed;
    border-style: solid;
    border-width: 1px;
    border-collapse: separate;
    border-spacing: 4px;
    font-size: 100%;
    vertical-align: top;
    position: relative
}

.k-editor-inline {
    padding: .3em .5em;
    word-wrap: break-word;
    overflow: auto;
    position: relative;
    transition: border-color .5s
}

    .k-editor-inline.k-editor {
        border: 1px solid transparent
    }

.k-editortoolbar-dragHandle {
    cursor: move;
    box-shadow: none !important
}

.k-editor-widget > .k-window-content {
    overflow: hidden
}

.k-editor .k-editor-toolbar-wrap {
    border: 0;
    padding: 0
}

.k-editor-toolbar {
    margin: 0;
    padding: .1em 0;
    list-style-type: none;
    line-height: 1.3em;
    cursor: default;
    word-wrap: break-word
}

    .k-editor-toolbar li {
        display: inline-block;
        vertical-align: middle
    }

.k-edge .k-editor-toolbar, .k-ff .k-editor-toolbar, .k-ie9 .k-editor-toolbar, .k-webkit .k-editor-toolbar {
    padding: 0
}

.k-editor-toolbar li {
    display: inline-block;
    padding: .1em 0
}

.k-editor-toolbar .k-editor-widget, .k-editor-toolbar > li {
    margin-right: 6px
}

.k-group-start.k-group-end .k-editor-widget {
    margin-right: 0
}

.k-editor-toolbar .k-editor-dropdown {
    position: relative
}

.k-select-overlay {
    -webkit-appearance: none;
    opacity: 0;
    z-index: 11000;
    top: 0;
    left: 0;
    position: absolute;
    height: 26px;
    width: 100%;
    margin: -4px 0 0
}

.k-editor-toolbar .k-separator {
    position: relative;
    top: 1px;
    border-style: solid;
    border-width: 0 1px 0 0;
    margin: 0 .3em 0 .1em;
    padding: 0 0 0 1px;
    font-size: 1.3em
}

.k-editor-toolbar .k-break {
    display: block;
    height: 1px;
    font-size: 0;
    line-height: 0
}

.k-editor-toolbar .k-colorpicker, .k-editor-toolbar .k-combobox, .k-editor-toolbar .k-dropdown, .k-editor-toolbar .k-selectbox {
    vertical-align: middle
}

.k-button-group .k-tool {
    margin: 1px -1px 1px 0;
    width: 2em;
    height: 2em;
    border-width: 1px;
    border-style: solid;
    line-height: 2em;
    position: relative;
    display: inline-block;
    vertical-align: middle
}

    .k-button-group .k-tool.k-state-hover, .k-button-group .k-tool:focus {
        z-index: 1
    }

.k-button-group .k-tool-icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    -ms-high-contrast-adjust: none
}

.k-fontName {
    width: 110px
}

.k-fontSize {
    width: 124px
}

.k-formatBlock {
    width: 147px
}

.k-editortoolbar-dragHandle {
    float: left;
    margin: 1px 0 0
}

.k-editor-toolbar .k-button-group {
    padding: 1px
}

.k-editor .k-editor-toolbar .k-row-break {
    display: block;
    height: 0;
    font-size: 0;
    line-height: 0
}

.k-editor-toolbar .k-i-login {
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1)
}

.k-editor-toolbar .k-button-group .k-state-disabled, .k-editor-toolbar .k-button-group.k-state-disabled {
    display: none !important
}

.k-editor-toolbar .k-button-group .k-state-active, .k-editor-toolbar .k-button-group .k-state-hover {
    vertical-align: middle
}

.k-editor-toolbar .k-button-group .k-state-disabled {
    filter: alpha(opacity=30);
    opacity: .3
}

.k-editor .k-editable-area {
    width: 100%;
    height: 100%;
    outline: 0
}

.k-editor.k-resizable .k-editable-area {
    padding-bottom: 16px
}

.k-editor .k-content {
    display: block;
    width: 100%;
    height: 100%;
    border-width: 0;
    margin: 0;
    padding: 0;
    background: #fff
}

.k-editor .k-editable-area .k-content {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid
}

a.k-tool {
    color: inherit;
    outline: 0
}

.k-editor iframe.k-content {
    display: inline;
    vertical-align: top
}

.k-editor .k-raw-content {
    border: 0;
    margin: 0;
    padding: 0
}

.k-editor .k-raw-content, .k-editor-dialog .k-editor-textarea {
    font-size: inherit;
    font-family: consolas,"courier new",monospace
}

.k-editor-dialog {
    padding: 1em;
    width: 400px
}

    .k-editor-dialog .k-edit-label {
        width: 25%
    }

    .k-editor-dialog .k-edit-field {
        width: 66%
    }

        .k-editor-dialog .k-edit-field .k-textbox {
            width: 96%
        }

.k-viewhtml-dialog {
    width: auto
}

.k-filebrowser-dialog {
    width: auto;
    min-width: 350px
}

    .k-filebrowser-dialog .k-filebrowser {
        margin: 0 1em 0
    }

    .k-filebrowser-dialog .k-edit-label {
        width: 18%
    }

    .k-filebrowser-dialog .k-edit-field {
        width: 75%
    }

        .k-filebrowser-dialog .k-edit-field .k-textbox {
            width: 70%
        }

#k-editor-image-height, #k-editor-image-width {
    width: 5em
}

.k-editor-dialog .k-button {
    display: inline-block
}

.k-editor-dialog .k-editor-textarea {
    width: 600px;
    height: 350px;
    padding: .2em .2em .2em .4em;
    border-width: 1px;
    border-style: solid;
    overflow: auto
}

.k-button-wrapper .k-link:hover {
    text-decoration: underline
}

.k-ct-popup {
    width: 180.4px;
    padding: .65em .5em .5em
}

    .k-ct-popup .k-button {
        width: 100%
    }

    .k-ct-popup .k-status {
        margin: .3em 0
    }

    .k-ct-popup .k-ct-cell {
        border-width: 1px;
        border-style: solid;
        width: 18px;
        height: 18px;
        margin: 1px;
        vertical-align: top;
        display: inline-block;
        overflow: hidden;
        pointer-events: all;
        -ms-high-contrast-adjust: none
    }

.k-editor .k-resize-handle {
    position: absolute;
    padding: 2px 0;
    right: 0;
    bottom: 0;
    cursor: se-resize
}

.k-editor .k-overlay {
    position: absolute;
    background-color: #fff;
    opacity: 0
}

.k-editor .k-toolbar-resizable {
    min-height: 2.4375em;
    position: relative
}

.k-editor-toolbar {
    width: 100%;
    position: relative;
    -ms-flex: 1;
    flex: 1
}

.editorToolbarWindow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: start;
    align-content: flex-start
}

.k-editortoolbar-dragHandle {
    position: relative;
    padding: 0;
    margin: 0 3px 0 0
}

.k-editor-toolbar .k-overflow-tools {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0
}

.k-editor-toolbar .k-tool-group .k-widget:last-child {
    margin-right: 0
}

.k-editor-overflow-popup .k-tool {
    width: auto;
    height: auto;
    display: block;
    text-align: left;
    text-decoration: initial;
    border: 0;
    padding-right: .5em;
    margin: 0;
    padding: 0 6px
}

    .k-editor-overflow-popup .k-tool.k-state-disabled {
        display: none
    }

.k-editor-toolbar .k-tool-text {
    display: none
}

.k-editor-inline .k-table-resize-handle-wrapper {
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: #fff;
    border: 1px solid #000;
    z-index: 100
}

    .k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle {
        width: 100%;
        height: 100%
    }

        .k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-east {
            cursor: e-resize
        }

        .k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-north {
            cursor: n-resize
        }

        .k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-northeast {
            cursor: ne-resize
        }

        .k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-northwest {
            cursor: nw-resize
        }

        .k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-south {
            cursor: s-resize
        }

        .k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-southeast {
            cursor: se-resize
        }

        .k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-southwest {
            cursor: sw-resize
        }

        .k-editor-inline .k-table-resize-handle-wrapper .k-table-resize-handle.k-resize-west {
            cursor: w-resize
        }

.k-editor-inline .k-column-resize-handle-wrapper {
    position: absolute;
    height: 10px;
    width: 10px;
    cursor: col-resize;
    z-index: 2
}

    .k-editor-inline .k-column-resize-handle-wrapper .k-column-resize-handle {
        width: 100%;
        height: 100%
    }

        .k-editor-inline .k-column-resize-handle-wrapper .k-column-resize-handle .k-column-resize-marker {
            width: 2px;
            height: 100%;
            margin: 0 auto;
            background-color: #00b0ff;
            display: none;
            opacity: .8
        }

.k-editor-inline .k-table {
    width: 100%;
    border-spacing: 0;
    margin: 0 0 1em
}

    .k-editor-inline .k-table, .k-editor-inline .k-table td {
        outline: 0;
        border: 1px dotted #ccc
    }

        .k-editor-inline .k-table td {
            min-width: 1px;
            padding: .2em .3em
        }

.k-table.k-table-resizing {
    opacity: .6
}

.k-editor-inline .k-row-resize-handle-wrapper {
    position: absolute;
    z-index: 2;
    cursor: row-resize;
    width: 10px;
    height: 10px
}

    .k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-handle {
        display: table;
        width: 100%;
        height: 100%
    }

    .k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-marker-wrapper {
        display: table-cell;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        vertical-align: middle
    }

    .k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-marker {
        display: none;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 2px;
        background-color: #00b0ff;
        opacity: .8
    }

.k-editor-dialog.k-viewhtml-dialog .k-edit-buttons {
    clear: both;
    text-align: right;
    border-width: 1px 0 0;
    border-style: solid;
    position: relative;
    bottom: -1em;
    padding: .6em
}

    .k-editor-dialog.k-viewhtml-dialog .k-edit-buttons .k-button {
        margin-right: .5em
    }

.k-editor-dialog.k-editor-table-wizard-dialog.k-window-content {
    overflow: hidden;
    min-width: 325px;
    width: 24em;
    padding: 1em 0
}

.k-editor-dialog .k-edit-form-container {
    width: auto
}

.k-editor-dialog .k-tabstrip {
    margin: -1.333em 0 0
}

.k-editor-dialog .k-root-tabs {
    margin: -1em -1em -1.5em;
    padding: .5em 1em 0;
    border-width: 0
}

.k-editor-dialog ul.k-tabstrip-items {
    padding: .5em 1em 0;
    border-width: 0 0 1px;
    border-style: solid
}

    .k-editor-dialog ul.k-tabstrip-items .k-loading {
        border-top-width: 0
    }

.k-editor-dialog .k-tabstrip:focus {
    box-shadow: none
}

.k-editor-dialog.k-editor-table-wizard-dialog .k-tabstrip-wrapper .k-content {
    overflow: hidden;
    min-height: 440px;
    height: 35em;
    margin: 0;
    padding: 1em .5em 0;
    border-width: 0
}

.k-editor-dialog .k-edit-field .k-numerictextbox {
    width: 10.6em;
    margin: 0 .35714285em 0 0
}

.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-numerictextbox + .k-dropdown {
    width: 4em
}

.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-dropdown, .k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-textbox {
    width: 15em
}

.k-editor-table-wizard-dialog .k-numerictextbox + .k-dropdown {
    width: 5.35714285em
}

.k-editor-dialog textarea.k-textbox {
    height: 14.28571428em
}

    .k-editor-dialog textarea.k-textbox.k-editor-accessibility-summary {
        height: auto
    }

.k-editor-dialog.k-editor-table-wizard-dialog .k-dropdown.k-align {
    width: 4em
}

.k-popup.k-list-container.k-align {
    padding: 0;
    text-align: center
}

.k-align-group {
    vertical-align: text-top
}

.k-popup.k-align .k-list .k-item {
    display: inline-block;
    width: 2.57142857em;
    height: 2.57142857em;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0
}

.k-popup.k-align .k-list .k-icon {
    vertical-align: bottom;
    vertical-align: -webkit-baseline-middle
}

.k-popup.k-align .k-list .k-item:last-child {
    width: auto;
    display: block;
    border-width: 1px 0 0;
    border-style: solid
}

.k-popup.k-align .k-list .k-item:before {
    width: 2.57142857em;
    height: 2.57142857em;
    line-height: 2.57142857em
}

.k-animation-container .k-editor-toolbar {
    text-align: center;
    line-height: 1.3em
}

    .k-animation-container .k-editor-toolbar .k-tool {
        display: inline-block;
        height: 24px;
        padding: 0 .5em;
        text-decoration: none;
        font-size: .85em;
        color: inherit
    }

    .k-animation-container .k-editor-toolbar .k-i-table-wizard-insert {
        width: 24px;
        height: 24px
    }

    .k-animation-container .k-editor-toolbar .k-tool-text {
        display: inline;
        vertical-align: sub;
        padding: 0 0 0 .5em
    }

.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-buttons {
    padding: 1em .8em;
    margin: .5em 0 0
}

.k-notification {
    border-width: 1px;
    border-style: solid;
    cursor: default;
    position: relative;
    white-space: nowrap;
    box-sizing: border-box
}

    .k-notification > .k-notification-wrap {
        padding: .6em .5em
    }

.k-notification-closable > .k-notification-wrap {
    padding-right: 20px
}

.k-notification .k-notification-content {
    display: inline-block;
    vertical-align: top;
    white-space: normal
}

.k-notification-wrap > .k-i-error, .k-notification-wrap > .k-i-info, .k-notification-wrap > .k-i-success, .k-notification-wrap > .k-i-warning {
    margin-right: 4px;
    vertical-align: text-bottom
}

.k-notification-wrap > .k-i-close {
    position: absolute;
    top: 50%;
    right: 4px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: none
}

.k-notification-closable .k-notification-wrap > .k-i-close {
    display: block
}

.k-rtl .k-notification-closable .k-notification-wrap, .k-rtl.k-notification-closable .k-notification-wrap {
    padding-right: .5em;
    padding-left: 20px
}

.k-rtl .k-notification-wrap > .k-i-error, .k-rtl .k-notification-wrap > .k-i-info, .k-rtl .k-notification-wrap > .k-i-success, .k-rtl .k-notification-wrap > .k-i-warning {
    margin-right: 0;
    margin-left: 4px
}

.k-rtl .k-notification-wrap > .k-i-close {
    right: auto;
    left: 4px
}

.k-progressbar {
    display: inline-block;
    position: relative;
    vertical-align: middle
}

.k-progressbar {
    border-radius: 4px
}

.k-progressbar-horizontal {
    width: 27em;
    height: 1.9em
}

.k-progressbar-vertical {
    width: 1.9em;
    height: 27em
}

.k-progressbar > .k-state-selected {
    position: absolute;
    border-style: solid;
    border-width: 1px;
    overflow: hidden
}

.k-progressbar-horizontal > .k-state-selected, .k-rtl .k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected {
    left: -1px;
    right: auto;
    top: -1px;
    height: 100%;
    border-radius: 4px 0 0 4px
}

.k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected, .k-rtl .k-progressbar-horizontal > .k-state-selected {
    left: auto;
    right: -1px;
    border-radius: 0 4px 4px 0
}

.k-progressbar-vertical > .k-state-selected {
    left: -1px;
    bottom: -1px;
    width: 100%;
    border-radius: 0 0 4px 4px
}

.k-progressbar-vertical.k-progressbar-reverse > .k-state-selected {
    bottom: auto;
    top: -1px;
    border-radius: 4px 4px 0 0
}

.k-progressbar > .k-state-selected.k-complete, .k-rtl .k-progressbar > .k-state-selected.k-complete {
    border-radius: 4px
}

.k-progressbar > .k-reset {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    left: -1px;
    top: -1px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    white-space: nowrap
}

.k-progressbar-horizontal .k-item {
    display: inline-block;
    height: 100%;
    border-style: solid;
    margin-left: -1px
}

    .k-progressbar-horizontal .k-item.k-first {
        margin-left: 0
    }

    .k-progressbar-horizontal .k-item.k-last {
        border-right-width: 0
    }

.k-progressbar-horizontal .k-item, .k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-item {
    border-width: 1px 0 1px 1px
}

.k-progressbar-horizontal.k-progressbar-reverse .k-item, .k-rtl .k-progressbar-horizontal .k-item {
    border-width: 1px 0 1px 1px
}

.k-progressbar-horizontal .k-first, .k-rtl .k-progressbar-horizontal .k-last, .k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-last {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left-width: 1px
}

.k-progressbar-horizontal .k-last, .k-rtl .k-progressbar-horizontal .k-first {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.k-progressbar-horizontal.k-progressbar-reverse .k-last, .k-rtl .k-progressbar-horizontal .k-first {
    border-right-width: 1px
}

.k-progressbar-horizontal .k-last.k-state-selected {
    border-right-width: 1px
}

.k-progressbar-vertical .k-item {
    width: 100%;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    margin-top: -1px
}

    .k-progressbar-vertical .k-item.k-first {
        margin-top: 0
    }

.k-progressbar-vertical li.k-item.k-last {
    border-bottom-width: 0
}

.k-progressbar-vertical .k-first {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.k-progressbar-vertical .k-last {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-width: 1px
}

.k-progressbar-vertical.k-progressbar-reverse .k-item {
    border-width: 0 1px 1px 1px
}

.k-progressbar-vertical.k-progressbar-reverse .k-first {
    border-top-width: 1px
}

.k-progress-status-wrap {
    position: absolute;
    top: -1px;
    border: 1px solid transparent;
    line-height: 2em;
    width: 100%;
    height: 100%
}

.k-progress-status-wrap, .k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-progress-status-wrap {
    left: -1px;
    right: auto;
    text-align: right
}

.k-progressbar-horizontal.k-progressbar-reverse .k-progress-status-wrap, .k-rtl .k-progressbar-horizontal .k-progress-status-wrap {
    left: auto;
    right: -1px;
    text-align: left
}

.k-progressbar-vertical .k-progress-status-wrap {
    top: auto;
    bottom: -1px
}

.k-progressbar-vertical.k-progressbar-reverse .k-progress-status-wrap {
    bottom: auto;
    top: -1px
}

.k-progress-status {
    display: inline-block;
    padding: 0 .5em;
    min-width: 10px;
    white-space: nowrap
}

.k-progressbar-vertical.k-progressbar-reverse .k-progress-status {
    position: absolute;
    bottom: 0;
    left: 0
}

.k-progressbar-vertical .k-progress-status {
    -webkit-transform: rotate(-90deg) translateX(-100%);
    -ms-transform: rotate(-90deg) translateX(-100%);
    transform: rotate(-90deg) translateX(-100%);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0
}

.k-progressbar-vertical.k-progressbar-reverse .k-progress-status {
    -webkit-transform: rotate(90deg) translateX(-100%);
    -ms-transform: rotate(90deg) translateX(-100%);
    transform: rotate(90deg) translateX(-100%);
    -webkit-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%
}

div.k-slider {
    position: relative;
    border-width: 0;
    background-color: transparent;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.k-slider-vertical {
    width: 26px;
    height: 200px
}

.k-slider-horizontal {
    display: inline-block;
    width: 200px;
    height: 26px
}

.k-slider-wrap {
    width: 100%;
    height: 100%
}

.k-grid .k-slider .k-button, .k-slider .k-button {
    position: absolute;
    top: 0;
    width: 24px;
    min-width: 0;
    height: 24px;
    margin: 0;
    padding: 0;
    outline: 0
}

.k-state-disabled .k-slider-wrap {
    filter: alpha(opacity=60);
    opacity: .6
}

    .k-state-disabled .k-slider-wrap .k-slider-items {
        color: #333
    }

.k-slider .k-button-decrease {
    left: 0
}

.k-grid .k-slider-vertical .k-button-decrease, .k-slider-vertical .k-button-decrease {
    top: auto;
    bottom: 0
}

.k-slider .k-button-increase {
    right: 0
}

.k-slider .k-icon, .k-slider .k-tick, .k-slider-track {
    cursor: pointer
}

.k-slider-selection, .k-slider-track {
    position: absolute;
    margin: 0;
    padding: 0
}

.k-slider-horizontal .k-slider-selection, .k-slider-horizontal .k-slider-track {
    top: 50%;
    left: 0;
    height: 8px;
    margin-top: -4px;
    background-repeat: repeat-x
}

.k-slider-horizontal .k-slider-buttons .k-slider-track {
    left: 34px
}

.k-slider-vertical .k-slider-selection, .k-slider-vertical .k-slider-track {
    left: 50%;
    bottom: 0;
    width: 8px;
    margin-left: -4px;
    background-repeat: repeat-y
}

.k-slider-vertical .k-slider-buttons .k-slider-track {
    bottom: 34px
}

.k-draghandle {
    position: absolute;
    background-repeat: no-repeat;
    background-color: transparent;
    text-indent: -3333px;
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    outline: 0
}

.k-slider-horizontal .k-draghandle {
    top: -4px;
    width: 13px;
    height: 14px
}

.k-slider-vertical .k-draghandle {
    left: -4px;
    width: 14px;
    height: 13px
}

.k-slider-buttons .k-slider-items {
    margin-left: 34px
}

.k-slider-horizontal .k-slider-items {
    height: 100%
}

.k-slider-vertical .k-slider-items {
    padding-top: 1px
}

.k-slider-vertical .k-slider-buttons .k-slider-items {
    padding-top: 0
}

.k-slider-vertical .k-slider-buttons .k-slider-items {
    margin: 0;
    padding-top: 35px
}

.k-slider .k-tick {
    position: relative;
    margin: 0;
    padding: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center
}

.k-slider-horizontal .k-tick {
    float: left;
    height: 100%;
    text-align: center
}

.k-slider-horizontal .k-tick {
    background-position: center -92px
}

.k-slider-horizontal .k-slider-topleft .k-tick {
    background-position: center -122px
}

.k-slider-horizontal .k-slider-bottomright .k-tick {
    background-position: center -152px
}

.k-slider-horizontal .k-tick-large {
    background-position: center -2px
}

.k-slider-horizontal .k-slider-topleft .k-tick-large {
    background-position: center -32px
}

.k-slider-horizontal .k-slider-bottomright .k-tick-large {
    background-position: center -62px
}

.k-slider-vertical .k-tick {
    background-position: -92px center
}

.k-slider-vertical .k-slider-topleft .k-tick {
    background-position: -122px center
}

.k-slider-vertical .k-slider-bottomright .k-tick {
    background-position: -152px center
}

.k-slider-vertical .k-tick-large {
    background-position: -2px center
}

.k-slider-vertical .k-slider-topleft .k-tick-large {
    background-position: -32px center
}

.k-slider-vertical .k-slider-bottomright .k-tick-large {
    background-position: -62px center
}

.k-slider-horizontal .k-first {
    background-position: 0 -92px
}

.k-slider-horizontal .k-tick-large.k-first {
    background-position: 0 -2px
}

.k-slider-horizontal .k-slider-topleft .k-first {
    background-position: 0 -122px
}

.k-slider-horizontal .k-slider-topleft .k-tick-large.k-first {
    background-position: 0 -32px
}

.k-slider-horizontal .k-slider-bottomright .k-first {
    background-position: 0 -152px
}

.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-first {
    background-position: 0 -62px
}

.k-slider-horizontal .k-last {
    background-position: 100% -92px
}

.k-slider-horizontal .k-tick-large.k-last {
    background-position: 100% -2px
}

.k-slider-horizontal .k-slider-topleft .k-last {
    background-position: 100% -122px
}

.k-slider-horizontal .k-slider-topleft .k-tick-large.k-last {
    background-position: 100% -32px
}

.k-slider-horizontal .k-slider-bottomright .k-last {
    background-position: 100% -152px
}

.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-last {
    background-position: 100% -62px
}

.k-slider-vertical .k-first {
    background-position: -92px 100%
}

.k-slider-vertical .k-tick-large.k-first {
    background-position: -2px 100%
}

.k-slider-vertical .k-slider-topleft .k-first {
    background-position: -122px 100%
}

.k-slider-vertical .k-slider-topleft .k-tick-large.k-first {
    background-position: -32px 100%
}

.k-slider-vertical .k-slider-bottomright .k-first {
    background-position: -152px 100%
}

.k-slider-vertical .k-slider-bottomright .k-tick-large.k-first {
    background-position: -62px 100%
}

.k-slider-vertical .k-last {
    background-position: -92px 0
}

.k-slider-vertical .k-tick-large.k-last {
    background-position: -2px 0
}

.k-slider-vertical .k-slider-topleft .k-last {
    background-position: -122px 0
}

.k-slider-vertical .k-slider-topleft .k-tick-large.k-last {
    background-position: -32px 0
}

.k-slider-vertical .k-slider-bottomright .k-last {
    background-position: -152px 0
}

.k-slider-vertical .k-slider-bottomright .k-tick-large.k-last {
    background-position: -62px 0
}

.k-slider-vertical .k-tick {
    text-align: right
}

.k-slider-vertical .k-slider-topleft .k-tick {
    text-align: left
}

.k-slider .k-label {
    position: absolute;
    white-space: nowrap;
    font-size: .92em
}

.k-slider-horizontal .k-label {
    left: 0;
    width: 100%;
    line-height: 1
}

.k-slider-horizontal .k-first .k-label {
    left: -50%
}

.k-slider-horizontal .k-last .k-label {
    left: auto;
    right: -50%
}

.k-slider-horizontal .k-label {
    bottom: -1.2em
}

.k-slider-horizontal .k-slider-topleft .k-label {
    top: -1.2em
}

.k-slider-vertical .k-label {
    left: 120%;
    display: block;
    text-align: left
}

.k-slider-vertical .k-last .k-label {
    top: -.5em
}

.k-slider-vertical .k-first .k-label {
    bottom: -.5em
}

.k-slider-vertical .k-slider-topleft .k-label {
    left: auto;
    right: 120%
}

.k-slider-tooltip {
    top: -4444px
}

.k-scheduler .k-link {
    color: inherit
}

.k-scheduler-footer, .k-scheduler-toolbar {
    border-style: solid
}

.k-scheduler-footer, .k-scheduler-toolbar {
    line-height: 28px;
    padding: 6px
}

.k-scheduler-toolbar {
    position: relative;
    border-width: 0 0 1px
}

.k-edit-field.k-scheduler-toolbar {
    border-width: 0;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0
}

.k-scheduler-header {
    text-align: center
}

    .k-scheduler-header .k-link {
        color: inherit
    }

.k-scheduler-footer {
    border-width: 1px 0 0
}

.k-scheduler-toolbar > ul {
    float: right
}

    .k-scheduler-toolbar > ul:first-child {
        float: left
    }

.k-scheduler-toolbar > .k-scheduler-tools {
    float: left;
    margin-bottom: .5em
}

.k-scheduler-tools + .k-scheduler-navigation {
    float: left;
    clear: left
}

.k-scheduler-footer > ul > li, .k-scheduler-toolbar > ul > li {
    display: inline-block;
    border-style: solid;
    border-width: 1px 1px 1px 0
}

.k-scheduler .k-scheduler-toolbar .k-nav-current, .k-scheduler .k-scheduler-toolbar .k-scheduler-tools > li {
    border-width: 0
}

.k-scheduler-toolbar > ul.k-scheduler-views > li:first-child + li, .k-scheduler-toolbar > ul > li:first-child {
    border-left-width: 1px
}

.k-scheduler div.k-scheduler-footer ul li {
    margin-right: .6em;
    border-width: 1px
}

.k-scheduler-footer .k-link, .k-scheduler-toolbar .k-link {
    display: inline-block;
    padding: 0 1.1em
}

.k-scheduler-toolbar .k-nav-next .k-link, .k-scheduler-toolbar .k-nav-prev .k-link {
    padding-left: .6em;
    padding-right: .6em
}

.k-scheduler-toolbar .k-nav-current .k-link {
    padding: 0
}

.k-scheduler-toolbar .k-nav-current {
    margin: 0 1.1em
}

.k-scheduler .k-nav-current > .k-state-active, .k-scheduler div.k-scheduler-toolbar > ul > li.k-nav-current {
    background: 0 0
}

.k-scheduler-footer .k-icon, .k-scheduler-toolbar .k-i-calendar {
    margin: -2px 6px 0 0
}

.k-scheduler-header, .k-scheduler-header-wrap {
    overflow: hidden
}

.k-scheduler-header-wrap {
    position: relative;
    border-style: solid;
    border-width: 0
}

.k-scheduler .k-scrollbar-v .k-scheduler-header-wrap {
    border-right-width: 1px
}

.k-scheduler-content, .k-scheduler-times {
    position: relative
}

.k-scheduler-times {
    overflow: hidden;
    border-style: solid;
    border-width: 0
}

.k-scheduler-content {
    overflow: auto
}

.k-scheduler-monthview .k-scheduler-content {
    overflow-y: scroll
}

.k-scheduler-layout, .k-scheduler-table {
    border-spacing: 0;
    width: 100%;
    margin: 0;
    border-collapse: separate
}

    .k-scheduler-layout > tbody > tr > td {
        padding: 0;
        vertical-align: top
    }

.k-safari .k-scheduler-layout > tbody > tr > td + td {
    width: 100%
}

.k-scheduler-table {
    table-layout: fixed;
    max-width: none
}

.k-scheduler-times .k-scheduler-table {
    table-layout: auto
}

.k-scheduler-monthview .k-scheduler-content .k-scheduler-table {
    height: 100%
}

.k-scheduler-table td, .k-scheduler-table th {
    height: 1.5em;
    padding: 4px .5em;
    font-size: 100%
}

.k-scheduler .k-scheduler-table td, .k-scheduler .k-scheduler-table th {
    box-sizing: content-box
}

.k-scheduler-dayview div:not(.k-scheduler-header) .k-today:not(.k-nonwork-hour):not(.k-state-selected), .k-scheduler-timelineview .k-today:not(.k-nonwork-hour) {
    background-color: transparent
}

.k-scheduler-monthview .k-hidden, .k-scheduler-monthview .k-hidden > div {
    width: 0 !important;
    overflow: hidden !important
}

.k-scheduler-monthview .k-hidden {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-right-width: 0 !important
}

.k-scheduler-monthview > tbody > tr:first-child .k-scheduler-times {
    margin-right: 1px
}

    .k-scheduler-monthview > tbody > tr:first-child .k-scheduler-times .k-hidden {
        height: auto
    }

.k-scheduler-monthview .k-hidden, .k-scheduler-monthview .k-scheduler-table td {
    height: 85px;
    text-align: right
}

.k-scheduler-monthview .k-scheduler-content .k-scheduler-table-auto, .k-scheduler-monthview .k-scheduler-table-auto, .k-scheduler-monthview .k-scheduler-table-auto td {
    height: auto
}

.k-scheduler-table td, .k-slot-cell {
    vertical-align: top
}

.k-scheduler-layout tr + tr .k-scheduler-times th:last-child {
    vertical-align: top
}

.k-scheduler-header th {
    overflow: hidden;
    text-overflow: ellipsis
}

.k-scheduler-header th, .k-scheduler-table td {
    border-style: solid;
    border-width: 0 0 1px 1px
}

    .k-scheduler-header th:first-child, .k-scheduler-table td:first-child {
        border-left-width: 0
    }

.k-scheduler-agendaview .k-scheduler-table td:first-child {
    border-left-width: 1px
}

.k-scheduler-agendaview .k-scheduler-table td.k-first {
    border-left-width: 0
}

.k-scheduler-layout tr + tr .k-scheduler-table > tbody > tr:last-child > td, .k-scheduler-layout tr + tr .k-scheduler-times tr:last-child > th, .k-scheduler-table > tbody > tr > .k-last {
    border-bottom-width: 0
}

.k-scheduler-agendaview.k-scrollbar-h .k-scheduler-table > tbody > tr > td.k-last, .k-scrollbar-h .k-scheduler-content .k-scheduler-table > tbody > tr:last-child > td, .k-scrollbar-h tr + tr .k-scheduler-times {
    border-bottom-width: 1px
}

.k-scheduler-times th {
    text-align: right;
    padding-right: .6em;
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: transparent;
    white-space: nowrap
}

.k-scheduler-layout tr + tr .k-scheduler-times th {
    border-bottom-color: transparent
}

    .k-scheduler-layout tr + tr .k-scheduler-times th.k-scheduler-times-all-day, .k-scheduler-layout tr + tr .k-scheduler-times th.k-slot-cell {
        border-bottom-color: inherit
    }

.k-scheduler .k-middle-row td {
    border-bottom-style: dotted
}

.k-scheduler-now-arrow, .k-scheduler-now-line {
    position: absolute
}

.k-scheduler-now-arrow {
    width: 0;
    height: 0;
    border: solid 5px transparent;
    left: 0
}

.k-scheduler-now-line {
    left: 5px;
    right: 0;
    height: 1px
}

.k-task {
    position: relative
}

div.k-more-events {
    padding: 0;
    height: 13px
}

.k-more-events > .k-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.k-event, .k-more-events {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    text-align: left;
    overflow: hidden;
    position: absolute
}

    .k-event .k-nav-day {
        display: inline-block
    }

.k-event {
    cursor: default;
    min-height: 1.3em
}

.k-event-drag-hint {
    filter: alpha(opacity=60);
    opacity: .6;
    cursor: -moz-grabbing
}

.k-scheduler-header .k-event {
    white-space: nowrap
}

.k-event-template {
    padding: 4px 1.4em 4px .6em
}

.k-event-time {
    display: none;
    padding-bottom: 0;
    font-size: .9em
}

.k-event-drag-hint .k-event-time {
    display: block
}

.k-event-actions, .k-event > .k-link, .k-task > .k-link {
    position: absolute;
    top: 3px;
    right: 4px;
    white-space: nowrap
}

.k-event-actions {
    z-index: 1
}

.k-scheduler-agendaview .k-task > .k-link {
    top: 0;
    right: 0
}

.k-event-actions:first-child {
    position: static;
    float: left;
    margin: 4px 2px 0 4px
}

.k-webkit .k-event-actions:first-child {
    margin-top: 3px
}

.k-event-actions:first-child > .k-link {
    display: inline-block
}

.k-event-delete {
    display: none
}

.k-event:hover .k-event-delete, tr:hover > td > .k-task .k-event-delete {
    display: inline-block
}

.k-event .k-event-bottom-actions, .k-event .k-event-top-actions {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center
}

.k-event .k-event-bottom-actions {
    top: auto;
    bottom: 0
}

.k-event .k-resize-handle, .k-scheduler-mobile .k-event:hover .k-resize-handle {
    position: absolute;
    visibility: hidden;
    z-index: 2
}

.k-event-active .k-resize-handle, .k-event:hover .k-resize-handle, .k-scheduler-mobile .k-event-active:hover .k-resize-handle {
    visibility: visible
}

.k-event .k-resize-handle:after {
    content: "";
    position: absolute;
    filter: alpha(opacity=50);
    opacity: .5
}

.k-scheduler-mobile .k-event .k-resize-handle:after {
    -webkit-filter: none;
    filter: none;
    opacity: 1
}

.k-event > .k-resize-n {
    top: 0;
    left: 0;
    right: 0;
    height: .4em
}

.k-event > .k-resize-s {
    bottom: 0;
    left: 0;
    right: 0;
    height: .4em
}

.k-event > .k-resize-e {
    right: 0;
    top: 0;
    bottom: 0;
    width: .4em
}

.k-event > .k-resize-w {
    left: 0;
    top: 0;
    bottom: 0;
    width: .4em
}

.k-event > .k-resize-n:after, .k-event > .k-resize-s:after {
    top: 1px;
    left: 50%;
    margin-left: -1em;
    width: 2em;
    height: 1px
}

.k-event > .k-resize-s:after {
    top: auto;
    bottom: 1px
}

.k-event > .k-resize-e:after, .k-event > .k-resize-w:after {
    left: 1px;
    top: 50%;
    margin-top: -.7em;
    height: 1.4em;
    width: 1px
}

.k-event > .k-resize-e:after {
    left: auto;
    right: 1px
}

.k-scheduler-mobile .k-event > .k-resize-n, .k-scheduler-mobile .k-event > .k-resize-s {
    height: .6em
}

.k-scheduler-mobile .k-event > .k-resize-e, .k-scheduler-mobile .k-event > .k-resize-w {
    width: .6em
}

.k-scheduler-mobile .k-event > .k-resize-n:after, .k-scheduler-mobile .k-event > .k-resize-s:after {
    top: 0;
    margin-left: -3em;
    width: 4em;
    height: .6em
}

.k-scheduler-mobile .k-event > .k-resize-s:after {
    bottom: 0
}

.k-scheduler-mobile .k-event > .k-resize-e:after, .k-scheduler-mobile .k-event > .k-resize-w:after {
    left: 0;
    margin-top: -.7em;
    height: 1.4em;
    width: .6em
}

.k-scheduler-mobile .k-event > .k-resize-e:after {
    right: 0
}

.k-scheduler-mobile .k-event > .k-resize-n:after {
    border-radius: 0 0 4px 4px
}

.k-scheduler-mobile .k-event > .k-resize-s:after {
    border-radius: 4px 4px 0 0
}

.k-scheduler-mobile .k-event > .k-resize-w:after {
    border-radius: 0 4px 4px 0
}

.k-scheduler-mobile .k-event > .k-resize-e:after {
    border-radius: 4px 0 0 4px
}

.k-scheduler-marquee {
    border-style: solid;
    border-width: 0
}

    .k-scheduler-marquee.k-first:before, .k-scheduler-marquee.k-last:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3px
    }

div.k-scheduler-marquee:before {
    top: 0;
    left: 0;
    border-right-color: transparent;
    border-bottom-color: transparent
}

div.k-scheduler-marquee:after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent
}

.k-scheduler-marquee .k-label-top {
    position: absolute;
    top: .3em;
    left: .8em;
    font-size: .8em
}

.k-scheduler-marquee .k-label-bottom {
    position: absolute;
    bottom: .3em;
    right: .81em;
    font-size: .8em
}

.k-scheduler-quickedit .k-textbox {
    width: 200px
}

.k-tooltip-bottom {
    text-align: left
}

    .k-tooltip-bottom .k-button {
        float: left;
        margin-right: .3em
    }

    .k-tooltip-bottom .k-quickedit-details {
        float: right;
        margin-right: 0
    }

.k-scheduler-agendaview .k-scheduler-table td, .k-scheduler-agendaview .k-scheduler-table th {
    text-align: left
}

.k-scheduler-groupcolumn, .k-scheduler-times .k-slot-cell {
    width: 6em
}

.k-scheduler-datecolumn {
    width: 12em
}

.k-scheduler-timecolumn {
    width: 11em
}

    .k-scheduler-timecolumn > div {
        position: relative;
        vertical-align: top
    }

.k-webkit .k-scheduler-timecolumn > div > .k-icon {
    vertical-align: top
}

.k-scheduler-timecolumn > div > .k-i-arrow-60-right {
    position: absolute;
    right: -4px
}

.k-scheduler-timecolumn .k-i-arrow-60-left {
    margin-left: -4px
}

.k-scheduler-mark {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    margin-right: .5em
}

.k-scheduler-agendaday {
    float: left;
    margin: 0 .2em 0 0;
    font-size: 3em;
    font-weight: 400
}

.k-scheduler-agendaweek {
    display: block;
    margin: .4em 0 0;
    font-size: 1.1em;
    font-style: normal
}

.k-scheduler-agendadate {
    font-size: .8em
}

.k-scheduler-timecolumn {
    white-space: nowrap
}

.k-scheduler-edit-form .k-edit-form-container, .k-scheduler-timezones .k-edit-form-container {
    width: 520px
}

.k-scheduler-edit-form .k-edit-label {
    width: 17%
}

.k-scheduler-edit-form .k-edit-field {
    width: 77%
}

.k-scheduler-edit-form .k-textbox[name=title], .k-scheduler-edit-form textarea.k-textbox {
    width: 100%
}

.k-scheduler-edit-form textarea.k-textbox {
    min-height: 4em;
    resize: vertical
}

.k-scheduler-edit-form > .k-edit-box:first-child .k-datetimepicker {
    margin-right: 1em
}

.k-edit-box {
    float: left
}

    .k-edit-box + .k-edit-box {
        float: right
    }

.k-scheduler-edit-form label + input {
    margin-left: 1em
}

.k-edit-field > ul.k-reset > li {
    margin: .2em 0 .4em;
    line-height: 2.4
}

.k-edit-field > ul.k-reset.k-toolbar > li {
    margin: 0
}

.k-edit-field > ul.k-reset .k-widget {
    margin-left: .8em
}

.k-edit-field span.k-recur-interval, .k-edit-field > ul.k-reset .k-numerictextbox {
    width: 5em
}

.k-edit-field > ul.k-reset .k-datepicker, .k-edit-field > ul.k-reset .k-dropdown, div[name=recurrenceRule] > .k-dropdown {
    width: 9em
}

.k-scheduler-edit-form .k-edit-buttons .k-scheduler-delete, .k-scheduler-edit-form .k-edit-buttons .k-scheduler-resetSeries {
    float: left
}

.k-popup-message {
    margin: 0;
    padding: 1em 0 2em;
    text-align: center
}

.k-scheduler-timezones .k-dropdown:first-child {
    width: 100%
}

.k-scheduler-timezones .k-dropdown + .k-dropdown {
    margin: .5em 0 .7em
}

.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
    display: none
}

.k-scheduler-toolbar .k-nav-current .k-lg-date-format, .k-scheduler-toolbar .k-nav-current .k-sm-date-format {
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.k-scheduler-toolbar .k-nav-current .k-lg-date-format {
    display: inline-block
}

.k-scheduler-toolbar .k-nav-current .k-sm-date-format {
    display: none
}

.k-scheduler-refresh {
    float: right;
    padding: 0 .5em
}

@media only screen and (max-width:1024px) {
    .k-scheduler-toolbar > ul.k-scheduler-views {
        position: absolute;
        right: 6px;
        top: 6px;
        z-index: 10000
    }

    .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views {
        right: auto;
        left: 6px
    }

    .k-scheduler-toolbar > ul.k-scheduler-views > li:not(.k-current-view) {
        display: none
    }

    .k-scheduler-toolbar li.k-nav-current .k-lg-date-format {
        display: none
    }

    .k-scheduler-toolbar li.k-nav-current .k-sm-date-format {
        display: inline-block
    }

    .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
        display: block;
        border-width: 1px;
        text-align: right
    }

        .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link {
            display: block;
            position: relative;
            padding-right: 2.5em;
            padding-left: 1em
        }

    .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link {
        padding-left: 0
    }

    .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after {
        content: "\E006";
        position: absolute;
        top: 50%;
        right: .6em;
        margin-top: -.5em;
        line-height: 1em
    }

    .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li, .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li:first-child + li {
        display: block;
        border: 0;
        border-radius: 0
    }

    .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
        border: 1px solid #c5c5c5;
        background-color: #fff;
        background-image: none;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,.3)
    }

    .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
        text-align: left
    }
}

@media only screen and (max-width:480px) {
    .k-scheduler:not(.k-scheduler-mobile) .k-scheduler-toolbar li.k-nav-current .k-sm-date-format {
        display: none
    }

    .k-scheduler:not(.k-scheduler-mobile) .k-scheduler-toolbar li.k-nav-current .k-i-calendar {
        display: inline-block
    }

    .k-scheduler-timecolumn {
        width: 5em
    }

    .k-scheduler-datecolumn {
        width: 6em;
        overflow: hidden
    }

        .k-scheduler-datecolumn > div, .k-scheduler-timecolumn > div {
            white-space: normal
        }
}

.k-scheduler-pdf-export {
    overflow: hidden
}

    .k-pdf-export-shadow .k-scheduler, .k-scheduler-pdf-export .k-scheduler-content, .k-scheduler-pdf-export .k-scheduler-times {
        height: auto !important;
        overflow: visible !important
    }

    .k-scheduler-pdf-export .k-scheduler-header {
        padding: 0 !important
    }

    .k-scheduler-pdf-export .k-scheduler-header-wrap {
        border-width: 0 !important
    }

    .k-scheduler-pdf-export .k-scheduler-content .k-scheduler-table, .k-scheduler-pdf-export .k-scheduler-header .k-scheduler-table {
        width: 100% !important
    }

.k-tooltip {
    position: absolute;
    z-index: 12000;
    border-style: solid;
    border-width: 0;
    padding: 5px 5px 5px 6px;
    background-repeat: repeat-x;
    min-width: 20px;
    text-align: center
}

.k-tooltip-button {
    text-align: right;
    height: 0
}

.k-tooltip-content {
    height: 100%
}

.k-tooltip-closable .k-tooltip-content {
    padding-right: 20px
}

span.k-tooltip {
    position: static;
    display: inline-block;
    border-width: 1px;
    padding: 2px 5px 1px 6px
}

.k-invalid-msg {
    display: none
}

.k-callout {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px;
    border-color: transparent
}

.k-callout-n {
    top: -12px;
    left: 50%;
    margin-left: -6px
}

.k-callout-w {
    top: 50%;
    left: -12px;
    margin-top: -6px
}

.k-callout-s {
    left: 50%;
    bottom: -12px;
    margin-left: -6px
}

.k-callout-e {
    top: 50%;
    right: -12px;
    margin-top: -6px
}

.k-slider-tooltip .k-callout-n, .k-slider-tooltip .k-callout-s {
    margin-left: -6px
}

.k-slider-tooltip .k-callout-e, .k-slider-tooltip .k-callout-w {
    margin-top: -6px
}

.k-tooltip-validation .k-i-warning {
    vertical-align: text-top;
    margin-right: 3px
}

.k-tooltip-validation {
    z-index: 9999
}

.k-toolbar {
    position: relative;
    display: block;
    vertical-align: middle;
    padding: .28571428em 0;
    overflow: hidden;
    min-height: 2.4375em;
    box-sizing: border-box
}

.k-toolbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: start;
    justify-content: flex-start
}

    .k-overflow-container .k-button .k-sprite, .k-toolbar .k-button .k-sprite {
        vertical-align: middle;
        margin-top: -7px;
        margin-bottom: -5px
    }

    .k-toolbar .k-input:before {
        content: "\a0";
        display: inline-block;
        width: 0
    }

    .k-toolbar .k-combobox .k-dropdown-wrap:before, .k-toolbar .k-numeric-wrap:before, .k-toolbar .k-picker-wrap:before {
        display: none
    }

.k-overflow-container .k-sprite {
    margin-left: -4px
}

.k-toolbar-resizable {
    white-space: nowrap
}

.k-toolbar-resizable {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.k-toolbar > .k-align-left {
    float: none
}

.k-toolbar > .k-align-right {
    float: right
}

.k-toolbar > * {
    display: inline-block;
    vertical-align: middle
}

.k-toolbar > * {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: center;
    align-content: center;
    vertical-align: middle;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.k-toolbar .k-spacer {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.k-toolbar .k-separator {
    border-width: 0 0 0 1px;
    border-style: solid;
    width: 1px;
    line-height: 2.1428571429em
}

.k-toolbar .k-button-group {
    list-style-type: none
}

    .k-toolbar .k-button-group > li {
        display: inline-block
    }

    .k-toolbar .k-button-group .k-button {
        margin-left: -1px;
        margin-right: 0;
        border-radius: 0
    }

.k-toolbar .k-button, .k-toolbar .k-button-group, .k-toolbar .k-separator, .k-toolbar .k-split-button, .k-toolbar .k-widget, .k-toolbar label, .k-toolbar span:not(.k-maskedtextbox) .k-textbox {
    margin: 0 .2em;
    vertical-align: middle
}

.k-toolbar label {
    -ms-flex-item-align: center;
    align-self: center
}

.k-toolbar .k-split-button {
    padding-left: 0;
    border: 1px solid transparent
}

    .k-toolbar .k-split-button .k-button {
        margin: -1px 0 -1px -1px
    }

    .k-toolbar .k-split-button .k-split-button-arrow {
        margin: -1px
    }

.k-toolbar .k-button-group .k-group-start {
    margin-left: 0;
    margin-right: 0
}

.k-toolbar .k-button-icontext {
    padding-right: .8em
}

.k-toolbar .k-overflow-anchor {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    width: 2.8571428571em;
    margin: 0;
    line-height: inherit;
    border-radius: 0
}

    .k-toolbar .k-overflow-anchor > .k-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -8px
    }

.k-overflow-container .k-button-group .k-button {
    margin: 0
}

.k-overflow-container .k-item {
    float: none;
    border: 0
}

.k-overflow-container .k-separator {
    border-width: 0 0 1px;
    border-style: solid;
    height: 1px;
    line-height: 0;
    font-size: 0;
    padding: 0
}

.k-overflow-container .k-overflow-button, .k-split-container .k-button {
    text-align: left;
    display: block;
    background: 0 0;
    border-color: transparent;
    white-space: nowrap
}

.k-split-container {
    margin-top: -1px
}

.k-overflow-container .k-button-group > li {
    display: block
}

.k-overflow-container .k-overflow-group {
    display: block;
    border-width: 1px 0;
    border-style: solid;
    border-radius: 0;
    margin: 1px 0
}

.k-overflow-container .k-overflow-hidden {
    display: none
}

.k-overflow-container .k-overflow-group + .k-overflow-group, .k-overflow-container .k-separator + .k-overflow-group, .k-overflow-container .k-toolbar-first-visible {
    border-top: 0;
    margin-top: 0;
    padding-top: 1px
}

.k-overflow-container .k-overflow-group + .k-separator {
    display: none
}

.k-overflow-container .k-toolbar-last-visible {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 1px
}

.k-splitter {
    position: relative;
    height: 300px
}

.k-pane > .k-splitter {
    border-width: 0;
    overflow: hidden
}

.k-splitter .k-pane {
    overflow: hidden
}

.k-splitter .k-scrollable {
    overflow: auto
}

.k-splitter .k-pane-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px
}

.k-ghost-splitbar, .k-splitbar {
    position: absolute;
    border-style: solid;
    font-size: 0;
    outline: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.k-splitter .k-ghost-splitbar-horizontal, .k-splitter .k-splitbar-horizontal {
    top: 0;
    width: 5px;
    border-width: 0 1px;
    background-repeat: repeat-y
}

.k-ghost-splitbar-vertical, .k-splitbar-vertical {
    left: 0;
    height: 5px;
    border-width: 1px 0;
    background-repeat: repeat-x
}

.k-splitbar-draggable-horizontal {
    cursor: w-resize
}

.k-splitbar-draggable-vertical {
    cursor: n-resize
}

.k-splitbar .k-resize-handle {
    display: none
}

.k-restricted-size-horizontal, .k-restricted-size-vertical {
    background-color: red
}

.k-splitbar-horizontal .k-icon {
    position: absolute;
    top: 50%;
    margin-top: -10px
}

.k-splitbar-horizontal .k-i-arrow-60-right {
    margin-left: -2px
}

.k-splitbar-horizontal.k-splitbar-draggable-horizontal .k-i-arrow-60-right {
    margin-top: 13px
}

.k-splitbar-horizontal .k-i-arrow-60-left {
    margin-left: -3px
}

.k-splitbar-horizontal.k-splitbar-draggable-horizontal .k-i-arrow-60-left {
    margin-top: -28px
}

.k-splitbar-vertical .k-i-arrow-60-up {
    margin-top: -2px
}

.k-splitbar-vertical .k-i-arrow-60-down {
    margin-top: -2px
}

.k-splitbar-vertical.k-splitbar-draggable-vertical .k-i-arrow-60-up {
    margin-left: -17px
}

.k-splitbar-vertical.k-splitbar-draggable-vertical .k-i-arrow-60-down {
    margin-left: 23px
}

.k-i-arrow-60-down, .k-i-arrow-60-left, .k-i-arrow-60-right, .k-i-arrow-60-up {
    cursor: pointer
}

.k-splitbar-horizontal .k-icon {
    position: absolute;
    top: 50%;
    width: 7px;
    height: 20px;
    margin-top: -10px
}

.k-splitbar-static-horizontal {
    width: 1px
}

.k-splitbar-static-vertical {
    height: 1px
}

.k-splitbar-vertical .k-icon {
    position: absolute;
    left: 50%
}

.k-splitbar-draggable-horizontal .k-resize-handle, .k-splitbar-draggable-vertical .k-resize-handle {
    display: inline-block;
    border-radius: 1px
}

.k-splitbar-draggable-horizontal .k-resize-handle {
    width: 3px;
    height: 16px;
    margin-left: 1px
}

.k-splitbar-draggable-vertical .k-resize-handle {
    width: 16px;
    height: 3px;
    margin-top: 1px
}

.k-splitbar .k-i-arrow-60-down, .k-splitbar .k-i-arrow-60-left, .k-splitbar .k-i-arrow-60-right, .k-splitbar .k-i-arrow-60-up {
    font-size: 10px
}

.k-splitter-resizing {
    overflow: hidden
}

.k-rtl .k-splitbar-horizontal .k-resize-handle {
    margin-right: 1px
}

.k-rtl .k-splitbar-horizontal .k-i-arrow-60-right {
    margin-top: 13px;
    margin-right: -3px
}

.k-rtl .k-splitbar-horizontal .k-i-arrow-60-left {
    margin-top: -28px;
    margin-right: -2px
}

.k-rtl .k-splitbar-vertical .k-i-arrow-60-up {
    margin-top: -2px;
    margin-left: -17px
}

.k-rtl .k-splitbar-vertical .k-i-arrow-60-down {
    margin-top: -2px;
    margin-left: 23px
}

html .k-upload {
    position: relative
}

.k-dropzone .k-dropzone-hint, .k-upload-button {
    vertical-align: middle
}

.k-dropzone, .k-file {
    position: relative
}

.k-dropzone {
    border-style: solid;
    border-width: 0;
    padding: .8em;
    background-color: transparent
}

    .k-dropzone .k-dropzone-hint {
        display: none;
        margin-left: .6em
    }

.k-dropzone-active .k-dropzone-hint {
    display: inline-block
}

.k-dropzone-active .k-upload-status {
    display: none
}

.k-upload-button {
    position: relative;
    overflow: hidden;
    direction: ltr
}

.k-upload .k-upload-button {
    min-width: 7.167em;
    margin-right: 1.6em
}

.k-upload-button input {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    font: 170px monospace !important;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer
}

.k-upload-files {
    line-height: 2.66;
    border-style: solid;
    border-width: 1px 0 0
}

    .k-upload-files .k-button {
        padding: 0
    }

.k-upload-status .k-i-warning {
    display: none
}

.k-upload-status-total .k-icon {
    display: inline-block;
    margin-right: 4px;
    vertical-align: text-bottom
}

.k-dropzone .k-upload-status-total {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.k-ie .k-dropzone .k-upload-status-total {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.k-upload .k-clear-selected, .k-upload .k-upload-selected {
    display: inline-block;
    width: 50%;
    margin: 0;
    line-height: 1em;
    padding: 1.214em 0;
    border: 0
}

.k-upload .k-upload-selected {
    border-left-width: 1px;
    border-left-style: solid;
    margin-left: -1px
}

.k-upload .k-clear-selected {
    border-radius: 0 0 0 4px
}

.k-upload .k-upload-selected {
    border-radius: 0 0 4px 0
}

.k-upload-files ~ .k-clear-selected, .k-upload-files ~ .k-upload-selected {
    margin-top: -5px
}

.k-upload .k-fail {
    background-position: -161px -111px
}

.k-file {
    border-style: solid;
    border-width: 0 0 1px;
    padding: .65em .17em .65em 1em;
    white-space: nowrap
}

    .k-file .k-upload-action {
        background: 0 0;
        border-width: 0;
        box-shadow: none;
        margin-right: 3px
    }

    .k-file:last-child {
        border-bottom-width: 0
    }

    .k-file .k-icon {
        display: inline-block;
        position: relative
    }

.k-file-extension-wrapper, .k-file-group-wrapper, .k-file-invalid-extension-wrapper, .k-file-name-size-wrapper, .k-multiple-files-extension-wrapper, .k-multiple-files-group-wrapper, .k-multiple-files-invalid-extension-wrapper {
    display: inline-block
}

.k-file-extension-wrapper, .k-file-invalid-extension-wrapper, .k-multiple-files-extension-wrapper, .k-multiple-files-invalid-extension-wrapper {
    position: relative;
    width: 24px;
    height: 34px;
    border-width: 2px;
    border-style: solid;
    vertical-align: top;
    font-size: .57em;
    text-transform: uppercase
}

.k-file-invalid-extension-wrapper, .k-multiple-files-invalid-extension-wrapper {
    font-size: 1.2em
}

.k-multiple-files-extension-wrapper, .k-multiple-files-invalid-extension-wrapper {
    margin-top: 4px
}

.k-file-group-wrapper .k-file-group, .k-file-invalid-group-wrapper .k-file-group, .k-multiple-files-group-wrapper .k-file-group, .k-multiple-files-invalid-group-wrapper .k-file-group {
    font-size: 32px
}

.k-file-group-wrapper .k-file-state, .k-file-invalid-group-wrapper .k-file-state, .k-multiple-files-group-wrapper .k-file-state, .k-multiple-files-invalid-group-wrapper .k-file-state {
    position: absolute
}

.k-multiple-files-group-wrapper, .k-multiple-files-invalid-group-wrapper {
    vertical-align: top
}

.k-file-state {
    visibility: hidden
}

.k-file-name-size-wrapper {
    vertical-align: middle;
    margin-left: 1em;
    max-width: calc(100% - 24px - 7em)
}

.k-file-extension-wrapper:before, .k-file-invalid-extension-wrapper:before, .k-multiple-files-extension-wrapper:after, .k-multiple-files-extension-wrapper:before, .k-multiple-files-invalid-extension-wrapper:after, .k-multiple-files-invalid-extension-wrapper:before {
    position: absolute;
    content: "";
    display: inline-block;
    border-style: solid
}

.k-file-extension-wrapper:before, .k-file-invalid-extension-wrapper:before, .k-multiple-files-extension-wrapper:before, .k-multiple-files-invalid-extension-wrapper:before {
    top: -1px;
    right: -1px;
    width: 0;
    height: 0;
    border-width: 6px;
    margin-top: -1px;
    margin-right: -1px
}

.k-multiple-files-extension-wrapper:after, .k-multiple-files-invalid-extension-wrapper:after {
    top: -6px;
    left: -6px;
    width: 15px;
    height: 35px;
    border-width: 2px 0 0 2px
}

.k-file-extension, .k-file-invalid-icon {
    position: absolute;
    bottom: 0;
    line-height: normal
}

.k-file-extension {
    margin-left: .4em;
    margin-bottom: .3em;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.k-file-invalid-icon {
    margin-left: 5px
}

.k-file-information, .k-file-name, .k-file-size, .k-file-validation-message {
    display: block
}

.k-file-name {
    position: relative;
    max-width: 100%;
    vertical-align: middle;
    line-height: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis
}

.k-file-information, .k-file-size, .k-file-validation-message {
    font-size: .78em;
    line-height: 1.5em
}

.k-file-information, .k-file-validation-message {
    line-height: 1.5em
}

.k-file-size {
    line-height: 1.2em
}

.k-upload-files .k-upload-status {
    line-height: 1;
    position: absolute;
    right: 10px;
    top: 1em;
    opacity: .65
}

.k-button.k-upload-action.k-state-active:hover, .k-button.k-upload-action:active:hover, .k-button.k-upload-action:hover, .k-upload-action {
    color: inherit;
    background: 0 0;
    border: 0
}

.k-upload-action {
    opacity: .6
}

    .k-upload-action:hover {
        opacity: 1
    }

    .k-upload-action .k-icon {
        vertical-align: top
    }

.k-file .k-upload-status .k-button, .k-file .k-upload-status > .k-icon {
    vertical-align: middle;
    border-radius: 50%
}

.k-dropzone .k-upload-status {
    position: static;
    margin-left: 10px
}

.k-upload-pct {
    margin-right: .75em
}

.k-ie9 .k-upload-status-total {
    top: 1.5em
}

    .k-ie9 .k-upload-status-total > .k-icon {
        margin-top: -3px
    }

.k-upload-action {
    line-height: normal
}

.k-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px
}

.k-file-invalid > .k-progress {
    width: 100%
}

.k-upload-selected {
    min-width: 7.167em;
    margin: .25em 0 0;
    box-sizing: content-box
}

.k-upload-cancel, .k-upload-selected {
    margin-bottom: .8em
}

.k-upload-selected {
    margin-left: .8em;
    margin-right: .2em
}

.k-rtl .k-file-extension-wrapper {
    margin-left: 14px
}

.k-upload .k-action-buttons {
    padding: 0;
    margin: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    clear: both
}

    .k-upload .k-action-buttons .k-button {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        margin: 0
    }

        .k-upload .k-action-buttons .k-button + .k-button {
            margin: 0
        }

.k-no-flexbox .k-upload .k-action-buttons .k-button {
    border-width: 0
}

.k-mediaplayer {
    position: relative
}

    .k-mediaplayer > iframe, .k-mediaplayer > video {
        vertical-align: top
    }

    .k-mediaplayer .k-mediaplayer-overlay {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute
    }

.k-mediaplayer-titlebar {
    padding: 8px;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
    background: linear-gradient(rgba(0,0,0,.7),rgba(0,0,0,.05) 80%,rgba(0,0,0,0))
}

.k-mediaplayer-toolbar-wrap {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0
}

.k-mediaplayer-toolbar {
    padding: 2px;
    border-width: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: start;
    justify-content: flex-start;
    position: relative
}

    .k-mediaplayer-toolbar > * {
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center
    }

        .k-mediaplayer-toolbar > * + * {
            margin-left: 5px
        }

    .k-mediaplayer-toolbar .k-toolbar-spacer {
        margin-top: 0;
        margin-bottom: 0;
        border-width: 0;
        -ms-flex: 1;
        flex: 1;
        opacity: 0
    }

    .k-mediaplayer-toolbar .k-align-right > * + * {
        margin-left: 5px
    }

    .k-mediaplayer-toolbar .k-mediaplayer-quality {
        width: auto;
        padding: 0;
        background: none transparent
    }

        .k-mediaplayer-toolbar .k-mediaplayer-quality .k-dropdown-wrap {
            border: 0 none;
            box-shadow: none;
            padding: 0;
            background: none transparent;
            color: inherit
        }

        .k-mediaplayer-toolbar .k-mediaplayer-quality .k-input {
            display: none
        }

        .k-mediaplayer-toolbar .k-mediaplayer-quality .k-select {
            position: relative;
            width: auto;
            min-height: 0;
            line-height: inherit
        }

.k-mediaplayer-quality-wrap, .k-mediaplayer-volume-wrap {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center
}

.k-quality-list {
    width: 80px !important;
    margin-left: -32px !important
}

.k-slider.k-mediaplayer-seekbar {
    width: 100%;
    position: absolute;
    z-index: 3;
    top: -17px;
    left: 0
}

    .k-slider.k-mediaplayer-seekbar .k-slider-track {
        width: 100% !important;
        border-radius: 0
    }

    .k-slider.k-mediaplayer-seekbar .k-slider-selection {
        border-radius: 0
    }

    .k-slider.k-mediaplayer-seekbar .k-draghandle {
        opacity: 0;
        transition: .2s opacity
    }

        .k-slider.k-mediaplayer-seekbar .k-draghandle.k-state-selected, .k-slider.k-mediaplayer-seekbar:hover .k-draghandle {
            opacity: 1
        }

.k-mediaplayer-fullscreen {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important
}

.k-no-flexbox .k-mediaplayer-toolbar .k-align-right > * {
    display: inline-block;
    vertical-align: middle
}

.k-no-flexbox .k-mediaplayer-toolbar .k-bare.k-button {
    height: auto
}

.k-no-flexbox .k-mediaplayer-toolbar .k-toolbar-spacer {
    display: none
}

.k-toolbar-wrap {
    float: left
}

    .k-toolbar-wrap .k-dropzone em, .k-toolbar-wrap .k-upload-files {
        display: none
    }

    .k-toolbar-wrap .k-dropzone {
        border: 0;
        padding: 0
    }

    .k-toolbar-wrap .k-dropzone-active {
        text-align: center
    }

        .k-toolbar-wrap .k-dropzone-active em {
            display: inline;
            margin: 0;
            font-size: 5em;
            font-style: normal
        }

        .k-toolbar-wrap .k-dropzone-active .k-upload-button {
            display: none
        }

.k-filebrowser-dropzone {
    z-index: 10010;
    filter: alpha(opacity=40);
    opacity: .4;
    position: fixed
}

.k-search-wrap {
    position: relative;
    float: right;
    width: 20%;
    padding: 0
}

    .k-search-wrap label {
        position: absolute;
        top: 0;
        left: 4px;
        line-height: 20px;
        font-style: italic
    }

    .k-search-wrap input.k-input {
        padding-left: 0;
        padding-right: 0
    }

    .k-search-wrap .k-search {
        position: absolute;
        top: 50%;
        right: 2px;
        margin: 0;
        margin-top: -8px
    }

.k-filebrowser .k-tile .k-file, .k-filebrowser .k-tile .k-folder, .k-filebrowser .k-tile .k-i-file, .k-filebrowser .k-tile .k-i-folder {
    font-size: 5em
}

.k-filebrowser .k-breadcrumbs .k-i-arrow-end-left {
    text-decoration: none;
    cursor: default
}

.k-filebrowser .k-filebrowser-toolbar {
    border-style: solid;
    border-width: 1px;
    margin: 8px 0 0;
    padding: .25em;
    line-height: 23px;
    white-space: nowrap
}

    .k-filebrowser .k-filebrowser-toolbar .k-button.k-state-disabled {
        display: none
    }

.k-filebrowser .k-tiles-arrange {
    float: right
}

    .k-filebrowser .k-tiles-arrange .k-dropdown {
        width: 5.3em
    }

.k-filebrowser .k-upload {
    float: left;
    z-index: 10010;
    border-width: 0;
    background-color: transparent
}

    .k-filebrowser .k-upload .k-upload-status {
        display: none
    }

    .k-filebrowser .k-upload .k-upload-button {
        width: auto;
        margin-left: 0
    }

.k-filebrowser .k-breadcrumb {
    position: relative;
    float: left;
    width: 79%;
    margin: 0
}

.k-tiles {
    clear: both;
    height: 390px;
    border-style: solid;
    border-width: 1px;
    border-top-width: 0;
    margin: 0 0 1.4em;
    padding: 9px;
    overflow: auto;
    line-height: 1.2
}

.k-tile {
    float: left;
    width: 223px;
    height: 88px;
    overflow: hidden;
    border-style: solid;
    border-width: 1px;
    margin: 1px;
    padding: 0 0 4px;
    background-position: 0 100px;
    background-repeat: repeat-x;
    cursor: pointer
}

.k-tiles li.k-state-hover, .k-tiles li.k-state-selected {
    background-position: 0 center
}

.k-filebrowser .k-thumb {
    float: left;
    display: inline;
    width: 80px;
    height: 80px;
    margin: 4px 10px 0 4px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.k-filebrowser .k-file, .k-filebrowser .k-i-file {
    width: 80px;
    height: 80px
}

.k-filebrowser .k-image {
    margin: 2px 0 0 2px
}

.k-filebrowser .k-folder, .k-filebrowser .k-i-folder {
    width: 80px;
    height: 80px;
    background-position: 0 -200px;
    background-repeat: no-repeat
}

.k-filebrowser .k-loading {
    margin: 35px 0 0 33px
}

.k-tile input, .k-tile strong {
    margin: 10px 0 4px;
    font-weight: 400
}

.k-tile strong {
    float: left;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis
}

.k-tile input {
    width: 100px
}

.k-tile input, .k-tile strong {
    display: block
}

.k-tile .k-filesize {
    display: inline-block
}

.k-filebrowser .k-form-text-row {
    text-align: right
}

    .k-filebrowser .k-form-text-row label {
        width: 14%
    }

    .k-filebrowser .k-form-text-row input {
        width: 80%
    }

.k-tile-empty {
    margin: 160px 0 0
}

    .k-tile-empty .k-dialog-upload {
        font-weight: 700;
        font-size: 120%
    }

    .k-tile-empty strong {
        display: block;
        margin: 0 0 .2em;
        font-size: 3em;
        font-weight: 400
    }

    .k-tile-empty, .k-tile-empty .k-button-wrapper {
        text-align: center
    }

.k-chart, .k-gauge, .k-sparkline, .k-stockchart {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent
}

.k-chart, .k-stockchart {
    height: 400px
}

    .k-chart > canvas {
        vertical-align: top
    }

.k-stockchart, div.k-chart, div.k-gauge, span.k-sparkline {
    background-color: transparent
}

.k-gauge {
    text-align: left;
    position: relative
}

.k-arcgauge {
    display: inline-block
}

.k-arcgauge-label {
    position: absolute;
    text-align: center;
    padding: 0;
    margin: 0
}

.k-baseline-marker {
    zoom: 1
}

.k-chart-tooltip {
    border-radius: 4px;
    padding: 6px;
    white-space: nowrap;
    z-index: 12000;
    line-height: normal;
    background-repeat: repeat-x;
    background-position: 0 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNJREFUeNpi/P//vwMDFQELEP8beQb+HTWQYgP/DHoD/466cAR4edRAyg38P6hLbIAAAwCnWhhVsxvdCAAAAABJRU5ErkJggg==);
    color: #fff
}

.k-chart-tooltip-inverse {
    color: #000
}

.k-chart-tooltip table {
    border-spacing: 0;
    border-collapse: collapse
}

.k-chart-tooltip th {
    width: auto;
    text-align: center;
    padding: 1px
}

.k-chart-tooltip td {
    width: auto;
    text-align: left;
    padding: .1em .2em
}

.k-chart-shared-tooltip-marker {
    display: block;
    width: 15px;
    height: 3px
}

.k-selector {
    position: absolute;
    -webkit-transform: translateZ(0)
}

.k-selection {
    position: absolute;
    border-width: 1px;
    border-style: solid;
    border-color: #d2d2d2;
    border-bottom: 0;
    height: 100%
}

.k-selection-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    background-color: rgba(255,255,255,.01);
    filter: alpha(opacity=1)
}

.k-handle {
    background: #d2d2d2;
    width: 7px;
    height: 26px;
    cursor: e-resize;
    z-index: 1;
    border-radius: 6px;
    position: absolute
}

    .k-handle div {
        width: 100%;
        height: 100%;
        background-color: transparent
    }

.k-left-handle {
    left: -4px
}

.k-right-handle {
    right: -4px
}

.k-left-handle div {
    margin: -20px 0 0 -15px;
    padding: 40px 30px 0 0
}

.k-left-handle.k-handle-active div {
    margin-left: -40px;
    padding-right: 55px
}

.k-right-handle div {
    margin: -20px 0 0 -15px;
    padding: 40px 0 0 30px
}

.k-right-handle.k-handle-active div {
    padding-left: 55px
}

.k-mask {
    position: absolute;
    height: 100%;
    background-color: #fff;
    filter: alpha(opacity=80);
    opacity: .8
}

.k-border {
    background: #d2d2d2;
    width: 1px;
    height: 100%;
    position: absolute
}

.k-navigator-hint div {
    position: absolute
}

.k-navigator-hint .k-scroll {
    position: absolute;
    height: 4px;
    border-radius: 4px;
    background: #d2d2d2
}

.k-navigator-hint .k-tooltip {
    margin-top: 20px;
    min-width: 160px;
    opacity: 1;
    text-align: center;
    border: 0;
    box-shadow: 0 1px 2px rgba(0,0,0,.5);
    background: #fff
}

.k-sparkline, .k-sparkline span {
    display: inline-block;
    vertical-align: top
}

    .k-sparkline span {
        height: 100%;
        width: 100%
    }

.k-diagram, .k-map {
    height: 600px
}

    .k-diagram .km-scroll-wrapper, .k-map .km-scroll-wrapper {
        padding-bottom: 0;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none
    }

    .k-diagram .km-scroll-container, .k-diagram .km-scroll-wrapper, .k-map .km-scroll-container, .k-map .km-scroll-wrapper {
        position: absolute;
        width: 100%;
        height: 100%
    }

    .k-diagram .k-layer, .k-map .k-layer {
        position: absolute;
        left: 0;
        top: 0
    }

    .k-diagram .km-touch-scrollbar, .k-map .km-touch-scrollbar {
        display: none
    }

.k-map {
    border-width: 0;
    border-style: solid;
    box-sizing: border-box
}

    .k-map .k-map-controls {
        position: absolute
    }

    .k-map .k-pos-top {
        top: 0
    }

    .k-map .k-pos-bottom {
        bottom: 0
    }

    .k-map .k-pos-left {
        left: 0
    }

    .k-map .k-pos-right {
        right: 0
    }

    .k-map .k-navigator {
        margin: 1em;
        width: 50px;
        height: 50px;
        border-width: 1px;
        border-style: solid;
        border-radius: 80px;
        position: relative;
        display: inline-block;
        vertical-align: middle
    }

.k-pdf-export .k-map .k-navigator {
    display: none
}

.k-map .k-zoom-control {
    margin: 1em;
    vertical-align: middle
}

    .k-map .k-zoom-control .k-button {
        width: auto;
        height: auto
    }

.k-pdf-export .k-map .k-zoom-control {
    display: none
}

.k-map .k-attribution {
    padding: 2px 4px;
    font-size: 10px;
    background-color: rgba(255,255,255,.8);
    z-index: 1000
}

.k-map .k-marker {
    width: 28px;
    height: 40px;
    margin: -40px 0 0 -14px;
    font-size: 28px;
    position: absolute;
    cursor: pointer;
    overflow: visible
}

.k-navigator > .k-button {
    margin: 0;
    padding: 0;
    border-color: transparent;
    border-radius: 16px;
    line-height: 10px;
    background: 0 0;
    position: absolute
}

.k-navigator .k-navigator-n, .k-navigator .k-navigator-up {
    top: 2px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

.k-navigator .k-navigator-e, .k-navigator .k-navigator-right {
    right: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.k-navigator .k-navigator-down, .k-navigator .k-navigator-s {
    bottom: 2px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

.k-navigator .k-navigator-left, .k-navigator .k-navigator-w {
    left: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.k-pdf-export .k-navigator {
    display: none
}

.k-button-wrap {
    border-radius: 4px;
    display: inline-block
}

    .k-button-wrap .k-button {
        position: relative;
        padding: 4px;
        line-height: 16px
    }

        .k-button-wrap .k-button .k-icon {
            vertical-align: top
        }

.k-buttons-horizontal :first-child {
    border-radius: 4px 0 0 4px
}

    .k-buttons-horizontal :first-child + .k-zoom-in {
        border-radius: 0;
        margin-left: -1px
    }

.k-buttons-horizontal .k-zoom-out {
    border-radius: 0 4px 4px 0;
    margin-left: -1px
}

.k-button-wrap .k-button:hover {
    z-index: 1
}

.k-buttons-vertical .k-button {
    display: block
}

.k-buttons-vertical :first-child {
    border-radius: 4px 4px 0 0
}

.k-buttons-vertical .k-zoom-out {
    border-radius: 0 0 4px 4px;
    margin-top: -1px
}

.k-zoom-text {
    margin: 0;
    width: 4.3em;
    vertical-align: top
}

.k-rtl .k-buttons-horizontal :first-child {
    border-radius: 0 4px 4px 0
}

    .k-rtl .k-buttons-horizontal :first-child + .k-zoom-in {
        border-radius: 0;
        margin-left: 0;
        margin-right: -1px
    }

.k-rtl .k-buttons-horizontal .k-zoom-out {
    border-radius: 4px 0 0 4px;
    margin-left: 0;
    margin-right: -1px
}

.k-diagram {
    height: 600px
}

    .k-diagram .km-scroll-wrapper {
        width: 100%;
        height: 100%;
        position: relative
    }

    .k-diagram .km-scroll-wrapper {
        width: 100%;
        height: 100%;
        position: relative
    }

.k-canvas-container {
    width: 100%;
    height: 100%
}

.k-diagram img {
    box-sizing: content-box
}

.k-treemap {
    overflow: hidden;
    height: 400px
}

.k-treemap-tile {
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
    position: absolute;
    margin: -1px 0 0 -1px;
    overflow: hidden
}

    .k-treemap-tile.k-leaf {
        padding: .6em
    }

.k-treemap-wrap.k-last > .k-treemap-tile {
    padding: .3em
}

.k-treemap-tile.k-state-hover {
    z-index: 2;
    background-image: none
}

.k-treemap > .k-treemap-tile {
    position: relative;
    height: 100%
}

.k-treemap-title {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2.42em;
    height: 2.42em;
    padding: 0 .6em;
    white-space: nowrap
}

.k-treemap-wrap .k-treemap-title {
    border-width: 0 0 1px;
    border-style: solid
}

.k-treemap-wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

.k-treemap-title + .k-treemap-wrap {
    top: 2.42em
}

.k-treemap-title-vertical {
    box-sizing: border-box;
    text-overflow: ellipsis;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2.42em;
    line-height: 2.42em;
    overflow: hidden;
    padding: .6em 0;
    white-space: nowrap
}

    .k-treemap-title-vertical > div {
        position: absolute;
        top: 0;
        right: 1.23em;
        -webkit-transform-origin: right;
        -ms-transform-origin: right;
        transform-origin: right;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg)
    }

    .k-treemap-title-vertical + .k-treemap-wrap {
        left: 2.42em
    }

.k-pane-wrapper {
    position: relative;
    font-size: 14px
}

    .k-pane-wrapper .k-pane {
        width: 100%;
        height: 100%;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-sizing: border-box;
        font-family: sans-serif;
        overflow-x: hidden
    }

    .k-pane-wrapper .k-view {
        top: 0;
        left: 0;
        position: absolute;
        border: 0;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        width: 100%;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        vertical-align: top
    }

    .k-pane-wrapper .k-content {
        min-height: 1px;
        -ms-flex: 1;
        flex: 1;
        -ms-flex-align: stretch;
        align-items: stretch;
        display: block;
        width: auto;
        overflow: hidden;
        position: relative
    }

    .k-pane-wrapper .k-grid-column-menu > .k-header, .k-pane-wrapper .k-grid-edit-form > .k-header, .k-pane-wrapper .k-grid-filter-menu > .k-header, .k-pane-wrapper .k-scheduler-edit-form > .k-header {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: .3em .6em;
        width: auto;
        line-height: 2em
    }

        .k-pane-wrapper .k-grid-column-menu > .k-header .k-header-cancel .k-icon, .k-pane-wrapper .k-grid-column-menu > .k-header .k-header-done .k-icon, .k-pane-wrapper .k-grid-edit-form > .k-header .k-header-cancel .k-icon, .k-pane-wrapper .k-grid-edit-form > .k-header .k-header-done .k-icon, .k-pane-wrapper .k-grid-filter-menu > .k-header .k-header-cancel .k-icon, .k-pane-wrapper .k-grid-filter-menu > .k-header .k-header-done .k-icon, .k-pane-wrapper .k-scheduler-edit-form > .k-header .k-header-cancel .k-icon, .k-pane-wrapper .k-scheduler-edit-form > .k-header .k-header-done .k-icon {
            font-size: 1.5em
        }

    .k-pane-wrapper .k-grid-edit-row .k-textbox {
        box-sizing: border-box
    }

.k-ie .k-pane-wrapper .k-scheduler .k-scheduler-footer, .k-ie .k-pane-wrapper .k-scheduler .k-scheduler-toolbar {
    line-height: 2em
}

.k-pane-wrapper > div.k-pane {
    box-shadow: none;
    font-weight: 400
}

.k-pane-wrapper .k-grid-column-menu .k-content, .k-pane-wrapper .k-grid-edit-form .k-content, .k-pane-wrapper .k-grid-filter-menu .k-content, .k-pane-wrapper .k-popup-edit-form .k-content {
    overflow-y: auto
}

    .k-pane-wrapper .k-grid-column-menu .k-content > .k-scroll-container, .k-pane-wrapper .k-grid-edit-form .k-content > .k-scroll-container, .k-pane-wrapper .k-grid-filter-menu .k-content > .k-scroll-container, .k-pane-wrapper .k-popup-edit-form .k-content > .k-scroll-container {
        position: absolute;
        width: 100%;
        min-height: 100%;
        box-sizing: border-box
    }

.k-pane-wrapper .k-grid-filter-menu .k-filter-selected-items {
    margin: 1em;
    font-weight: 400
}

.k-pane-wrapper .k-grid-edit-form .k-edit-form-container, .k-pane-wrapper .k-grid-edit-form .k-popup-edit-form {
    width: auto
}

.k-pane-wrapper .k-grid .k-grid-search {
    width: 100%;
    margin-left: 0;
    margin-top: 6px
}

    .k-pane-wrapper .k-grid .k-grid-search:first-child {
        margin-top: 0
    }

.k-grid-mobile {
    border-width: 0
}

    .k-grid-mobile .k-resize-handle-inner::before {
        content: "\e01e";
        position: absolute;
        top: calc(50% - 1px);
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        padding: .2em
    }

    .k-grid-mobile .k-edit-cell > input, .k-grid-mobile .k-edit-cell > select, .k-grid-mobile .k-grid-edit-row > td > input, .k-grid-mobile .k-grid-edit-row > td > select {
        width: 100%;
        box-sizing: border-box
    }

    .k-grid-mobile .k-header a {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-user-drag: none;
        -moz-user-drag: none
    }

.k-pane-wrapper .k-scheduler-edit-form .k-recur-view {
    padding: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: stretch;
    align-items: stretch
}

    .k-pane-wrapper .k-scheduler-edit-form .k-recur-view > .k-listgroup-form-row {
        margin: 0
    }

.k-pane-wrapper .k-scheduler-edit-form .k-recur-items-wrap {
    width: 100%;
    margin: -1px 0
}

.k-pane-wrapper .k-scheduler-edit-form .k-scheduler-recur-end-wrap {
    white-space: nowrap
}

.k-scheduler-mobile {
    border-width: 0
}

    .k-scheduler-mobile th {
        font-weight: 400
    }

    .k-scheduler-mobile .k-scheduler-toolbar {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

        .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-tools {
            margin: 0
        }

            .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-tools .k-button {
                padding: 4px
            }

                .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-tools .k-button span {
                    margin: 0
                }

        .k-scheduler-mobile .k-scheduler-toolbar .k-i-calendar {
            display: inline-block
        }

        .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation {
            width: 100%;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin: 0
        }

            .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation .k-nav-current, .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation .k-nav-next, .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation .k-nav-prev {
                border: 0;
                background: 0 0;
                display: -ms-flexbox;
                display: flex;
                line-height: 1.5;
                -ms-flex-align: center;
                align-items: center;
                -ms-flex-pack: space-evenly;
                justify-content: space-evenly
            }

            .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation .k-y-date-format {
                font-size: 9px
            }

            .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation .k-nav-current {
                -ms-flex-direction: column;
                flex-direction: column
            }

    .k-scheduler-mobile .k-scheduler-footer {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .k-scheduler-mobile .k-scheduler-monthview .k-hidden {
        height: 40px
    }

    .k-scheduler-mobile .k-scheduler-monthview .k-scheduler-table td {
        height: 40px;
        vertical-align: top;
        text-align: center
    }

    .k-scheduler-mobile .k-scheduler-monthview .k-events-container {
        position: absolute;
        text-align: center;
        height: 6px;
        line-height: 6px
    }

    .k-scheduler-mobile .k-scheduler-monthview .k-event {
        position: static;
        display: inline-block;
        width: 4px;
        height: 4px;
        min-height: 0;
        margin: 1px
    }

    .k-scheduler-mobile .k-scheduler-dayview .k-mobile-header.k-mobile-horizontal-header .k-scheduler-times table tr:first-child {
        display: none
    }

    .k-scheduler-mobile .k-scheduler-dayview .k-mobile-header .k-scheduler-header .k-scheduler-date-group {
        display: none
    }

    .k-scheduler-mobile .k-scheduler-agendaview .k-mobile-header {
        display: none
    }

    .k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table {
        table-layout: auto
    }

        .k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table .k-scheduler-groupcolumn {
            width: 1%
        }

    .k-scheduler-mobile .k-mobile-header .k-scheduler-table td, .k-scheduler-mobile .k-mobile-header .k-scheduler-table th {
        height: 1.5em
    }

    .k-scheduler-mobile .k-scheduler-table td, .k-scheduler-mobile .k-scheduler-table th {
        height: 2em;
        vertical-align: middle
    }

    .k-scheduler-mobile .k-scheduler-times th {
        font-size: 1em
    }

    .k-scheduler-mobile .k-scheduler-datecolumn-wrap {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .k-scheduler-mobile .k-task {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }

        .k-scheduler-mobile .k-task .k-scheduler-mark {
            border-radius: 50%
        }

        .k-scheduler-mobile .k-task .k-i-reload {
            font-size: 1em;
            margin-right: .5em
        }

        .k-scheduler-mobile .k-task .k-scheduler-task-text {
            -ms-flex: 1 1 0%;
            flex: 1 1 0%
        }

    .k-scheduler-mobile .k-scheduler-agenda .k-scheduler-group-cell, .k-scheduler-mobile .k-scheduler-agenda .k-scheduler-groupcolumn, .k-scheduler-mobile .k-scheduler-times .k-scheduler-group-cell, .k-scheduler-mobile .k-scheduler-times .k-scheduler-groupcolumn {
        vertical-align: top
    }

        .k-scheduler-mobile .k-scheduler-agenda .k-scheduler-group-cell .k-scheduler-group-text, .k-scheduler-mobile .k-scheduler-agenda .k-scheduler-groupcolumn .k-scheduler-group-text, .k-scheduler-mobile .k-scheduler-times .k-scheduler-group-cell .k-scheduler-group-text, .k-scheduler-mobile .k-scheduler-times .k-scheduler-groupcolumn .k-scheduler-group-text {
            -webkit-writing-mode: vertical-lr;
            -ms-writing-mode: tb-lr;
            writing-mode: vertical-lr;
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            white-space: nowrap
        }

    .k-scheduler-mobile .k-scrollbar-h tr + tr .k-scheduler-times {
        border-bottom-width: 0
    }

.k-pane-wrapper .k-pane * {
    background-clip: border-box
}

.k-pane-wrapper .k-filter-help-text, .k-pane-wrapper .k-list-title {
    padding: .5em 1em;
    display: block
}

.k-pane-wrapper .k-listgroup .k-listgroup-item {
    border-color: inherit
}

.k-pane-wrapper .k-listgroup + .k-listgroup {
    margin-top: 2em
}

.k-pane-wrapper .k-column-menu .k-filter-item .k-filterable * {
    pointer-events: none
}

.k-pane-wrapper .k-filter-menu {
    padding: 0
}

    .k-pane-wrapper .k-filter-menu .k-space-right {
        border: 0;
        padding: 0 10px;
        background: 0
    }

        .k-pane-wrapper .k-filter-menu .k-space-right > input {
            width: 100%;
            height: 2em;
            padding: 1px 0;
            margin: 0;
            border-radius: 3px;
            text-indent: 1em;
            border-width: 1px;
            border-style: solid
        }

        .k-pane-wrapper .k-filter-menu .k-space-right > .k-i-zoom {
            right: 15px;
            z-index: 2
        }

    .k-pane-wrapper .k-filter-menu .k-filter-tools {
        margin: 1em;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

.k-pane-wrapper .k-popup-edit-form .k-recur-editor-wrap {
    display: -ms-flexbox;
    display: flex
}

.k-pane-wrapper .k-popup-edit-form .k-tooltip-validation {
    top: 100%;
    left: 0
}

.k-pane-wrapper .k-popup-edit-form .k-mobiletimezoneeditor {
    width: 100;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.k-pane-wrapper .k-multicheck-wrap.k-listgroup {
    overflow-y: auto
}

.k-pane-wrapper .k-state-disabled {
    opacity: 1
}

    .k-pane-wrapper .k-state-disabled > * {
        opacity: .7
    }

.k-ie .k-pane-wrapper .k-grid .k-icon {
    text-indent: 0
}

.k-pane-wrapper .k-pager-wrap.k-pager-sm {
    -ms-flex-pack: center;
    justify-content: center
}

    .k-pane-wrapper .k-pager-wrap.k-pager-sm .k-pager-refresh {
        display: none
    }

    .k-pane-wrapper .k-pager-wrap.k-pager-sm .k-current-page .k-link {
        line-height: .9em
    }

.km-scroll-container {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-margin-collapse: separate;
    -webkit-transform: translatez(0)
}

.k-widget .km-scroll-wrapper {
    position: relative;
    padding-bottom: 0
}

.km-touch-scrollbar {
    position: absolute;
    visibility: hidden;
    z-index: 200000;
    height: .3em;
    width: .3em;
    background-color: rgba(0,0,0,.7);
    opacity: 0;
    transition: opacity .3s linear
}

.km-vertical-scrollbar {
    height: 100%;
    right: 2px;
    top: 2px
}

.km-horizontal-scrollbar {
    width: 100%;
    left: 2px;
    bottom: 2px
}

.k-pane-wrapper[dir=rtl] .k-grid-column-menu > .k-header, .k-pane-wrapper[dir=rtl] .k-grid-edit-form > .k-header, .k-pane-wrapper[dir=rtl] .k-grid-filter-menu > .k-header, .k-pane-wrapper[dir=rtl] .k-scheduler-edit-form > .k-header, .k-rtl .k-pane-wrapper .k-grid-column-menu > .k-header, .k-rtl .k-pane-wrapper .k-grid-edit-form > .k-header, .k-rtl .k-pane-wrapper .k-grid-filter-menu > .k-header, .k-rtl .k-pane-wrapper .k-scheduler-edit-form > .k-header, [dir=rtl] .k-pane-wrapper .k-grid-column-menu > .k-header, [dir=rtl] .k-pane-wrapper .k-grid-edit-form > .k-header, [dir=rtl] .k-pane-wrapper .k-grid-filter-menu > .k-header, [dir=rtl] .k-pane-wrapper .k-scheduler-edit-form > .k-header {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.k-pane-wrapper[dir=rtl] .k-filter-menu .k-space-right > .k-i-zoom, .k-rtl .k-pane-wrapper .k-filter-menu .k-space-right > .k-i-zoom, [dir=rtl] .k-pane-wrapper .k-filter-menu .k-space-right > .k-i-zoom {
    left: 15px;
    right: auto
}

.k-pane-wrapper[dir=rtl] .k-scheduler-mobile .k-scheduler-toolbar > ul > li, .k-rtl .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar > ul > li, [dir=rtl] .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar > ul > li {
    border: 0
}

.k-pane-wrapper[dir=rtl] .k-scheduler-mobile .k-scheduler-toolbar .k-nav-next, .k-pane-wrapper[dir=rtl] .k-scheduler-mobile .k-scheduler-toolbar .k-nav-prev, .k-rtl .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar .k-nav-next, .k-rtl .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar .k-nav-prev, [dir=rtl] .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar .k-nav-next, [dir=rtl] .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar .k-nav-prev {
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1)
}

.k-pane-wrapper[dir=rtl] .k-scheduler-mobile .k-task .k-i-reload, .k-rtl .k-pane-wrapper .k-scheduler-mobile .k-task .k-i-reload, [dir=rtl] .k-pane-wrapper .k-scheduler-mobile .k-task .k-i-reload {
    margin-left: .5em;
    margin-right: 0
}

.k-pane-wrapper[dir=rtl] .k-scheduler-edit-form .k-item .k-i-arrow-chevron-right, .k-rtl .k-pane-wrapper .k-scheduler-edit-form .k-item .k-i-arrow-chevron-right, [dir=rtl] .k-pane-wrapper .k-scheduler-edit-form .k-item .k-i-arrow-chevron-right {
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1)
}

.k-fx-end .k-fx-current, .k-fx-end .k-fx-next {
    transition: all 350ms ease-out
}

.k-fx {
    position: relative
}

    .k-fx .k-fx-current {
        z-index: 0
    }

    .k-fx .k-fx-next {
        z-index: 1
    }

.k-fx-hidden, .k-fx-hidden * {
    visibility: hidden !important
}

.k-fx-reverse .k-fx-current {
    z-index: 1
}

.k-fx-reverse .k-fx-next {
    z-index: 0
}

.k-fx-zoom.k-fx-start .k-fx-next {
    -webkit-transform: scale(0) !important;
    -ms-transform: scale(0) !important;
    transform: scale(0) !important
}

.k-fx-zoom.k-fx-end .k-fx-next {
    -webkit-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
    transform: scale(1) !important
}

.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next, .k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next {
    -webkit-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
    transform: scale(1) !important
}

.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
    -webkit-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
    transform: scale(1) !important
}

.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
    -webkit-transform: scale(0) !important;
    -ms-transform: scale(0) !important;
    transform: scale(0) !important
}

.k-fx-fade.k-fx-start .k-fx-next {
    will-change: opacity;
    opacity: 0
}

.k-fx-fade.k-fx-end .k-fx-next {
    opacity: 1
}

.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
    will-change: opacity;
    opacity: 1
}

.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
    opacity: 0
}

.k-fx-slide.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-end .k-fx-current .k-footer, .k-fx-slide.k-fx-end .k-fx-current .k-header, .k-fx-slide.k-fx-end .k-fx-current .km-content, .k-fx-slide.k-fx-end .k-fx-current .km-footer, .k-fx-slide.k-fx-end .k-fx-current .km-header, .k-fx-slide.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-end .k-fx-next .km-content, .k-fx-slide.k-fx-end .k-fx-next .km-footer, .k-fx-slide.k-fx-end .k-fx-next .km-header {
    transition: all 350ms ease-out
}

.k-fx-slide.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-start .k-fx-next .km-content {
    will-change: transform;
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx-slide.k-fx-start .k-fx-next .k-footer, .k-fx-slide.k-fx-start .k-fx-next .k-header, .k-fx-slide.k-fx-start .k-fx-next .km-footer, .k-fx-slide.k-fx-start .k-fx-next .km-header {
    will-change: opacity;
    opacity: 0
}

.k-fx-slide.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-end .k-fx-current .km-content {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx-slide.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-end .k-fx-next .km-footer, .k-fx-slide.k-fx-end .k-fx-next .km-header {
    opacity: 1
}

.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-content, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
    will-change: transform;
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0)
}

.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0)
}

.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-footer, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header {
    will-change: opacity;
    opacity: 1
}

.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-footer, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header {
    opacity: 1
}

.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-footer, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header {
    opacity: 0
}

.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header {
    opacity: 1
}

.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0)
}

.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0)
}

.k-fx-tile.k-fx-start .k-fx-next {
    will-change: transform;
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx-tile.k-fx-end .k-fx-current {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
    will-change: transform;
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0)
}

.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0)
}

.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0)
}

.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0)
}

.k-fx-tile.k-fx-start .k-fx-next {
    will-change: transform;
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx-tile.k-fx-end .k-fx-current {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
    will-change: transform;
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0)
}

.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0)
}

.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0)
}

.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
    -webkit-transform: translatex(0);
    -ms-transform: translatex(0);
    transform: translatex(0)
}

.k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next, .k-fx.k-fx-overlay.k-fx-start .k-fx-next {
    will-change: transform;
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
    -webkit-transform: translatey(100%);
    -ms-transform: translatey(100%);
    transform: translatey(100%)
}

.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
    -webkit-transform: translatey(-100%);
    -ms-transform: translatey(-100%);
    transform: translatey(-100%)
}

.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
    will-change: transform;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current, .k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
    -webkit-transform: translatex(100%);
    -ms-transform: translatex(100%);
    transform: translatex(100%)
}

.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
    -webkit-transform: translatex(-100%);
    -ms-transform: translatex(-100%);
    transform: translatex(-100%)
}

.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
    -webkit-transform: translatey(100%);
    -ms-transform: translatey(100%);
    transform: translatey(100%)
}

.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
    -webkit-transform: translatey(-100%);
    -ms-transform: translatey(-100%);
    transform: translatey(-100%)
}

.k-virtual-wrap {
    position: relative
}

    .k-virtual-wrap .k-list.k-virtual-list {
        height: auto
    }

.k-virtual-content {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative
}

.k-virtual-list > .k-virtual-content {
    position: absolute;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none
}

.k-virtual-option-label {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none
}

.k-virtual-wrap > .k-virtual-header {
    text-align: right
}

.k-popup .k-item.k-first {
    position: relative
}

.k-virtual-content > .k-virtual-list > .k-virtual-item {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap
}

.k-popup .k-list .k-item > .k-group, .k-popup > .k-group-header, .k-popup > .k-virtual-wrap > .k-group-header {
    text-transform: uppercase;
    font-size: .857em
}

.k-popup .k-list .k-item > .k-group {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 .5em;
    line-height: 1.8
}

.k-popup .k-state-hover > .k-group {
    right: -1px
}

.k-group-header + .k-list > .k-item.k-first, .k-static-header + .k-list > .k-item.k-first, .k-virtual-item.k-first {
    border-top-style: solid;
    border-top-width: 1px;
    padding-top: 0
}

.k-popup > .k-group-header, .k-popup > .k-virtual-wrap > .k-group-header {
    text-align: right
}

@font-face {
    font-family: WebComponentsIcons;
    src: url(fonts/glyphs/WebComponentsIcons.eot?gedxeo);
    src: url(fonts/glyphs/WebComponentsIcons.eot?gedxeo#iefix) format('embedded-opentype'),url(fonts/glyphs/WebComponentsIcons.ttf?gedxeo) format('truetype'),url(fonts/glyphs/WebComponentsIcons.woff?gedxeo) format('woff'),url(fonts/glyphs/WebComponentsIcons.svg?gedxeo#WebComponentsIcons) format('svg');
    font-weight: 400;
    font-style: normal
}

.k-i-arrow-45-up-right:before {
    content: "\e000"
}

.k-i-collapse-ne:before {
    content: "\e000"
}

.k-i-resize-ne:before {
    content: "\e000"
}

.k-i-arrow-45-down-right:before {
    content: "\e001"
}

.k-i-collapse-se:before {
    content: "\e001"
}

.k-i-collapse:before {
    content: "\e001"
}

.k-i-resize-se:before {
    content: "\e001"
}

.k-i-arrow-45-down-left:before {
    content: "\e002"
}

.k-i-collapse-sw:before {
    content: "\e002"
}

.k-i-resize-sw:before {
    content: "\e002"
}

.k-i-arrow-45-up-left:before {
    content: "\e003"
}

.k-i-collapse-nw:before {
    content: "\e003"
}

.k-i-resize-new:before {
    content: "\e003"
}

.k-i-arrow-60-up:before {
    content: "\e004"
}

.k-i-arrow-n:before {
    content: "\e004"
}

.k-i-kpi-trend-increase:before {
    content: "\e004"
}

.k-i-arrow-60-right:before {
    content: "\e005"
}

.k-i-arrow-e:before {
    content: "\e005"
}

.k-i-expand:before {
    content: "\e005"
}

.k-i-arrow-60-down:before {
    content: "\e006"
}

.k-i-arrow-s:before {
    content: "\e006"
}

.k-i-kpi-trend-decrease:before {
    content: "\e006"
}

.k-i-arrow-60-left:before {
    content: "\e007"
}

.k-i-arrow-w:before {
    content: "\e007"
}

.k-i-arrow-end-up:before {
    content: "\e008"
}

.k-i-seek-n:before {
    content: "\e008"
}

.k-i-arrow-end-right:before {
    content: "\e009"
}

.k-i-seek-e:before {
    content: "\e009"
}

.k-i-arrow-end-down:before {
    content: "\e00a"
}

.k-i-seek-s:before {
    content: "\e00a"
}

.k-i-arrow-end-left:before {
    content: "\e00b"
}

.k-i-seek-w:before {
    content: "\e00b"
}

.k-i-arrow-double-60-up:before {
    content: "\e00c"
}

.k-i-arrow-seek-up:before {
    content: "\e00c"
}

.k-i-arrow-double-60-right:before {
    content: "\e00d"
}

.k-i-arrow-seek-right:before {
    content: "\e00d"
}

.k-i-forward-sm:before {
    content: "\e00d"
}

.k-i-arrow-double-60-down:before {
    content: "\e00e"
}

.k-i-arrow-seek-down:before {
    content: "\e00e"
}

.k-i-arrow-double-60-left:before {
    content: "\e00f"
}

.k-i-arrow-seek-left:before {
    content: "\e00f"
}

.k-i-rewind-sm:before {
    content: "\e00f"
}

.k-i-arrows-kpi:before {
    content: "\e010"
}

.k-i-kpi:before {
    content: "\e010"
}

.k-i-arrows-no-change:before {
    content: "\e011"
}

.k-i-arrow-overflow-down:before {
    content: "\e012"
}

.k-i-arrow-chevron-up:before {
    content: "\e013"
}

.k-i-arrow-chevron-right:before {
    content: "\e014"
}

.k-i-arrow-chevron-down:before {
    content: "\e015"
}

.k-i-arrow-chevron-left:before {
    content: "\e016"
}

.k-i-arrow-up:before {
    content: "\e017"
}

.k-i-arrow-right:before {
    content: "\e018"
}

.k-i-arrow-down:before {
    content: "\e019"
}

.k-i-arrow-left:before {
    content: "\e01a"
}

.k-i-arrow-drill:before {
    content: "\e01b"
}

.k-i-arrow-parent:before {
    content: "\e01c"
}

.k-i-arrow-root:before {
    content: "\e01d"
}

.k-i-arrows-resizing:before {
    content: "\e01e"
}

.k-i-arrows-dimensions:before {
    content: "\e01f"
}

.k-i-arrows-swap:before {
    content: "\e020"
}

.k-i-drag-and-drop:before {
    content: "\e021"
}

.k-i-categorize:before {
    content: "\e022"
}

.k-i-grid:before {
    content: "\e023"
}

.k-i-grid-layout:before {
    content: "\e024"
}

.k-i-group:before {
    content: "\e025"
}

.k-i-ungroup:before {
    content: "\e026"
}

.k-i-handler-drag:before {
    content: "\e027"
}

.k-i-layout:before {
    content: "\e028"
}

.k-i-layout-1-by-4:before {
    content: "\e029"
}

.k-i-layout-2-by-2:before {
    content: "\e02a"
}

.k-i-layout-side-by-side:before {
    content: "\e02b"
}

.k-i-layout-stacked:before {
    content: "\e02c"
}

.k-i-columns:before {
    content: "\e02d"
}

.k-i-rows:before {
    content: "\e02e"
}

.k-i-reorder:before {
    content: "\e02f"
}

.k-i-menu:before {
    content: "\e030"
}

.k-i-more-vertical:before {
    content: "\e031"
}

.k-i-more-horizontal:before {
    content: "\e032"
}

.k-i-undo:before {
    content: "\e100"
}

.k-i-redo:before {
    content: "\e101"
}

.k-i-reset:before {
    content: "\e102"
}

.k-i-reload:before {
    content: "\e103"
}

.k-i-refresh:before {
    content: "\e103"
}

.k-i-recurrence:before {
    content: "\e103"
}

.k-i-non-recurrence:before {
    content: "\e104"
}

.k-i-reset-sm:before {
    content: "\e105"
}

.k-i-reload-sm:before {
    content: "\e106"
}

.k-i-refresh-sm:before {
    content: "\e106"
}

.k-i-recurrence-sm:before {
    content: "\e106"
}

.k-i-clock:before {
    content: "\e107"
}

.k-i-calendar:before {
    content: "\e108"
}

.k-i-save:before {
    content: "\e109"
}

.k-i-floppy:before {
    content: "\e109"
}

.k-i-print:before {
    content: "\e10a"
}

.k-i-printer:before {
    content: "\e10a"
}

.k-i-edit:before {
    content: "\e10b"
}

.k-i-pencil:before {
    content: "\e10b"
}

.k-i-delete:before {
    content: "\e10c"
}

.k-i-trash:before {
    content: "\e10c"
}

.k-i-attachment:before {
    content: "\e10d"
}

.k-i-clip:before {
    content: "\e10d"
}

.k-i-attachment-45:before {
    content: "\e10e"
}

.k-i-clip-45:before {
    content: "\e10e"
}

.k-i-link-horizontal:before {
    content: "\e10f"
}

.k-i-hyperlink:before {
    content: "\e10f"
}

.k-i-unlink-horizontal:before {
    content: "\e110"
}

.k-i-hyperlink-remove:before {
    content: "\e110"
}

.k-i-link-vertical:before {
    content: "\e111"
}

.k-i-unlink-vertical:before {
    content: "\e112"
}

.k-i-lock:before {
    content: "\e113"
}

.k-i-unlock:before {
    content: "\e114"
}

.k-i-cancel:before {
    content: "\e115"
}

.k-i-cancel-outline:before {
    content: "\e116"
}

.k-i-cancel-circle:before {
    content: "\e117"
}

.k-i-check:before {
    content: "\e118"
}

.k-i-checkmark:before {
    content: "\e118"
}

.k-i-tick:before {
    content: "\e118"
}

.k-i-check-outline:before {
    content: "\e119"
}

.k-i-checkmark-outline:before {
    content: "\e119"
}

.k-i-success:before {
    content: "\e119"
}

.k-i-check-circle:before {
    content: "\e11a"
}

.k-i-checkmark-circle:before {
    content: "\e11a"
}

.k-i-close:before {
    content: "\e11b"
}

.k-i-x:before {
    content: "\e11b"
}

.k-i-close-outline:before {
    content: "\e11c"
}

.k-i-x-outline:before {
    content: "\e11c"
}

.k-i-error:before {
    content: "\e11c"
}

.k-i-close-circle:before {
    content: "\e11d"
}

.k-i-x-circle:before {
    content: "\e11d"
}

.k-i-plus:before {
    content: "\e11e"
}

.k-i-add:before {
    content: "\e11e"
}

.k-i-plus-outline:before {
    content: "\e11f"
}

.k-i-plus-circle:before {
    content: "\e120"
}

.k-i-minus:before {
    content: "\e121"
}

.k-i-kpi-trend-equal:before {
    content: "\e121"
}

.k-i-minus-outline:before {
    content: "\e122"
}

.k-i-minus-circle:before {
    content: "\e123"
}

.k-i-sort-asc:before {
    content: "\e124"
}

.k-i-sort-desc:before {
    content: "\e125"
}

.k-i-unsort:before {
    content: "\e126"
}

.k-i-sort-clear:before {
    content: "\e126"
}

.k-i-sort-asc-sm:before {
    content: "\e127"
}

.k-i-sort-desc-sm:before {
    content: "\e128"
}

.k-i-filter:before {
    content: "\e129"
}

.k-i-filter-clear:before {
    content: "\e12a"
}

.k-i-filter-sm:before {
    content: "\e12b"
}

.k-i-filter-sort-asc-sm:before {
    content: "\e12c"
}

.k-i-filter-sort-desc-sm:before {
    content: "\e12d"
}

.k-i-filter-add-expression:before {
    content: "\e12e"
}

.k-i-filter-add-group:before {
    content: "\e12f"
}

.k-i-login:before {
    content: "\e130"
}

.k-i-logout:before {
    content: "\e131"
}

.k-i-download:before {
    content: "\e132"
}

.k-i-upload:before {
    content: "\e133"
}

.k-i-hyperlink-open:before {
    content: "\e134"
}

.k-i-hyperlink-open-sm:before {
    content: "\e135"
}

.k-i-launch:before {
    content: "\e136"
}

.k-i-window:before {
    content: "\e137"
}

.k-i-window-maximize:before {
    content: "\e137"
}

.k-i-windows:before {
    content: "\e138"
}

.k-i-window-restore:before {
    content: "\e138"
}

.k-i-tiles:before {
    content: "\e138"
}

.k-i-window-minimize:before {
    content: "\e139"
}

.k-i-gear:before {
    content: "\e13a"
}

.k-i-cog:before {
    content: "\e13a"
}

.k-i-custom:before {
    content: "\e13a"
}

.k-i-gears:before {
    content: "\e13b"
}

.k-i-cogs:before {
    content: "\e13b"
}

.k-i-wrench:before {
    content: "\e13c"
}

.k-i-settings:before {
    content: "\e13c"
}

.k-i-preview:before {
    content: "\e13d"
}

.k-i-eye:before {
    content: "\e13d"
}

.k-i-zoom:before {
    content: "\e13e"
}

.k-i-search:before {
    content: "\e13e"
}

.k-i-zoom-in:before {
    content: "\e13f"
}

.k-i-zoom-out:before {
    content: "\e140"
}

.k-i-pan:before {
    content: "\e141"
}

.k-i-move:before {
    content: "\e141"
}

.k-i-calculator:before {
    content: "\e142"
}

.k-i-cart:before {
    content: "\e143"
}

.k-i-shopping-cart:before {
    content: "\e143"
}

.k-i-connector:before {
    content: "\e144"
}

.k-i-plus-sm:before {
    content: "\e145"
}

.k-i-minus-sm:before {
    content: "\e146"
}

.k-i-kpi-status-deny:before {
    content: "\e147"
}

.k-i-kpi-status-hold:before {
    content: "\e148"
}

.k-i-kpi-status-open:before {
    content: "\e149"
}

.k-i-play:before {
    content: "\e200"
}

.k-i-pause:before {
    content: "\e201"
}

.k-i-stop:before {
    content: "\e202"
}

.k-i-rewind:before {
    content: "\e203"
}

.k-i-forward:before {
    content: "\e204"
}

.k-i-volume-down:before {
    content: "\e205"
}

.k-i-volume-up:before {
    content: "\e206"
}

.k-i-volume-off:before {
    content: "\e207"
}

.k-i-hd:before {
    content: "\e208"
}

.k-i-subtitles:before {
    content: "\e209"
}

.k-i-playlist:before {
    content: "\e20a"
}

.k-i-audio:before {
    content: "\e20b"
}

.k-i-play-sm:before {
    content: "\e20c"
}

.k-i-pause-sm:before {
    content: "\e20d"
}

.k-i-stop-sm:before {
    content: "\e20e"
}

.k-i-heart-outline:before {
    content: "\e300"
}

.k-i-fav-outline:before {
    content: "\e300"
}

.k-i-favorite-outline:before {
    content: "\e300"
}

.k-i-heart:before {
    content: "\e301"
}

.k-i-fav:before {
    content: "\e301"
}

.k-i-favorite:before {
    content: "\e301"
}

.k-i-star-outline:before {
    content: "\e302"
}

.k-i-bookmark-outline:before {
    content: "\e302"
}

.k-i-star:before {
    content: "\e303"
}

.k-i-bookmark:before {
    content: "\e303"
}

.k-i-checkbox:before {
    content: "\e304"
}

.k-i-shape-rect:before {
    content: "\e304"
}

.k-i-checkbox-checked:before {
    content: "\e305"
}

.k-i-tri-state-indeterminate:before {
    content: "\e306"
}

.k-i-tri-state-null:before {
    content: "\e307"
}

.k-i-circle:before {
    content: "\e308"
}

.k-i-radiobutton:before {
    content: "\e309"
}

.k-i-shape-circle:before {
    content: "\e309"
}

.k-i-radiobutton-checked:before {
    content: "\e30a"
}

.k-i-notification:before {
    content: "\e400"
}

.k-i-bell:before {
    content: "\e400"
}

.k-i-information:before {
    content: "\e401"
}

.k-i-info:before {
    content: "\e401"
}

.k-i-note:before {
    content: "\e401"
}

.k-i-question:before {
    content: "\e402"
}

.k-i-help:before {
    content: "\e402"
}

.k-i-warning:before {
    content: "\e403"
}

.k-i-exception:before {
    content: "\e403"
}

.k-i-photo-camera:before {
    content: "\e500"
}

.k-i-image:before {
    content: "\e501"
}

.k-i-photo:before {
    content: "\e501"
}

.k-i-image-export:before {
    content: "\e502"
}

.k-i-photo-export:before {
    content: "\e502"
}

.k-i-zoom-actual-size:before {
    content: "\e503"
}

.k-i-zoom-best-fit:before {
    content: "\e504"
}

.k-i-image-resize:before {
    content: "\e505"
}

.k-i-crop:before {
    content: "\e506"
}

.k-i-mirror:before {
    content: "\e507"
}

.k-i-flip-horizontal:before {
    content: "\e508"
}

.k-i-flip-vertical:before {
    content: "\e509"
}

.k-i-rotate:before {
    content: "\e50a"
}

.k-i-rotate-right:before {
    content: "\e50b"
}

.k-i-rotate-left:before {
    content: "\e50c"
}

.k-i-brush:before {
    content: "\e50d"
}

.k-i-palette:before {
    content: "\e50e"
}

.k-i-paint:before {
    content: "\e50f"
}

.k-i-droplet:before {
    content: "\e50f"
}

.k-i-background:before {
    content: "\e50f"
}

.k-i-line:before {
    content: "\e510"
}

.k-i-shape-line:before {
    content: "\e510"
}

.k-i-brightness-contrast:before {
    content: "\e511"
}

.k-i-saturation:before {
    content: "\e512"
}

.k-i-invert-colors:before {
    content: "\e513"
}

.k-i-transperancy:before {
    content: "\e514"
}

.k-i-opacity:before {
    content: "\e514"
}

.k-i-greyscale:before {
    content: "\e515"
}

.k-i-blur:before {
    content: "\e516"
}

.k-i-sharpen:before {
    content: "\e517"
}

.k-i-shape:before {
    content: "\e518"
}

.k-i-round-corners:before {
    content: "\e519"
}

.k-i-front-element:before {
    content: "\e51a"
}

.k-i-back-element:before {
    content: "\e51b"
}

.k-i-forward-element:before {
    content: "\e51c"
}

.k-i-backward-element:before {
    content: "\e51d"
}

.k-i-align-left-element:before {
    content: "\e51e"
}

.k-i-align-center-element:before {
    content: "\e51f"
}

.k-i-align-right-element:before {
    content: "\e520"
}

.k-i-align-top-element:before {
    content: "\e521"
}

.k-i-align-middle-element:before {
    content: "\e522"
}

.k-i-align-bottom-element:before {
    content: "\e523"
}

.k-i-thumbnails-up:before {
    content: "\e524"
}

.k-i-thumbnails-right:before {
    content: "\e525"
}

.k-i-thumbnails-down:before {
    content: "\e526"
}

.k-i-thumbnails-left:before {
    content: "\e527"
}

.k-i-full-screen:before {
    content: "\e528"
}

.k-i-fullscreen:before {
    content: "\e528"
}

.k-i-full-screen-exit:before {
    content: "\e529"
}

.k-i-fullscreen-exit:before {
    content: "\e529"
}

.k-i-reset-color:before {
    content: "\e52a"
}

.k-i-paint-remove:before {
    content: "\e52a"
}

.k-i-background-remove:before {
    content: "\e52a"
}

.k-i-page-properties:before {
    content: "\e600"
}

.k-i-bold:before {
    content: "\e601"
}

.k-i-italic:before {
    content: "\e602"
}

.k-i-underline:before {
    content: "\e603"
}

.k-i-font-family:before {
    content: "\e604"
}

.k-i-foreground-color:before {
    content: "\e605"
}

.k-i-convert-lowercase:before {
    content: "\e606"
}

.k-i-convert-uppercase:before {
    content: "\e607"
}

.k-i-strikethrough:before {
    content: "\e608"
}

.k-i-sub-script:before {
    content: "\e609"
}

.k-i-sup-script:before {
    content: "\e60a"
}

.k-i-div:before {
    content: "\e60b"
}

.k-i-all:before {
    content: "\e60c"
}

.k-i-h1:before {
    content: "\e60d"
}

.k-i-h2:before {
    content: "\e60e"
}

.k-i-h3:before {
    content: "\e60f"
}

.k-i-h4:before {
    content: "\e610"
}

.k-i-h5:before {
    content: "\e611"
}

.k-i-h6:before {
    content: "\e612"
}

.k-i-list-ordered:before {
    content: "\e613"
}

.k-i-list-numbered:before {
    content: "\e613"
}

.k-i-list-unordered:before {
    content: "\e614"
}

.k-i-list-bulleted:before {
    content: "\e614"
}

.k-i-indent-increase:before {
    content: "\e615"
}

.k-i-indent:before {
    content: "\e615"
}

.k-i-indent-decrease:before {
    content: "\e616"
}

.k-i-outdent:before {
    content: "\e616"
}

.k-i-insert-n::before {
    content: "\e617"
}

.k-i-insert-up:before {
    content: "\e617"
}

.k-i-insert-top:before {
    content: "\e617"
}

.k-i-insert-m:before {
    content: "\e618"
}

.k-i-insert-middle:before {
    content: "\e618"
}

.k-i-insert-s:before {
    content: "\e619"
}

.k-i-insert-down:before {
    content: "\e619"
}

.k-i-insert-bottom:before {
    content: "\e619"
}

.k-i-align-top:before {
    content: "\e61a"
}

.k-i-align-middle:before {
    content: "\e61b"
}

.k-i-align-bottom:before {
    content: "\e61c"
}

.k-i-align-left:before {
    content: "\e61d"
}

.k-i-align-center:before {
    content: "\e61e"
}

.k-i-align-right:before {
    content: "\e61f"
}

.k-i-align-justify:before {
    content: "\e620"
}

.k-i-align-remove:before {
    content: "\e621"
}

.k-i-text-wrap:before {
    content: "\e622"
}

.k-i-rule-horizontal:before {
    content: "\e623"
}

.k-i-table-align-top-left:before {
    content: "\e624"
}

.k-i-table-align-top-center:before {
    content: "\e625"
}

.k-i-table-align-top-right:before {
    content: "\e626"
}

.k-i-table-align-middle-left:before {
    content: "\e627"
}

.k-i-table-align-middle-center:before {
    content: "\e628"
}

.k-i-table-align-middle-right:before {
    content: "\e629"
}

.k-i-table-align-bottom-left:before {
    content: "\e62a"
}

.k-i-table-align-bottom-center:before {
    content: "\e62b"
}

.k-i-table-align-bottom-right:before {
    content: "\e62c"
}

.k-i-table-align-remove:before {
    content: "\e62d"
}

.k-i-borders-all:before {
    content: "\e62e"
}

.k-i-all-borders:before {
    content: "\e62e"
}

.k-i-borders-outside:before {
    content: "\e62f"
}

.k-i-outside-borders:before {
    content: "\e62f"
}

.k-i-borders-inside:before {
    content: "\e630"
}

.k-i-inside-borders:before {
    content: "\e630"
}

.k-i-borders-inside-horizontal:before {
    content: "\e631"
}

.k-i-inside-horizontal-borders:before {
    content: "\e631"
}

.k-i-borders-inside-vertical:before {
    content: "\e632"
}

.k-i-inside-vertical-borders:before {
    content: "\e632"
}

.k-i-border-top:before {
    content: "\e633"
}

.k-i-top-border:before {
    content: "\e633"
}

.k-i-border-bottom:before {
    content: "\e634"
}

.k-i-bottom-border:before {
    content: "\e634"
}

.k-i-border-left:before {
    content: "\e635"
}

.k-i-left-border:before {
    content: "\e635"
}

.k-i-border-right:before {
    content: "\e636"
}

.k-i-right-border:before {
    content: "\e636"
}

.k-i-border-no:before {
    content: "\e637"
}

.k-i-no-borders:before {
    content: "\e637"
}

.k-i-borders-show-hide:before {
    content: "\e638"
}

.k-i-form:before {
    content: "\e639"
}

.k-i-border:before {
    content: "\e639"
}

.k-i-form-element:before {
    content: "\e63a"
}

.k-i-code-snippet:before {
    content: "\e63b"
}

.k-i-select-all:before {
    content: "\e63c"
}

.k-i-button:before {
    content: "\e63d"
}

.k-i-select-box:before {
    content: "\e63e"
}

.k-i-calendar-date:before {
    content: "\e63f"
}

.k-i-group-box:before {
    content: "\e640"
}

.k-i-textarea:before {
    content: "\e641"
}

.k-i-textbox:before {
    content: "\e642"
}

.k-i-textbox-hidden:before {
    content: "\e643"
}

.k-i-password:before {
    content: "\e644"
}

.k-i-paragraph-add:before {
    content: "\e645"
}

.k-i-edit-tools:before {
    content: "\e646"
}

.k-i-template-manager:before {
    content: "\e647"
}

.k-i-change-manually:before {
    content: "\e648"
}

.k-i-track-changes:before {
    content: "\e649"
}

.k-i-track-changes-enable:before {
    content: "\e64a"
}

.k-i-track-changes-accept:before {
    content: "\e64b"
}

.k-i-track-changes-accept-all:before {
    content: "\e64c"
}

.k-i-track-changes-reject:before {
    content: "\e64d"
}

.k-i-track-changes-reject-all:before {
    content: "\e64e"
}

.k-i-document-manager:before {
    content: "\e64f"
}

.k-i-custom-icon:before {
    content: "\e650"
}

.k-i-dictionary-add:before {
    content: "\e651"
}

.k-i-image-light-dialog:before {
    content: "\e652"
}

.k-i-image-insert:before {
    content: "\e652"
}

.k-i-image-edit:before {
    content: "\e653"
}

.k-i-image-map-editor:before {
    content: "\e654"
}

.k-i-comment:before {
    content: "\e655"
}

.k-i-comment-remove:before {
    content: "\e656"
}

.k-i-comments-remove-all:before {
    content: "\e657"
}

.k-i-silverlight:before {
    content: "\e658"
}

.k-i-media-manager:before {
    content: "\e659"
}

.k-i-video-external:before {
    content: "\e65a"
}

.k-i-flash-manager:before {
    content: "\e65b"
}

.k-i-find-and-replace:before {
    content: "\e65c"
}

.k-i-find:before {
    content: "\e65c"
}

.k-i-copy:before {
    content: "\e65d"
}

.k-i-files:before {
    content: "\e65d"
}

.k-i-cut:before {
    content: "\e65e"
}

.k-i-paste:before {
    content: "\e65f"
}

.k-i-paste-as-html:before {
    content: "\e660"
}

.k-i-paste-from-word:before {
    content: "\e661"
}

.k-i-paste-from-word-strip-file:before {
    content: "\e662"
}

.k-i-paste-html:before {
    content: "\e663"
}

.k-i-paste-markdown:before {
    content: "\e664"
}

.k-i-paste-plain-text:before {
    content: "\e665"
}

.k-i-apply-format:before {
    content: "\e666"
}

.k-i-clear-css:before {
    content: "\e667"
}

.k-i-copy-format:before {
    content: "\e668"
}

.k-i-strip-all-formating:before {
    content: "\e669"
}

.k-i-strip-css-format:before {
    content: "\e66a"
}

.k-i-strip-font-elements:before {
    content: "\e66b"
}

.k-i-strip-span-elements:before {
    content: "\e66c"
}

.k-i-strip-word-formatting:before {
    content: "\e66d"
}

.k-i-format-code-block:before {
    content: "\e66e"
}

.k-i-style-builder:before {
    content: "\e66f"
}

.k-i-module-manager:before {
    content: "\e670"
}

.k-i-hyperlink-light-dialog:before {
    content: "\e671"
}

.k-i-hyperlink-insert:before {
    content: "\e671"
}

.k-i-hyperlink-globe:before {
    content: "\e672"
}

.k-i-hyperlink-globe-remove:before {
    content: "\e673"
}

.k-i-hyperlink-email:before {
    content: "\e674"
}

.k-i-anchor:before {
    content: "\e675"
}

.k-i-table-light-dialog:before {
    content: "\e676"
}

.k-i-table-insert:before {
    content: "\e676"
}

.k-i-table:before {
    content: "\e677"
}

.k-i-table-properties:before {
    content: "\e678"
}

.k-i-table-wizard:before {
    content: "\e678"
}

.k-i-table-cell:before {
    content: "\e679"
}

.k-i-table-cell-properties:before {
    content: "\e67a"
}

.k-i-table-column-insert-left:before {
    content: "\e67b"
}

.k-i-table-column-insert-right:before {
    content: "\e67c"
}

.k-i-table-row-insert-above:before {
    content: "\e67d"
}

.k-i-table-row-insert-below:before {
    content: "\e67e"
}

.k-i-table-column-delete:before {
    content: "\e67f"
}

.k-i-table-row-delete:before {
    content: "\e680"
}

.k-i-table-cell-delete:before {
    content: "\e681"
}

.k-i-table-delete:before {
    content: "\e682"
}

.k-i-cells-merge:before {
    content: "\e683"
}

.k-i-cells-merge-horizontally:before {
    content: "\e684"
}

.k-i-cells-merge-vertically:before {
    content: "\e685"
}

.k-i-cell-split-horizontally:before {
    content: "\e686"
}

.k-i-cell-split-vertically:before {
    content: "\e687"
}

.k-i-table-unmerge:before {
    content: "\e688"
}

.k-i-pane-freeze:before {
    content: "\e689"
}

.k-i-row-freeze:before {
    content: "\e68a"
}

.k-i-column-freeze:before {
    content: "\e68b"
}

.k-i-toolbar-float:before {
    content: "\e68c"
}

.k-i-spell-checker:before {
    content: "\e68d"
}

.k-i-validation-xhtml:before {
    content: "\e68e"
}

.k-i-validation-data:before {
    content: "\e68f"
}

.k-i-toggle-full-screen-mode:before {
    content: "\e690"
}

.k-i-formula-fx:before {
    content: "\e691"
}

.k-i-sum:before {
    content: "\e692"
}

.k-i-symbol:before {
    content: "\e693"
}

.k-i-dollar:before {
    content: "\e694"
}

.k-i-currency:before {
    content: "\e694"
}

.k-i-percent:before {
    content: "\e695"
}

.k-i-custom-format:before {
    content: "\e696"
}

.k-i-decimal-increase:before {
    content: "\e697"
}

.k-i-decimal-decrease:before {
    content: "\e698"
}

.k-i-font-size:before {
    content: "\e699"
}

.k-i-image-absolute-position:before {
    content: "\e69a"
}

.k-i-globe-outline:before {
    content: "\e700"
}

.k-i-globe:before {
    content: "\e701"
}

.k-i-marker-pin:before {
    content: "\e702"
}

.k-i-marker-pin-target:before {
    content: "\e703"
}

.k-i-pin:before {
    content: "\e704"
}

.k-i-unpin:before {
    content: "\e705"
}

.k-i-share:before {
    content: "\e800"
}

.k-i-user:before {
    content: "\e801"
}

.k-i-inbox:before {
    content: "\e802"
}

.k-i-blogger:before {
    content: "\e803"
}

.k-i-blogger-box:before {
    content: "\e804"
}

.k-i-delicious:before {
    content: "\e805"
}

.k-i-delicious-box:before {
    content: "\e806"
}

.k-i-digg:before {
    content: "\e807"
}

.k-i-digg-box:before {
    content: "\e808"
}

.k-i-email:before {
    content: "\e809"
}

.k-i-envelop:before {
    content: "\e809"
}

.k-i-letter:before {
    content: "\e809"
}

.k-i-email-box:before {
    content: "\e80a"
}

.k-i-envelop-box:before {
    content: "\e80a"
}

.k-i-letter-box:before {
    content: "\e80a"
}

.k-i-facebook:before {
    content: "\e80b"
}

.k-i-facebook-box:before {
    content: "\e80c"
}

.k-i-google:before {
    content: "\e80d"
}

.k-i-google-box:before {
    content: "\e80e"
}

.k-i-google-plus:before {
    content: "\e80f"
}

.k-i-google-plus-box:before {
    content: "\e810"
}

.k-i-linkedin:before {
    content: "\e811"
}

.k-i-linkedin-box:before {
    content: "\e812"
}

.k-i-myspace:before {
    content: "\e813"
}

.k-i-myspace-box:before {
    content: "\e814"
}

.k-i-pinterest:before {
    content: "\e815"
}

.k-i-pinterest-box:before {
    content: "\e816"
}

.k-i-reddit:before {
    content: "\e817"
}

.k-i-reddit-box:before {
    content: "\e818"
}

.k-i-stumble-upon:before {
    content: "\e819"
}

.k-i-stumble-upon-box:before {
    content: "\e81a"
}

.k-i-tell-a-friend:before {
    content: "\e81b"
}

.k-i-tell-a-friend-box:before {
    content: "\e81c"
}

.k-i-tumblr:before {
    content: "\e81d"
}

.k-i-tumblr-box:before {
    content: "\e81e"
}

.k-i-twitter:before {
    content: "\e81f"
}

.k-i-twitter-box:before {
    content: "\e820"
}

.k-i-yammer:before {
    content: "\e821"
}

.k-i-yammer-box:before {
    content: "\e822"
}

.k-i-behance:before {
    content: "\e823"
}

.k-i-behance-box:before {
    content: "\e824"
}

.k-i-dribbble:before {
    content: "\e825"
}

.k-i-dribbble-box:before {
    content: "\e826"
}

.k-i-rss:before {
    content: "\e827"
}

.k-i-rss-box:before {
    content: "\e828"
}

.k-i-vimeo:before {
    content: "\e829"
}

.k-i-vimeo-box:before {
    content: "\e82a"
}

.k-i-youtube:before {
    content: "\e82b"
}

.k-i-youtube-box:before {
    content: "\e82c"
}

.k-i-folder:before {
    content: "\e900"
}

.k-i-folder-open:before {
    content: "\e901"
}

.k-i-folder-add:before {
    content: "\e902"
}

.k-i-folder-up:before {
    content: "\e903"
}

.k-i-folder-more:before {
    content: "\e904"
}

.k-i-fields-more:before {
    content: "\e904"
}

.k-i-aggregate-fields:before {
    content: "\e905"
}

.k-i-file:before {
    content: "\e906"
}

.k-i-file-vertical:before {
    content: "\e906"
}

.k-i-file-add:before {
    content: "\e907"
}

.k-i-file-txt:before {
    content: "\e908"
}

.k-i-txt:before {
    content: "\e908"
}

.k-i-file-csv:before {
    content: "\e909"
}

.k-i-csv:before {
    content: "\e909"
}

.k-i-file-excel:before {
    content: "\e90a"
}

.k-i-file-xls:before {
    content: "\e90a"
}

.k-i-excel:before {
    content: "\e90a"
}

.k-i-xls:before {
    content: "\e90a"
}

.k-i-file-word:before {
    content: "\e90b"
}

.k-i-file-doc:before {
    content: "\e90b"
}

.k-i-word:before {
    content: "\e90b"
}

.k-i-doc:before {
    content: "\e90b"
}

.k-i-file-mdb:before {
    content: "\e90c"
}

.k-i-mdb:before {
    content: "\e90c"
}

.k-i-file-ppt:before {
    content: "\e90d"
}

.k-i-ppt:before {
    content: "\e90d"
}

.k-i-file-pdf:before {
    content: "\e90e"
}

.k-i-pdf:before {
    content: "\e90e"
}

.k-i-file-psd:before {
    content: "\e90f"
}

.k-i-psd:before {
    content: "\e90f"
}

.k-i-file-flash:before {
    content: "\e910"
}

.k-i-flash:before {
    content: "\e910"
}

.k-i-file-config:before {
    content: "\e911"
}

.k-i-config:before {
    content: "\e911"
}

.k-i-file-ascx:before {
    content: "\e912"
}

.k-i-ascx:before {
    content: "\e912"
}

.k-i-file-bac:before {
    content: "\e913"
}

.k-i-bac:before {
    content: "\e913"
}

.k-i-file-zip:before {
    content: "\e914"
}

.k-i-zip:before {
    content: "\e914"
}

.k-i-film:before {
    content: "\e915"
}

.k-i-css3:before {
    content: "\e916"
}

.k-i-html5:before {
    content: "\e917"
}

.k-i-html:before {
    content: "\e918"
}

.k-i-source-code:before {
    content: "\e918"
}

.k-i-view-source:before {
    content: "\e918"
}

.k-i-css:before {
    content: "\e919"
}

.k-i-js:before {
    content: "\e91a"
}

.k-i-exe:before {
    content: "\e91b"
}

.k-i-csproj:before {
    content: "\e91c"
}

.k-i-vbproj:before {
    content: "\e91d"
}

.k-i-cs:before {
    content: "\e91e"
}

.k-i-vb:before {
    content: "\e91f"
}

.k-i-sln:before {
    content: "\e920"
}

.k-i-cloud:before {
    content: "\e921"
}

.k-i-file-horizontal:before {
    content: "\e922"
}

.k-i-equal:before {
    content: "\e14a"
}

.k-i-not-equal:before {
    content: "\e14b"
}

.k-i-less-or-equal:before {
    content: "\e14c"
}

.k-i-greater-or-equal:before {
    content: "\e14d"
}

.k-i-divide:before {
    content: "\e14e"
}

.k-i-accessibility:before {
    content: "\e14f"
}

.k-i-barcode-outline:before {
    content: "\e150"
}

.k-i-barcode:before {
    content: "\e151"
}

.k-i-barcode-scanner:before {
    content: "\e152"
}

.k-i-qr-code-outline:before {
    content: "\e153"
}

.k-i-qr-code:before {
    content: "\e154"
}

.k-i-qr-code-scanner:before {
    content: "\e155"
}

.k-i-barcode-qr-code-scanner:before {
    content: "\e156"
}

.k-i-signature:before {
    content: "\e157"
}

.k-i-hand:before {
    content: "\e158"
}

.k-i-cursor:before {
    content: "\e159"
}

.k-i-attachment-45:before {
    content: "\e10e"
}

.k-i-images:before {
    content: "\e52b"
}

.k-i-strip-all-formatting:before {
    content: "\e669"
}

.k-i-home:before {
    content: "\e034"
}

.k-i-overlap:before {
    content: "\e033"
}

.k-i-align-to-grid:before {
    content: "\e52c"
}

.k-i-size-to-grid:before {
    content: "\e52d"
}

.k-i-make-same-size:before {
    content: "\e52e"
}

.k-i-make-same-width:before {
    content: "\e52f"
}

.k-i-make-same-height:before {
    content: "\e530"
}

.k-i-make-horizontal-spacing-equal:before {
    content: "\e531"
}

.k-i-increase-horizontal-spacing:before {
    content: "\e532"
}

.k-i-decrease-horizontal-spacing:before {
    content: "\e533"
}

.k-i-remove-horizontal-spacing:before {
    content: "\e534"
}

.k-i-make-vertical-spacing-equal:before {
    content: "\e535"
}

.k-i-increase-vertical-spacing:before {
    content: "\e536"
}

.k-i-decrease-vertical-spacing:before {
    content: "\e537"
}

.k-i-remove-vertical-spacing:before {
    content: "\e538"
}

.k-i-eyedropper:before {
    content: "\e539"
}

.k-i-snap-grid:before {
    content: "\e53a"
}

.k-i-snap-to-gridlines:before {
    content: "\e53b"
}

.k-i-snap-to-snaplines:before {
    content: "\e53c"
}

.k-i-dimensions:before {
    content: "\e53d"
}

.k-i-crosstab:before {
    content: "\e69c"
}

.k-i-table-body:before {
    content: "\e69e"
}

.k-i-table-column-groups:before {
    content: "\e69f"
}

.k-i-table-corner:before {
    content: "\e6a0"
}

.k-i-table-row-groups:before {
    content: "\e6a1"
}

.k-i-subreport:before {
    content: "\e923"
}

.k-i-data:before {
    content: "\e924"
}

.k-i-report-header-section:before {
    content: "\e925"
}

.k-i-report-footer-section:before {
    content: "\e926"
}

.k-i-group-header-section:before {
    content: "\e927"
}

.k-i-group-footer-section:before {
    content: "\e928"
}

.k-i-page-header-section:before {
    content: "\e929"
}

.k-i-page-footer-section:before {
    content: "\e92a"
}

.k-i-detail-section:before {
    content: "\e92b"
}

.k-i-toc-section:before {
    content: "\e92c"
}

.k-i-group-section:before {
    content: "\e92d"
}

.k-i-parameters:before {
    content: "\e92e"
}

.k-i-data-csv:before {
    content: "\e92f"
}

.k-i-data-json:before {
    content: "\e930"
}

.k-i-data-sql:before {
    content: "\e931"
}

.k-i-data-web:before {
    content: "\e932"
}

.k-i-group-collection:before {
    content: "\e933"
}

.k-i-parameter-boolean:before {
    content: "\e934"
}

.k-i-parameter-date-time:before {
    content: "\e935"
}

.k-i-parameter-float:before {
    content: "\e936"
}

.k-i-parameter-integer:before {
    content: "\e937"
}

.k-i-parameter-string:before {
    content: "\e938"
}

.k-i-toc-section-level:before {
    content: "\e939"
}

.k-i-inherited:before {
    content: "\e93a"
}

.k-i-file-video:before {
    content: "\e93b"
}

.k-i-file-audio:before {
    content: "\e93c"
}

.k-i-file-image:before {
    content: "\e93d"
}

.k-i-file-presentation:before {
    content: "\e93e"
}

.k-i-file-data:before {
    content: "\e93f"
}

.k-i-file-disc-image:before {
    content: "\e940"
}

.k-i-file-programming:before {
    content: "\e941"
}

.k-i-graph:before {
    content: "\ea00"
}

.k-i-column-clustered:before {
    content: "\ea01"
}

.k-i-column-stacked:before {
    content: "\ea02"
}

.k-i-column-stacked100:before {
    content: "\ea03"
}

.k-i-column-range:before {
    content: "\ea04"
}

.k-i-bar-clustered:before {
    content: "\ea05"
}

.k-i-bar-stacked:before {
    content: "\ea06"
}

.k-i-bar-stacked100:before {
    content: "\ea07"
}

.k-i-bar-range:before {
    content: "\ea08"
}

.k-i-area-clustered:before {
    content: "\ea09"
}

.k-i-area-stacked:before {
    content: "\ea0a"
}

.k-i-area-stacked100:before {
    content: "\ea0b"
}

.k-i-area-range:before {
    content: "\ea0c"
}

.k-i-line-stacked:before {
    content: "\ea0e"
}

.k-i-line-stacked100:before {
    content: "\ea0f"
}

.k-i-line-markers:before {
    content: "\ea10"
}

.k-i-line-stacked-markers:before {
    content: "\ea11"
}

.k-i-line-stacked100-markers:before {
    content: "\ea12"
}

.k-i-pie:before {
    content: "\ea13"
}

.k-i-doughnut:before {
    content: "\ea14"
}

.k-i-scatter:before {
    content: "\ea15"
}

.k-i-scatter-smooth-lines-markers:before {
    content: "\ea16"
}

.k-i-scatter-smooth-lines:before {
    content: "\ea17"
}

.k-i-scatter-straight-lines-markers:before {
    content: "\ea18"
}

.k-i-scatter-straight-lines:before {
    content: "\e6a19"
}

.k-i-bubble:before {
    content: "\ea1a"
}

.k-i-candlestick:before {
    content: "\ea1b"
}

.k-i-bar:before {
    content: "\ea1c"
}

.k-i-radar:before {
    content: "\ea1d"
}

.k-i-radar-markers:before {
    content: "\ea1e"
}

.k-i-radar-filled:before {
    content: "\ea1f"
}

.k-i-rose:before {
    content: "\ea20"
}

.k-i-choropleth:before {
    content: "\ea21"
}

.k-i-page-portrait::before {
    content: "\e906"
}

.k-i-page-landscape::before {
    content: "\e922"
}

.k-rtl .k-i-collapse, .k-rtl .k-i-expand, .k-rtl .k-i-indent-decrease, .k-rtl .k-i-indent-increase {
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1)
}

.k-spreadsheet {
    width: 800px;
    height: 600px;
    position: relative;
    border-width: 1px;
    border-style: solid;
    cursor: default
}

    .k-spreadsheet .k-spreadsheet-pane {
        padding-right: 1px;
        position: absolute;
        border-width: 1px;
        border-bottom-width: 0;
        border-right-width: 0;
        border-style: solid;
        box-sizing: border-box;
        overflow: hidden
    }

        .k-spreadsheet .k-spreadsheet-pane.k-top {
            border-top-width: 0
        }

        .k-spreadsheet .k-spreadsheet-pane.k-left {
            border-left-width: 0
        }

        .k-spreadsheet .k-spreadsheet-pane .k-spreadsheet-cell {
            position: absolute;
            white-space: pre;
            box-sizing: border-box;
            overflow: hidden;
            padding: 1px 3px;
            background-clip: padding-box
        }

            .k-spreadsheet .k-spreadsheet-pane .k-spreadsheet-cell.k-spreadsheet-has-comment::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                left: auto;
                border-width: 3px;
                border-style: solid
            }

        .k-spreadsheet .k-spreadsheet-pane .k-spreadsheet-cell-comment {
            position: absolute;
            white-space: pre-wrap;
            text-align: left;
            border-width: 1px;
            border-style: solid
        }

    .k-spreadsheet .k-spreadsheet-haxis, .k-spreadsheet .k-spreadsheet-vaxis {
        position: absolute;
        border-style: solid
    }

    .k-spreadsheet .k-spreadsheet-vaxis {
        top: 0;
        border-width: 0 0 0 1px
    }

    .k-spreadsheet .k-spreadsheet-haxis {
        left: 0;
        border-width: 1px 0 0
    }

    .k-spreadsheet .k-filter-range, .k-spreadsheet .k-spreadsheet-selection {
        border-width: 1px;
        border-style: solid;
        position: absolute;
        box-sizing: border-box
    }

    .k-spreadsheet .k-spreadsheet-active-cell {
        position: absolute;
        outline-color: transparent !important;
        box-sizing: border-box;
        z-index: 10
    }

    .k-spreadsheet .k-spreadsheet-action-bar {
        border-width: 0 0 1px;
        border-style: solid;
        position: relative;
        display: table;
        width: 100%
    }

        .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar, .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor {
            display: table-cell;
            vertical-align: middle
        }

        .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor {
            position: relative;
            vertical-align: top;
            border-width: 0 1px 0 0;
            border-style: solid
        }

            .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox {
                width: 9.5em;
                border-radius: 0;
                border-color: transparent
            }

                .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox .k-dropdown-wrap {
                    border-radius: 0;
                    border-color: transparent;
                    background-image: none;
                    box-shadow: none;
                    border-width: 0
                }

                    .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox .k-dropdown-wrap .k-input {
                        border-radius: 0
                    }

                    .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox .k-dropdown-wrap .k-select {
                        border-radius: 0
                    }

        .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar {
            width: 100%;
            padding-left: 31px
        }

            .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar::before {
                position: relative;
                display: inline-block;
                overflow: hidden;
                width: 1em;
                height: 1em;
                text-align: center;
                vertical-align: middle;
                background-image: none;
                font: 16px/1 WebComponentsIcons;
                speak: none;
                font-variant: normal;
                text-transform: none;
                text-indent: 0;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\e691";
                display: block;
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                border-width: 0 1px 0 0;
                border-style: solid;
                width: 24px;
                margin-left: -24px
            }

            .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar .k-spreadsheet-formula-input.k-spreadsheet-array-formula:before {
                content: "{";
                font-weight: 700
            }

            .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar .k-spreadsheet-formula-input.k-spreadsheet-array-formula:after {
                content: "}";
                font-weight: 700
            }

        .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-input {
            overflow: hidden;
            text-indent: .571em;
            border: 0;
            margin: 0
        }

        .k-spreadsheet .k-spreadsheet-action-bar .k-state-border-down {
            padding-bottom: 0
        }

    .k-spreadsheet .k-spreadsheet-formula-input {
        outline: 0;
        border-width: 0;
        height: 26px;
        line-height: 20px;
        width: 100%;
        box-sizing: border-box;
        white-space: pre;
        padding: 3px .5em
    }

    .k-spreadsheet .k-spreadsheet-cell-editor {
        position: absolute;
        display: none;
        padding: 0 3px;
        line-height: 20px;
        z-index: 2000;
        overflow: hidden
    }

    .k-spreadsheet > .k-spreadsheet-view {
        position: relative;
        font-size: 12px;
        font-family: Arial,Verdana,sans-serif
    }

        .k-spreadsheet > .k-spreadsheet-view .k-state-disabled {
            pointer-events: auto
        }

    .k-spreadsheet .k-tabstrip-wrapper {
        position: relative;
        line-height: 1.7em
    }

        .k-spreadsheet .k-tabstrip-wrapper .k-tabstrip-items {
            padding: .3em 0 0
        }

    .k-spreadsheet .k-spreadsheet-quick-access-toolbar {
        display: inline-block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        padding: .3em .3em 0
    }

        .k-spreadsheet .k-spreadsheet-quick-access-toolbar .k-button {
            padding: .3em;
            line-height: 1.4em;
            height: auto
        }

    .k-spreadsheet .k-filter-wrapper, .k-spreadsheet .k-merged-cells-wrapper, .k-spreadsheet .k-selection-wrapper, .k-spreadsheet .k-spreadsheet-row-header {
        position: relative
    }

    .k-spreadsheet .k-spreadsheet-column-header {
        position: absolute
    }

    .k-spreadsheet .k-filter-wrapper {
        pointer-events: none;
        z-index: 50
    }

    .k-spreadsheet .k-spreadsheet-sheets-bar {
        border-width: 1px 0 0
    }

    .k-spreadsheet .k-vertical-align-center {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    .k-spreadsheet .k-vertical-align-bottom {
        position: relative;
        top: 100%;
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    .k-spreadsheet .k-dirty {
        position: absolute;
        top: 0;
        left: 0;
        right: unset
    }

    .k-spreadsheet .k-single-selection {
        cursor: crosshair
    }

        .k-spreadsheet .k-single-selection::after {
            content: " ";
            height: 6px;
            width: 6px;
            position: absolute;
            display: block;
            bottom: 0;
            right: 0;
            border-radius: 50%;
            margin-bottom: -4px;
            margin-right: -4px;
            border-width: 1px;
            border-style: solid;
            z-index: 100
        }

    .k-spreadsheet .k-spreadsheet-edit-container .k-single-selection::after {
        display: none
    }

    .k-spreadsheet .k-auto-fill, .k-spreadsheet .k-auto-fill-bl-hint, .k-spreadsheet .k-auto-fill-br-hint, .k-spreadsheet .k-auto-fill-punch, .k-spreadsheet .k-auto-fill-tr-hint {
        position: absolute;
        box-sizing: border-box
    }

    .k-spreadsheet .k-auto-fill {
        border-width: 1px;
        border-style: solid;
        cursor: crosshair
    }

    .k-spreadsheet .k-auto-fill-wrapper {
        position: relative
    }

    .k-spreadsheet .k-spreadsheet-vborder {
        position: absolute;
        border-left-style: solid;
        border-left-width: 1px
    }

    .k-spreadsheet .k-spreadsheet-hborder {
        position: absolute;
        border-top-style: solid;
        border-top-width: 1px
    }

.k-spreadsheet-names-popup .k-item {
    position: relative
}

.k-spreadsheet-names-popup .k-icon {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: .7;
    cursor: pointer
}

    .k-spreadsheet-names-popup .k-icon:hover {
        opacity: 1
    }

.k-horizontal-resize {
    cursor: col-resize
}

.k-vertical-resize {
    cursor: row-resize
}

.k-merged-cells-wrapper, .k-selection-wrapper, .k-spreadsheet-active-cell, .k-spreadsheet-data {
    cursor: cell
}

.k-horizontal-resize .k-merged-cells-wrapper, .k-horizontal-resize .k-selection-wrapper, .k-horizontal-resize .k-spreadsheet-active-cell, .k-horizontal-resize .k-spreadsheet-data {
    cursor: col-resize
}

.k-vertical-resize .k-merged-cells-wrapper, .k-vertical-resize .k-selection-wrapper, .k-vertical-resize .k-spreadsheet-active-cell, .k-vertical-resize .k-spreadsheet-data {
    cursor: row-resize
}

.k-spreadsheet .k-colorpicker .k-tool-icon {
    overflow: visible
}

.k-button.k-custom-color, .k-button.k-reset-color {
    display: block;
    width: 100%;
    border-radius: 0;
    border: none;
    text-align: left;
    line-height: 2em
}

.k-spreadsheet-colorpicker .k-colorpalette {
    border: 1px solid #dbdbdb;
    border-left: none;
    border-right: none;
    padding: .4em
}

.k-spreadsheet-filter {
    position: absolute;
    cursor: pointer;
    pointer-events: all
}

.k-spreadsheet-sample {
    float: right
}

.k-spreadsheet-clipboard, .k-spreadsheet-clipboard-paste {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border: 0;
    width: 1px;
    height: 1px;
    box-sizing: border-box
}

textarea.k-spreadsheet-clipboard {
    position: fixed
}

.k-spreadsheet-top-corner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    border-width: 0 1px 1px 0;
    border-style: solid
}

    .k-spreadsheet-top-corner:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        right: 0;
        border-width: 6px;
        border-style: solid
    }

.k-spreadsheet-scroller {
    width: 100%;
    height: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    z-index: 1
}

.k-spreadsheet-fixed-container {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 2;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden
}

.k-spreadsheet-view-size {
    position: relative
}

.k-spreadsheet-column-header, .k-spreadsheet-row-header {
    text-align: center;
    z-index: 100
}

    .k-spreadsheet-column-header .k-spreadsheet-cell, .k-spreadsheet-row-header .k-spreadsheet-cell {
        border-style: solid;
        border-width: 0 1px 1px 0
    }

div.k-spreadsheet-window {
    padding: 1.167em .75em .75em
}

.k-spreadsheet-window .k-edit-form-container {
    width: 100%
}

.k-spreadsheet-window .k-root-tabs {
    border-width: 0;
    margin: -1em -1em 0;
    padding: .5em 1em 0
}

    .k-spreadsheet-window .k-root-tabs .k-loading {
        display: none
    }

.k-spreadsheet-window .k-list-wrapper {
    padding: .15em 0;
    height: 190px
}

.k-spreadsheet-window .k-list .k-item {
    border-radius: 0;
    padding: .5em .6em .4em .6em;
    cursor: pointer;
    line-height: 1.1em
}

.k-spreadsheet-window .k-format-filter {
    width: 100%
}

    .k-spreadsheet-window .k-format-filter + .k-group-header + .k-list-wrapper {
        margin-top: 1em
    }

.k-spreadsheet-window .k-action-buttons {
    top: 10px;
    padding: 10px;
    margin-bottom: calc(10px - 1em)
}

.k-spreadsheet-window .k-edit-label {
    width: 25%;
    margin: 0 0 0 .75em
}

.k-spreadsheet-window .k-edit-field {
    width: 67%;
    margin: 0 .917em 0 0
}

.k-spreadsheet-window .k-edit-field, .k-spreadsheet-window .k-edit-label {
    padding: 0;
    line-height: 3em
}

    .k-spreadsheet-window .k-edit-field .k-dropdown, .k-spreadsheet-window .k-edit-field .k-textbox {
        width: 100%
    }

.k-spreadsheet-window .k-export-config .k-hr {
    margin-left: 15px;
    margin-right: 15px
}

.k-spreadsheet-window .k-export-config .k-edit-field {
    width: 40%;
    float: left;
    margin-left: 30px
}

.k-spreadsheet-window .k-page-orientation {
    position: absolute;
    right: 2em;
    top: 25%
}

    .k-spreadsheet-window .k-page-orientation .k-icon {
        font-size: 6em
    }

.k-spreadsheet-window .k-export-config:after, .k-spreadsheet-window > div:after {
    content: "";
    display: block;
    clear: both;
    height: 0
}

.k-spreadsheet-format-cells .k-spreadsheet-preview {
    font-weight: 700;
    text-align: center;
    font-size: 1.16em;
    line-height: 3.64em;
    margin: 0 -.863em .72em;
    border-style: solid;
    border-width: 1px 0
}

.k-spreadsheet-border-palette {
    width: 153px
}

    .k-spreadsheet-border-palette .k-spreadsheet-border-type-palette .k-button {
        box-sizing: border-box;
        width: 20%
    }

    .k-spreadsheet-border-palette .k-colorpalette {
        vertical-align: bottom
    }

.k-spreadsheet-popup .k-separator {
    width: 1px;
    height: 1.8em;
    vertical-align: middle;
    display: inline-block
}

.k-spreadsheet-popup .k-colorpalette {
    vertical-align: top
}

.k-spreadsheet-popup .k-button.k-button-icon {
    padding: .6em;
    border-width: 0;
    border-radius: 0
}

.k-spreadsheet-popup .k-button.k-button-icontext {
    display: block;
    text-align: left;
    text-transform: initial;
    padding: .3em 1.5em .3em .9em;
    border-width: 0;
    border-radius: 0
}

.k-spreadsheet-filter-menu {
    width: 280px
}

    .k-spreadsheet-filter-menu > .k-menu {
        border-width: 0
    }

        .k-spreadsheet-filter-menu > .k-menu .k-link {
            padding-left: 26px
        }

            .k-spreadsheet-filter-menu > .k-menu .k-link .k-icon {
                margin-left: -26px;
                width: 26px
            }

    .k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper {
        height: 200px;
        overflow-y: scroll;
        overflow-x: auto;
        border-width: 1px;
        border-style: solid
    }

        .k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper .k-treeview {
            overflow: visible;
            padding: 6px 7px
        }

    .k-spreadsheet-filter-menu .k-details {
        border-top-width: 1px;
        border-top-style: solid;
        padding: 4px 0
    }

    .k-spreadsheet-filter-menu .k-details-summary {
        cursor: pointer;
        line-height: 26px
    }

        .k-spreadsheet-filter-menu .k-details-summary .k-icon {
            margin: 0 5px
        }

    .k-spreadsheet-filter-menu .k-details-content {
        padding: 0 8px 0 26px
    }

        .k-spreadsheet-filter-menu .k-details-content > .k-textbox, .k-spreadsheet-filter-menu .k-details-content > .k-widget {
            width: 100%;
            margin-bottom: 3px
        }

        .k-spreadsheet-filter-menu .k-details-content .k-space-right {
            background-image: none
        }

        .k-spreadsheet-filter-menu .k-details-content .k-filter-and {
            width: 75px;
            margin: 8px 0
        }

    .k-spreadsheet-filter-menu .k-action-buttons {
        border-top-width: 0;
        margin: 8px;
        padding: 0;
        position: static
    }

.k-resize-handle, .k-resize-hint {
    position: absolute;
    z-index: 200
}

.k-resize-hint-handle {
    width: 100%;
    height: 20px
}

.k-resize-hint-marker {
    width: 2px;
    height: 100%;
    margin: 0 auto
}

.k-resize-hint-vertical .k-resize-hint-handle {
    height: 100%;
    width: 20px;
    float: left
}

.k-resize-hint-vertical .k-resize-hint-marker {
    height: 2px;
    width: 100%
}

.k-button.k-spreadsheet-sheets-bar-add, .k-spreadsheet-quick-access-toolbar .k-button {
    border-color: transparent;
    background-color: transparent;
    background-image: none
}

.k-spreadsheet-tabstrip {
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0
}

    .k-spreadsheet-tabstrip > .k-content {
        border-left-width: 0;
        border-right-width: 0;
        border-bottom-width: 0;
        margin: 0;
        padding: 0
    }

    .k-spreadsheet-tabstrip .k-loading {
        display: none
    }

.k-spreadsheet-toolbar.k-toolbar {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0
}

    .k-spreadsheet-toolbar.k-toolbar .k-overflow-anchor + * {
        margin-left: .3em
    }

    .k-spreadsheet-toolbar.k-toolbar > .k-button-group {
        border-width: 0
    }

.k-spreadsheet-toolbar .k-button, .k-spreadsheet-toolbar > .k-button-group, .k-spreadsheet-toolbar > .k-widget, .k-spreadsheet-toolbar > .k-widget .k-state-default:not(.k-state-hover):not(.k-state-active) {
    margin: 0;
    border-color: transparent;
    background-color: transparent;
    background-image: none
}

    .k-spreadsheet-toolbar > .k-widget[data-property=fontSize] {
        width: 75px
    }

    .k-spreadsheet-toolbar > .k-widget[data-property=format] {
        width: 100px
    }

    .k-spreadsheet-toolbar > .k-widget[data-property=fontFamily] {
        width: 130px
    }

.k-spreadsheet-toolbar > .k-combobox .k-state-default:not(.k-state-hover):not(.k-state-active) .k-input {
    background-color: transparent;
    box-shadow: none
}

.k-spreadsheet-toolbar > .k-widget .k-state-default:not(.k-state-hover):not(.k-state-active) .k-select {
    border-color: transparent
}

.k-spreadsheet-toolbar [data-tool=alignment], .k-spreadsheet-toolbar [data-tool=backgroundColor], .k-spreadsheet-toolbar [data-tool=borders], .k-spreadsheet-toolbar [data-tool=freeze], .k-spreadsheet-toolbar [data-tool=merge], .k-spreadsheet-toolbar [data-tool=textColor] {
    width: auto
}

.k-spreadsheet-toolbar .k-separator {
    margin: 0 3px;
    width: 0;
    overflow: hidden;
    height: 1.8em;
    vertical-align: middle;
    display: inline-block
}

.k-spreadsheet-formula-input > .k-syntax-bool.k-syntax-at-point, .k-spreadsheet-formula-input > .k-syntax-func.k-syntax-at-point, .k-spreadsheet-formula-input > .k-syntax-num.k-syntax-at-point, .k-spreadsheet-formula-input > .k-syntax-ref.k-syntax-at-point, .k-spreadsheet-formula-input > .k-syntax-str.k-syntax-at-point {
    text-decoration: underline
}

.k-spreadsheet-formula-input > .k-series-a, .k-spreadsheet-formula-input > .k-series-b, .k-spreadsheet-formula-input > .k-series-c, .k-spreadsheet-formula-input > .k-series-d {
    background-color: transparent
}

.k-spreadsheet-selection-highlight {
    border-width: 1px;
    border-style: solid;
    position: absolute;
    box-sizing: border-box
}

.k-spreadsheet-formula-list {
    min-width: 100px
}

    .k-spreadsheet-formula-list .k-item {
        padding: 0 .3em;
        box-shadow: none
    }

.k-spreadsheet-sheets-bar {
    position: relative
}

.k-button.k-spreadsheet-sheets-bar-add {
    position: absolute;
    bottom: .2em;
    left: .4em;
    z-index: 1
}

.k-spreadsheet-sheets-bar .k-spreadsheet-sheets-remove {
    padding: 0;
    margin: .5em .5em .5em -.5em;
    vertical-align: middle
}

    .k-spreadsheet-sheets-bar .k-spreadsheet-sheets-remove .k-button-icon {
        padding: 2px;
        line-height: normal
    }

    .k-spreadsheet-sheets-bar .k-spreadsheet-sheets-remove .k-icon {
        margin: 0
    }

.k-spreadsheet-sheets-items-hint {
    margin-top: .1em;
    background: 0 0 !important;
    border-width: 0 !important
}

.k-spreadsheet-editor-button {
    position: absolute;
    width: 20px;
    z-index: 10;
    margin-left: 2px;
    padding: 0 !important
}

    .k-spreadsheet-editor-button.k-spreadsheet-last-column {
        margin-left: -21px
    }

    .k-spreadsheet-editor-button span {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%)
    }

.hint-wrapper {
    margin-top: 1em
}

.k-edit-form-container > div:after {
    display: block;
    content: "";
    clear: both
}

.k-spreadsheet-filter-menu .k-i-arrow-45-down-right {
    cursor: pointer
}

.k-spreadsheet-import-errors .k--header-message {
    font-weight: 700;
    padding-bottom: 5px;
    border-bottom: 1px solid #888;
    margin-bottom: 5px
}

.k-spreadsheet-import-errors .k--errors {
    max-height: 350px;
    overflow: auto
}

    .k-spreadsheet-import-errors .k--errors table {
        border-collapse: collapse;
        font-size: 90%
    }

        .k-spreadsheet-import-errors .k--errors table td, .k-spreadsheet-import-errors .k--errors table th {
            padding: 2px 4px;
            border-bottom: 1px solid #bbb
        }

        .k-spreadsheet-import-errors .k--errors table th {
            text-align: left;
            border-bottom: 1px solid #000
        }

.k-spreadsheet-insert-image-dialog {
    margin: 0 1em;
    border-style: dashed;
    border-width: 2px
}

    .k-spreadsheet-insert-image-dialog label {
        position: relative;
        display: block;
        width: 355px;
        height: 200px;
        padding: 10px;
        background-image: url(textures/image-default.png);
        background-size: auto 70%;
        background-repeat: no-repeat;
        background-position: 50% 30%
    }

        .k-spreadsheet-insert-image-dialog label div {
            position: relative;
            top: 75%;
            padding: 10px;
            text-align: center;
            pointer-events: none
        }

        .k-spreadsheet-insert-image-dialog label input {
            display: none
        }

    .k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image {
        background-size: auto 90%;
        background-position: 50% 50%
    }

        .k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image.k-state-hovered div, .k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image:hover div {
            opacity: 1;
            top: 50%
        }

        .k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image div {
            opacity: 0
        }

.k-spreadsheet-drawing {
    position: absolute;
    box-sizing: border-box;
    z-index: 101
}

    .k-spreadsheet-drawing.k-spreadsheet-active-drawing {
        outline-style: solid;
        outline-width: 2px
    }

    .k-spreadsheet-drawing .k-spreadsheet-drawing-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: 50% 50%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: move
    }

    .k-spreadsheet-drawing .k-spreadsheet-drawing-handle {
        position: absolute;
        width: 6px;
        height: 6px;
        border-style: solid;
        border-width: 1px;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%)
    }

        .k-spreadsheet-drawing .k-spreadsheet-drawing-handle.N {
            left: 50%;
            top: 0;
            cursor: ns-resize
        }

        .k-spreadsheet-drawing .k-spreadsheet-drawing-handle.NE {
            left: 100%;
            top: 0;
            cursor: nesw-resize
        }

        .k-spreadsheet-drawing .k-spreadsheet-drawing-handle.E {
            left: 100%;
            top: 50%;
            cursor: ew-resize
        }

        .k-spreadsheet-drawing .k-spreadsheet-drawing-handle.SE {
            left: 100%;
            top: 100%;
            cursor: nwse-resize
        }

        .k-spreadsheet-drawing .k-spreadsheet-drawing-handle.S {
            left: 50%;
            top: 100%;
            cursor: ns-resize
        }

        .k-spreadsheet-drawing .k-spreadsheet-drawing-handle.SW {
            left: 0;
            top: 100%;
            cursor: nesw-resize
        }

        .k-spreadsheet-drawing .k-spreadsheet-drawing-handle.W {
            left: 0;
            top: 50%;
            cursor: ew-resize
        }

        .k-spreadsheet-drawing .k-spreadsheet-drawing-handle.NW {
            left: 0;
            top: 0;
            cursor: nwse-resize
        }

.k-dialog {
    min-width: 90px;
    min-height: 3em;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    position: fixed;
    overflow: hidden
}

    .k-dialog.k-dialog-centered {
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%
    }

.k-dialog-titlebar {
    padding: 12px
}

.k-dialog-title {
    font-size: 1.4em
}

.k-dialog-titlebar .k-dialog-actions {
    top: 9px
}

.k-dialog-titleless .k-dialog-titlebar .k-dialog-close {
    position: absolute;
    top: 4px;
    right: 6px
}

.k-dialog .k-content {
    padding: 21px 12px;
    height: auto
}

.k-dialog.k-dialog-titleless .k-content {
    padding: 2.4em
}

.k-dialog-buttongroup {
    margin: 0;
    padding: 12px 12px;
    width: 100%;
    border-radius: 0;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end
}

    .k-dialog-buttongroup .k-button {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        display: inline-block;
        text-overflow: ellipsis;
        box-sizing: border-box
    }

        .k-dialog-buttongroup .k-button + .k-button {
            margin-left: .5em
        }

    .k-dialog-buttongroup.k-dialog-button-layout-stretched {
        padding: 0
    }

        .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button {
            margin: 0;
            border-radius: 0;
            border-bottom: 0;
            border-width: 1px 0 0 1px;
            -ms-flex: 1 0 0px;
            flex: 1 0 0;
            white-space: nowrap
        }

            .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button:first-child {
                border-radius: 0;
                border-left-width: 0
            }

            .k-dialog-buttongroup.k-dialog-button-layout-stretched .k-button:last-child {
                border-radius: 0;
                border-right-width: 0
            }

.k-no-flexbox .k-dialog-buttongroup {
    display: block;
    text-align: right
}

.k-no-flexbox .k-rtl .k-dialog-button-group {
    text-align: left
}

.k-dialog.k-alert .k-dialog-titlebar, .k-dialog.k-confirm .k-dialog-titlebar, .k-dialog.k-prompt .k-dialog-titlebar {
    border-width: 0;
    color: inherit;
    background: 0 0
}

.k-dialog.k-alert .k-dialog-content, .k-dialog.k-confirm .k-dialog-content, .k-dialog.k-prompt .k-dialog-content {
    padding: 24px 24px
}

.k-dialog.k-prompt .k-dialog-content, .k-dialog.k-prompt .k-prompt-container {
    padding: 12px 24px
}

.k-card {
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative
}

    .k-card .k-card-callout {
        position: absolute;
        width: 20px;
        height: 20px;
        border-width: 1px 1px 0 0;
        border-style: solid;
        margin: 0
    }

        .k-card .k-card-callout.k-callout-n {
            top: 0;
            -webkit-transform: translate(-50%,-50%) rotate(-45deg);
            -ms-transform: translate(-50%,-50%) rotate(-45deg);
            transform: translate(-50%,-50%) rotate(-45deg);
            left: 50%
        }

        .k-card .k-card-callout.k-callout-e {
            top: 50%;
            left: auto;
            right: 0;
            -webkit-transform: translate(50%,-50%) rotate(135deg) scaleX(-1);
            -ms-transform: translate(50%,-50%) rotate(135deg) scaleX(-1);
            transform: translate(50%,-50%) rotate(135deg) scaleX(-1)
        }

        .k-card .k-card-callout.k-callout-s {
            top: auto;
            bottom: 0;
            -webkit-transform: translate(-50%,50%) rotate(135deg);
            -ms-transform: translate(-50%,50%) rotate(135deg);
            transform: translate(-50%,50%) rotate(135deg);
            left: 50%
        }

        .k-card .k-card-callout.k-callout-w {
            top: 50%;
            left: 0;
            -webkit-transform: translate(-50%,-50%) rotate(-135deg);
            -ms-transform: translate(-50%,-50%) rotate(-135deg);
            transform: translate(-50%,-50%) rotate(-135deg)
        }

    .k-card.k-card-flat {
        box-shadow: none
    }

.k-card-horizontal {
    -ms-flex-direction: row;
    flex-direction: row
}

.k-card-header {
    padding: 12px 16px;
    border-width: 0 0 1px;
    border-style: solid;
    overflow: hidden;
    position: relative;
    z-index: 1
}

.k-card > .k-card-header:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.k-card > .k-card-header:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.k-card-header > h1, .k-card-header > h2, .k-card-header > h3, .k-card-header > h4, .k-card-header > h5, .k-card-header > h6 {
    margin: 0
}

.k-card-header .k-card-title + .k-card-subtitle {
    margin-top: 0
}

.k-card-footer {
    padding: 12px 16px;
    border-width: 1px 0 0;
    border-style: solid;
    display: block
}

.k-card-body {
    padding: 12px 16px;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.k-card > .k-card-body:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.k-card > .k-card-body:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.k-card-body p {
    margin: 0 0 8px
}

.k-card-body > .k-last, .k-card-body > :last-child {
    margin-bottom: 0
}

.k-card-image, .k-card-media {
    border: 0;
    max-width: 100%;
    overflow: hidden
}

.k-card > .k-card-image:first-child, .k-card > .k-card-media:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.k-card > .k-card-image:last-child, .k-card > .k-card-media:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.k-card-image > img, .k-card-media > img {
    border: 0;
    max-width: 100%
}

.k-card-horizontal .k-card-image, .k-card-horizontal .k-card-media {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    max-width: 100px;
    object-fit: cover
}

.k-card-title {
    font-size: 19px;
    margin: 0 0 8px
}

.k-card-subtitle {
    font-size: 12px;
    margin: 0 0 8px;
    opacity: .6
}

.k-card-title + .k-card-subtitle {
    margin-top: -6px
}

.k-card-separator {
    margin: 0;
    border-width: 1px 0 0;
    border-style: solid;
    display: block;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

    .k-card-separator.k-separator-vertical {
        width: 0;
        border-width: 0 0 0 1px
    }

.k-card > .k-hr {
    margin: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-color: inherit
}

.k-card-actions {
    padding: 8px 16px;
    border-width: 0;
    border-style: solid;
    border-color: inherit;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

.k-card > .k-card-actions:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.k-card > .k-card-actions:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.k-card > .k-card-actions {
    border-top-width: 1px;
    border-color: inherit
}

.k-card-horizontal .k-card-actions, .k-card-vertical .k-card-actions {
    border: 0
}

.k-card-actions .k-button {
    max-width: 100%;
    white-space: normal
}

.k-card-actions > .k-button.k-flat:first-child {
    margin-left: -8px
}

.k-card-actions.k-card-actions-start {
    -ms-flex-pack: start;
    justify-content: flex-start
}

.k-card-actions.k-card-actions-end {
    -ms-flex-pack: end;
    justify-content: flex-end
}

.k-card-actions.k-card-actions-center {
    -ms-flex-pack: center;
    justify-content: center
}

.k-card-action {
    border-width: 0;
    border-style: solid;
    border-color: inherit;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

    .k-card-action > .k-button {
        border-radius: 0;
        padding: 16px 16px;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto
    }

.k-card-actions-vertical {
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

    .k-card-actions-vertical .k-button {
        padding: 12px 16px
    }

        .k-card-actions-vertical .k-button.k-flat:first-child {
            margin-left: 0
        }

    .k-card-actions-vertical .k-card-action + .k-card-action {
        border-top-width: 1px
    }

.k-card-horizontal .k-card-actions-vertical {
    border-top-width: 0;
    border-left-width: 1px
}

.k-card-vertical .k-card-actions-vertical.k-card-actions-start {
    -ms-flex-align: start;
    align-items: flex-start
}

.k-card-vertical .k-card-actions-vertical.k-card-actions-end {
    -ms-flex-align: end;
    align-items: flex-end
}

.k-card-vertical .k-card-actions-vertical.k-card-actions-center {
    -ms-flex-align: center;
    align-items: center
}

.k-card-vertical .k-card-actions-vertical.k-card-actions-center, .k-card-vertical .k-card-actions-vertical.k-card-actions-end, .k-card-vertical .k-card-actions-vertical.k-card-actions-start {
    padding: 8px 16px
}

    .k-card-vertical .k-card-actions-vertical.k-card-actions-center .k-button, .k-card-vertical .k-card-actions-vertical.k-card-actions-end .k-button, .k-card-vertical .k-card-actions-vertical.k-card-actions-start .k-button {
        padding: 4px 8px
    }

.k-card-actions-stretched {
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row
}

    .k-card-actions-stretched .k-button {
        padding: 12px 16px;
        -ms-flex-positive: 1;
        flex-grow: 1;
        border-radius: 0
    }

        .k-card-actions-stretched .k-button.k-flat:first-child {
            margin-left: 0
        }

    .k-card-actions-stretched .k-card-action + .k-card-action {
        border-left-width: 1px
    }

    .k-card-actions-stretched.k-card-actions-vertical {
        -ms-flex-direction: column;
        flex-direction: column
    }

.k-card-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

    .k-card-list .k-card {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

        .k-card-list .k-card + .k-card {
            margin-top: 16px
        }

.k-card-deck {
    display: -ms-flexbox;
    display: flex;
    margin-top: 16px;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

    .k-card-deck .k-card {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

        .k-card-deck .k-card + .k-card {
            margin-left: 16px
        }

.k-card-deck-scrollwrap {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-align: center;
    align-items: center
}

    .k-card-deck-scrollwrap > .k-button {
        border-radius: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        position: absolute;
        z-index: 1
    }

        .k-card-deck-scrollwrap > .k-button:first-child {
            left: -1px
        }

        .k-card-deck-scrollwrap > .k-button:last-child {
            right: -1px
        }

    .k-card-deck-scrollwrap > .k-card-deck {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto
    }

.k-card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

    .k-card-group .k-card {
        border-radius: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

        .k-card-group .k-card > .k-card-header {
            border-radius: 0
        }

        .k-card-group .k-card + .k-card {
            margin-left: -1px
        }

        .k-card-group .k-card.k-first {
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px
        }

            .k-card-group .k-card.k-first > .k-card-header {
                border-top-left-radius: 4px
            }

        .k-card-group .k-card.k-last {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px
        }

            .k-card-group .k-card.k-last > .k-card-header {
                border-top-right-radius: 4px
            }

        .k-card-group .k-card.k-only {
            border-radius: 4px
        }

            .k-card-group .k-card.k-only > .k-card-header {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px
            }

.k-rtl .k-card-deck .k-card + .k-card, [dir=rtl] .k-card-deck .k-card + .k-card {
    margin-left: 0;
    margin-right: 16px
}

.k-ie11 .k-card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.k-hidden {
    display: none !important
}

.k-chat {
    height: 600px;
    max-height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    max-width: 500px;
    margin: auto
}

    .k-chat .k-message-list {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
        overflow-x: hidden;
        overflow-y: auto;
        scroll-behavior: smooth
    }

    .k-chat .k-message-list-content {
        padding: 16px 16px;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
        overflow: hidden
    }

        .k-chat .k-message-list-content > * + * {
            margin-top: 16px
        }

    .k-chat .k-message-group {
        max-width: 80%;
        background: 0 0;
        box-sizing: border-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative
    }

        .k-chat .k-message-group:not(.k-alt) {
            -ms-flex-align: start;
            align-items: flex-start;
            text-align: left
        }

            .k-chat .k-message-group:not(.k-alt) .k-message-time {
                margin-left: 8px;
                left: 100%
            }

            .k-chat .k-message-group:not(.k-alt) .k-message-status {
                left: 0
            }

            .k-chat .k-message-group:not(.k-alt) .k-first .k-bubble, .k-chat .k-message-group:not(.k-alt) .k-only .k-bubble {
                border-bottom-left-radius: 2px
            }

            .k-chat .k-message-group:not(.k-alt) .k-last .k-bubble, .k-chat .k-message-group:not(.k-alt) .k-middle .k-bubble {
                border-bottom-left-radius: 2px;
                border-top-left-radius: 2px
            }

        .k-chat .k-message-group.k-alt {
            -ms-flex-item-align: end;
            align-self: flex-end;
            -ms-flex-align: end;
            align-items: flex-end;
            text-align: right
        }

            .k-chat .k-message-group.k-alt .k-message-time {
                margin-right: 8px;
                right: 100%
            }

            .k-chat .k-message-group.k-alt .k-message-status {
                right: 0
            }

            .k-chat .k-message-group.k-alt .k-first .k-bubble, .k-chat .k-message-group.k-alt .k-only .k-bubble {
                border-bottom-right-radius: 2px
            }

            .k-chat .k-message-group.k-alt .k-last .k-bubble, .k-chat .k-message-group.k-alt .k-middle .k-bubble {
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px
            }

    .k-chat .k-message {
        max-width: 100%;
        margin: 2px 0 0;
        position: relative;
        transition: margin .2s ease-in-out
    }

    .k-chat .k-message-status, .k-chat .k-message-time {
        font-size: smaller;
        line-height: normal;
        white-space: nowrap;
        pointer-events: none;
        position: absolute
    }

    .k-chat .k-message-time {
        opacity: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        transition: opacity .2s ease-in-out
    }

    .k-chat .k-message-status {
        margin-top: 2px;
        height: 0;
        overflow: hidden;
        top: 100%;
        transition: height .2s ease-in-out
    }

    .k-chat .k-bubble {
        border-radius: 12px;
        padding: 8px 12px;
        border-width: 1px;
        border-style: solid;
        line-height: 18px;
        word-wrap: break-word
    }

    .k-chat .k-message.k-state-selected {
        margin-bottom: 16px;
        border: 0;
        color: inherit;
        background: 0 0
    }

        .k-chat .k-message.k-state-selected .k-message-time {
            opacity: 1
        }

        .k-chat .k-message.k-state-selected .k-message-status {
            height: 1.2em
        }

    .k-chat .k-message-error, .k-chat .k-message-sending {
        margin-bottom: 16px
    }

        .k-chat .k-message-error .k-message-status, .k-chat .k-message-sending .k-message-status {
            height: 1.2em
        }

.k-avatar {
    border-radius: 100%;
    width: 32px;
    height: 32px;
    position: absolute
}

.k-message-group:not(.k-alt) > .k-avatar {
    left: 0;
    bottom: 0
}

.k-message-group.k-alt > .k-avatar {
    right: 0;
    bottom: 0
}

.k-avatars .k-message-group:not(.k-alt):not(.k-no-avatar) {
    padding-left: 40px
}

.k-avatars .k-message-group.k-alt:not(.k-no-avatar) {
    padding-right: 40px
}

.k-author {
    margin: 0;
    font-size: smaller;
    line-height: normal
}

.k-chat .k-author {
    margin: 0
}

.k-timestamp {
    font-size: smaller;
    line-height: normal;
    text-align: center;
    -ms-flex-item-align: stretch;
    align-self: stretch
}

.k-quick-replies {
    display: block;
    max-width: 100%
}

.k-quick-reply {
    border-radius: 100px;
    margin-right: 8px;
    margin-bottom: 4px;
    padding: 8px 12px;
    border-width: 1px;
    border-style: solid;
    line-height: 18px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    transition-property: color,background-color,border-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out
}

.k-message-box {
    padding: 10px 16px;
    border-width: 1px 0 0;
    border-style: solid;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

    .k-message-box .k-input {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        background: 0 0;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto
    }

    .k-message-box .k-button {
        padding: 0
    }

        .k-message-box .k-button svg {
            width: 20px;
            height: 20px;
            fill: currentColor;
            display: inline-block
        }

        .k-message-box .k-button::after, .k-message-box .k-button::before {
            display: none
        }

.k-chat .k-card-list {
    margin: 2px 0 0
}

.k-chat .k-card-deck {
    max-width: calc(100% + 32px);
    box-sizing: border-box;
    margin-left: -16px;
    margin-right: -16px;
    padding: 16px 16px 16px;
    overflow: hidden;
    overflow-x: auto;
    scroll-behavior: smooth
}

    .k-chat .k-card-deck .k-card-wrap + .k-card-wrap {
        margin-left: 16px
    }

    .k-chat .k-card-deck .k-card, .k-chat .k-card-deck .k-card-wrap {
        width: 200px
    }

.k-card-deck-scrollwrap {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    overflow: hidden;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
    box-sizing: content-box
}

    .k-card-deck-scrollwrap > .k-card-deck {
        margin-bottom: -20px;
        padding-bottom: 20px
    }

.k-chat .k-card-deck .k-card-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

    .k-chat .k-card-deck .k-card-wrap .k-card {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

        .k-chat .k-card-deck .k-card-wrap .k-card + .k-card {
            margin-left: 16px
        }

    .k-chat .k-card-deck .k-card-wrap.k-state-selected {
        background: 0 0
    }

.k-typing-indicator {
    padding: 0;
    border-radius: 50px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

    .k-typing-indicator span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        -ms-flex: 0 0 8px;
        flex: 0 0 8px;
        background-color: currentColor
    }

        .k-typing-indicator span + span {
            margin-left: 5px
        }

@-webkit-keyframes k-animation-blink {
    50% {
        opacity: 1
    }
}

@keyframes k-animation-blink {
    50% {
        opacity: 1
    }
}

.k-chat .k-toolbar-box {
    width: 100%;
    border-width: 1px 0 0;
    border-style: solid;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

    .k-chat .k-toolbar-box .k-button-list {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        overflow: hidden;
        scroll-behavior: smooth;
        padding: 10px 16px
    }

        .k-chat .k-toolbar-box .k-button-list:after {
            content: '';
            padding-right: 16px
        }

        .k-chat .k-toolbar-box .k-button-list .k-button + .k-button {
            margin-left: 20px;
            -ms-flex-negative: 0;
            flex-shrink: 0
        }

    .k-chat .k-toolbar-box .k-scroll-button {
        position: absolute;
        z-index: 2;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    .k-chat .k-toolbar-box .k-scroll-button-left {
        left: 0
    }

    .k-chat .k-toolbar-box .k-scroll-button-right {
        right: 0
    }

    .k-chat .k-toolbar-box .k-button {
        border-width: 0;
        color: inherit;
        background: 0 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        box-shadow: none
    }

.k-rtl .k-message-group:not(.k-alt), [dir=rtl] .k-message-group:not(.k-alt) {
    text-align: right
}

    .k-rtl .k-message-group:not(.k-alt) .k-message-time, [dir=rtl] .k-message-group:not(.k-alt) .k-message-time {
        margin-left: 0;
        margin-right: 8px;
        left: auto;
        right: 100%
    }

    .k-rtl .k-message-group:not(.k-alt) .k-message-status, [dir=rtl] .k-message-group:not(.k-alt) .k-message-status {
        left: auto;
        right: 0
    }

.k-rtl .k-message-group.k-alt, [dir=rtl] .k-message-group.k-alt {
    text-align: left
}

    .k-rtl .k-message-group.k-alt .k-message-time, [dir=rtl] .k-message-group.k-alt .k-message-time {
        margin-right: 0;
        margin-left: 8px;
        right: auto;
        left: 100%
    }

    .k-rtl .k-message-group.k-alt .k-message-status, [dir=rtl] .k-message-group.k-alt .k-message-status {
        right: auto;
        left: 0
    }

.k-rtl .k-message-group:not(.k-alt) > .k-avatar, [dir=rtl] .k-message-group:not(.k-alt) > .k-avatar {
    left: auto;
    right: 0
}

.k-rtl .k-message-group.k-alt > .k-avatar, [dir=rtl] .k-message-group.k-alt > .k-avatar {
    right: auto;
    left: 0
}

.k-rtl .k-avatars .k-message-group:not(.k-alt):not(.k-no-avatar), [dir=rtl] .k-avatars .k-message-group:not(.k-alt):not(.k-no-avatar) {
    padding-left: 0;
    padding-right: 40px
}

.k-rtl .k-avatars .k-message-group.k-alt:not(.k-no-avatar), [dir=rtl] .k-avatars .k-message-group.k-alt:not(.k-no-avatar) {
    padding-right: 0;
    padding-left: 40px
}

.k-rtl .k-message-box .k-button svg, [dir=rtl] .k-message-box .k-button svg {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.k-rtl .k-quick-reply, [dir=rtl] .k-quick-reply {
    margin-right: 0;
    margin-left: 8px
}

.k-calendar {
    padding: 0 0;
    border-width: 1px;
    border-style: solid;
    line-height: 1.42857143;
    position: relative;
    overflow: hidden;
    display: inline-block
}

    .k-calendar .k-link {
        color: inherit;
        text-decoration: none;
        outline: 0
    }

    .k-calendar .k-action-link {
        text-decoration: underline
    }

    .k-calendar .k-state-focused, .k-calendar .k-state-focused.k-state-selected {
        box-shadow: none
    }

    .k-calendar table {
        margin: 0;
        padding: 0;
        border-spacing: 0;
        border-width: 0;
        outline: 0;
        table-layout: fixed;
        float: left
    }

    .k-calendar td, .k-calendar th {
        border-width: 0;
        padding: 0;
        text-align: center;
        border-style: solid;
        border-color: inherit;
        color: inherit;
        background: 0 0;
        font-weight: 400;
        cursor: default
    }

    .k-calendar .k-header {
        padding: 2px 2px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        text-align: center;
        position: relative;
        z-index: 1
    }

        .k-calendar .k-header .k-link {
            padding: 4px 4px;
            border-width: 0;
            border-style: solid;
            border-radius: 4px;
            display: inline-block
        }

    .k-calendar .k-nav-next, .k-calendar .k-nav-prev {
        width: 1.42857143em;
        height: 1.42857143em;
        box-sizing: content-box;
        position: absolute;
        top: 2px
    }

        .k-calendar .k-nav-next .k-icon, .k-calendar .k-nav-prev .k-icon {
            vertical-align: text-top
        }

    .k-calendar .k-nav-prev {
        left: 2px
    }

    .k-calendar .k-nav-next {
        right: 2px
    }

    .k-calendar .k-nav-fast {
        margin: auto;
        width: 75%;
        box-sizing: border-box
    }

    .k-calendar .k-footer {
        text-align: center;
        position: relative;
        clear: both
    }

        .k-calendar .k-footer > .k-state-disabled, .k-calendar .k-nav-today {
            padding: .5em;
            border-width: 0;
            border-radius: 0;
            display: block
        }

            .k-calendar .k-nav-today:hover {
                text-decoration: underline
            }

    .k-calendar .k-calendar-view {
        width: 17em;
        height: 17em;
        position: relative;
        z-index: 1;
        overflow: hidden
    }

    .k-calendar.k-week-number .k-calendar-view {
        width: 19.42857143em
    }

    .k-calendar .k-content {
        width: 100%;
        background-color: transparent
    }

        .k-calendar .k-content th {
            padding: .25em 2px;
            border-bottom-width: 1px;
            box-sizing: border-box
        }

        .k-calendar .k-content td {
            border-radius: 4px
        }

            .k-calendar .k-content td.k-alt {
                width: 2.42857143em;
                border-radius: 0
            }

        .k-calendar .k-content .k-link {
            margin: auto;
            padding: .5em .5em;
            box-sizing: border-box;
            border-radius: 4px;
            line-height: 1.42857143;
            display: block
        }

    .k-calendar .k-month .k-link {
        width: 2.42857143em;
        height: 2.42857143em
    }

    .k-calendar .k-month th {
        height: 2.42857143em
    }

    .k-calendar .k-year .k-link {
        width: 4.28571429em;
        height: 4.28571429em;
        line-height: 3.28571429em
    }

    .k-calendar .k-decade .k-link {
        width: 4.28571429em;
        height: 4.28571429em;
        line-height: 3.28571429em
    }

    .k-calendar .k-century .k-link {
        width: 4.28571429em;
        height: 4.28571429em;
        text-align: left;
        line-height: 1.64285714em;
        white-space: normal
    }

    .k-calendar .k-animation-container .k-content {
        height: 100%
    }

.k-week-number {
    width: 19.42857143em
}

.k-calendar-range {
    width: auto;
    text-align: left;
    line-height: 1.42857143
}

    .k-calendar-range .k-calendar-header {
        padding: 8px 16px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

        .k-calendar-range .k-calendar-header .k-button {
            white-space: nowrap;
            border-color: transparent !important;
            color: inherit;
            background: 0 0 !important;
            box-shadow: none !important;
            transition: color .2s ease-in-out
        }

            .k-calendar-range .k-calendar-header .k-button.k-state-active, .k-calendar-range .k-calendar-header .k-button.k-state-hover, .k-calendar-range .k-calendar-header .k-button:active, .k-calendar-range .k-calendar-header .k-button:hover, .k-calendar-range .k-calendar-header .k-button:hover.k-state-active, .k-calendar-range .k-calendar-header .k-button:hover:active {
                color: inherit
            }

            .k-calendar-range .k-calendar-header .k-button::before {
                display: block
            }

            .k-calendar-range .k-calendar-header .k-button::after {
                display: block
            }

            .k-calendar-range .k-calendar-header .k-button.k-state-focused::after, .k-calendar-range .k-calendar-header .k-button:focus::after {
                box-shadow: inset 0 0 0 2px currentColor;
                opacity: .12
            }

            .k-calendar-range .k-calendar-header .k-button.k-state-disabled, .k-calendar-range .k-calendar-header .k-button[disabled], .k-state-disabled .k-calendar-range .k-calendar-header .k-button {
                pointer-events: none
            }

    .k-calendar-range .k-calendar-nav {
        display: inline-block;
        white-space: nowrap
    }

    .k-calendar-range .k-calendar-view {
        height: auto;
        width: auto;
        min-height: 17em;
        text-align: center;
        white-space: nowrap
    }

.k-animation-container-sm .k-calendar-range .k-calendar-view {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.k-calendar-range.k-week-number .k-calendar-view {
    width: auto
}

.k-calendar-range .k-content {
    padding: 0 16px 8px;
    width: auto;
    height: auto;
    text-align: center;
    display: inline-table;
    vertical-align: top;
    float: none;
    color: inherit;
    background-color: transparent
}

    .k-calendar-range .k-content td, .k-calendar-range .k-content th {
        padding: 0;
        border-width: 0;
        color: inherit;
        background-color: transparent;
        background-image: none;
        text-align: inherit;
        vertical-align: middle
    }

    .k-calendar-range .k-content th {
        padding: .5em 0;
        font-size: 12px;
        line-height: 1.5;
        text-transform: uppercase;
        opacity: .5
    }

    .k-calendar-range .k-content td {
        border-radius: 4px
    }

        .k-calendar-range .k-content td.k-alt {
            opacity: .5
        }

    .k-calendar-range .k-content .k-link {
        padding: .5em .5em;
        min-height: 0;
        box-sizing: border-box;
        border-radius: 4px;
        line-height: inherit
    }

    .k-calendar-range .k-content .k-meta-header, .k-calendar-range .k-content .k-month-header {
        margin: 0 16px;
        padding: .5em 8px;
        font-size: 12px;
        line-height: 1.5;
        text-transform: uppercase;
        text-align: left;
        opacity: .5
    }

    .k-calendar-range .k-content td.k-range-start {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .k-calendar-range .k-content td.k-range-mid {
        border-radius: 0
    }

    .k-calendar-range .k-content td.k-range-end {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .k-calendar-range .k-content td.k-range-split-end, .k-calendar-range .k-content td.k-range-split-start {
        position: relative
    }

        .k-calendar-range .k-content td.k-range-split-end::after, .k-calendar-range .k-content td.k-range-split-start::after {
            content: "";
            width: 5px;
            display: block;
            position: absolute;
            top: 1px;
            bottom: 1px
        }

        .k-calendar-range .k-content td.k-range-split-start::after {
            right: auto;
            left: -5px
        }

        .k-calendar-range .k-content td.k-range-split-end::after {
            right: -5px;
            left: auto
        }

    .k-calendar-range .k-content td.k-weekend {
        background-color: transparent
    }

.k-calendar-range .k-state-selected {
    color: inherit;
    background: 0 0;
    border-color: transparent
}

.k-calendar-range .k-state-focused, .k-calendar-range .k-state-focused.k-state-selected {
    box-shadow: none
}

.k-calendar-range td.k-state-disabled {
    opacity: .5
}

    .k-calendar-range td.k-state-disabled .k-link {
        color: inherit
    }

.k-calendar-range .k-footer .k-nav-today {
    padding: 8px 16px;
    width: auto;
    height: auto;
    display: block
}

.k-widget.k-daterangepicker {
    border: 0;
    box-shadow: none
}

.k-calendar-monthview .k-content .k-link {
    width: 2.42857143em
}

.k-calendar-monthview .k-content tbody tr {
    height: 2.42857143em
}

.k-calendar-yearview .k-content {
    margin: 0 -1px
}

    .k-calendar-yearview .k-content .k-link {
        width: 4.28571429em;
        height: 4.28571429em;
        line-height: 3.25
    }

.k-calendar-decadeview .k-content {
    margin: 0 -1px
}

    .k-calendar-decadeview .k-content .k-link {
        width: 4.28571429em;
        height: 4.28571429em;
        line-height: 3.25
    }

.k-calendar-centuryview .k-content {
    margin: 0 -1px
}

    .k-calendar-centuryview .k-content .k-link {
        padding: .5em .5em;
        width: 4.28571429em;
        height: 4.28571429em;
        text-align: left;
        line-height: 1.625;
        white-space: normal
    }

.k-rtl .k-calendar-range {
    text-align: right
}

    .k-rtl .k-calendar-range .k-calendar-nav {
        float: left
    }

.k-pdf-viewer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden
}

    .k-pdf-viewer .k-toolbar {
        border-top-width: 0;
        border-right-width: 0;
        border-left-width: 0;
        border-color: inherit;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        z-index: 2
    }

    .k-pdf-viewer .k-pager-wrap {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding: 0;
        min-height: auto;
        -ms-flex-align: center;
        align-items: center;
        background: 0 0;
        color: inherit
    }

    .k-pdf-viewer .k-list-scroller {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        outline: 0
    }

        .k-pdf-viewer .k-list-scroller .k-enable-text-select {
            -webkit-user-select: text;
            -ms-user-select: text;
            user-select: text;
            cursor: text
        }

        .k-pdf-viewer .k-list-scroller .k-enable-panning {
            cursor: grab
        }

            .k-pdf-viewer .k-list-scroller .k-enable-panning span::selection {
                background-color: transparent
            }

    .k-pdf-viewer .k-page {
        position: relative;
        margin: 30px auto
    }

        .k-pdf-viewer .k-page canvas {
            direction: ltr
        }

        .k-pdf-viewer .k-page .k-text-layer {
            position: absolute;
            top: 0;
            left: 0;
            opacity: .2;
            overflow: hidden
        }

            .k-pdf-viewer .k-page .k-text-layer > span {
                position: absolute;
                -webkit-transform-origin: 0 0;
                -ms-transform-origin: 0 0;
                transform-origin: 0 0;
                color: transparent
            }

            .k-pdf-viewer .k-page .k-text-layer mark {
                color: transparent
            }

.k-pdf-viewer-window {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

    .k-pdf-viewer-window .k-edit-field .k-file-format, .k-pdf-viewer-window .k-edit-field .k-textbox {
        width: 100%
    }

    .k-pdf-viewer-window .k-action-buttons {
        top: 10px;
        padding: 10px;
        margin-bottom: calc(10px - 1em)
    }

.k-pdf-viewer-search-dialog {
    padding: .28571428em
}

    .k-pdf-viewer-search-dialog .k-search-container {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex-align: center;
        align-items: center
    }

        .k-pdf-viewer-search-dialog .k-search-container .k-textbox {
            display: -ms-inline-flexbox;
            display: inline-flex;
            -ms-flex-align: center;
            align-items: center;
            background: 0 0;
            padding-right: .17857143em;
            margin: 0 .28571428em
        }

            .k-pdf-viewer-search-dialog .k-search-container .k-textbox .k-icon {
                position: relative;
                top: auto;
                margin: 0;
                font-size: inherit
            }

            .k-pdf-viewer-search-dialog .k-search-container .k-textbox .k-search-dialog-input::-ms-clear {
                display: none
            }

            .k-pdf-viewer-search-dialog .k-search-container .k-textbox:after {
                content: ""
            }

        .k-pdf-viewer-search-dialog .k-search-container .k-match-case-button {
            height: 1.65em
        }

        .k-pdf-viewer-search-dialog .k-search-container .k-search-matches {
            display: inline-block;
            min-width: 4.5em;
            margin: 0 .28571428em 0 .28571428em;
            vertical-align: middle
        }

        .k-pdf-viewer-search-dialog .k-search-container > .k-button {
            margin-left: .28571428em
        }

        .k-pdf-viewer-search-dialog .k-search-container .k-search-dialog-draghandle {
            cursor: move;
            margin-left: 0
        }

.k-scrollview {
    position: relative;
    overflow: hidden;
    outline: 0;
    display: block
}

ul.k-scrollview-wrap {
    list-style-type: none;
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    cursor: default
}

    ul.k-scrollview-wrap img {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none
    }

    ul.k-scrollview-wrap > li {
        display: inline-block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0
    }

div.k-scrollview-wrap {
    white-space: nowrap
}

    div.k-scrollview-wrap > [data-role=page] {
        vertical-align: top;
        display: inline-block;
        min-height: 1px
    }

.k-scrollview-nav-wrap {
    position: absolute;
    bottom: 15px;
    left: 10px;
    right: 10px;
    height: 20px;
    overflow: hidden
}

.k-scrollview-nav {
    margin: 0;
    padding: 5px 0 0 0;
    display: block;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    text-align: center;
    list-style: none;
    pointer-events: initial
}

    .k-scrollview-nav > li.k-link {
        margin: 0 20px;
        padding: 0;
        width: 8px;
        height: 8px;
        box-sizing: content-box;
        vertical-align: top;
        display: inline-block;
        position: relative;
        border-width: 1px;
        border-style: solid;
        border-radius: 50%;
        cursor: pointer;
        pointer-events: all
    }

        .k-scrollview-nav > li.k-link::before {
            content: "";
            width: 28px;
            height: 20px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%)
        }

.k-scrollview-next, .k-scrollview-prev {
    display: table;
    position: absolute;
    padding: 0;
    height: 60%;
    top: 20%;
    text-decoration: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    overflow: hidden
}

.k-scrollview-prev {
    left: 0
}

.k-scrollview-next {
    right: 0
}

    .k-scrollview-next span, .k-scrollview-prev span {
        display: table-cell;
        margin: 0;
        padding: 0;
        vertical-align: middle;
        font-size: 4.5em;
        font-weight: 400;
        position: static
    }

.k-scrollview-elements {
    width: 100%
}

.k-scrollview-animation {
    transition-duration: .3s;
    transition-timing-function: ease-in-out
}

.k-i-arrowhead-e::before {
    content: "\e014"
}

.k-i-arrowhead-w::before {
    content: "\e016"
}

@supports (-webkit-user-select:none) {
    div.k-scrollview ul.k-scrollview-wrap li > * {
        pointer-events: auto
    }
}

@supports (not (-webkit-user-select:none)) {
    div.k-scrollview ul.k-scrollview-wrap li > * {
        pointer-events: none
    }
}

.k-switch {
    border-radius: 5em;
    cursor: pointer;
    width: 5em;
    background: 0 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    display: -ms-inline-flexbox;
    display: inline-flex;
    overflow: hidden;
    vertical-align: middle;
    font-size: 12px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left
}

    .k-switch [type=checkbox] {
        display: none
    }

.k-ie .k-switch {
    display: inline-block
}

.k-switch, .k-switch-container, .k-switch-handle {
    box-sizing: border-box
}

.k-switch-container {
    border-radius: 5em;
    padding: 2px 2px;
    border-width: 1px;
    border-style: solid;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    position: relative;
    outline: 0;
    transition: background-color .2s ease-out 0s
}

.k-ie .k-switch-container {
    display: block
}

.k-switch-handle {
    border-radius: 5em;
    width: 2em;
    height: 2em;
    border-width: 1px;
    border-style: solid;
    background-position: 50%;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    left: 0;
    transition: left .2s ease-out 0s
}

.k-ie11 .k-switch-handle {
    display: block
}

.k-switch-on .k-switch-handle {
    left: calc(100% - 2em)
}

.k-edge .k-switch-on .k-switch-handle, .k-ie .k-switch-on .k-switch-handle {
    left: 100%;
    margin-left: -2em
}

.k-switch-off .k-switch-handle {
    left: 0
}

.k-switch-label-off, .k-switch-label-on {
    display: inline;
    width: calc(100% - 2em - 12px);
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-shadow: none;
    line-height: 2em;
    overflow: hidden
}

.k-switch-label-on {
    text-align: left;
    left: 7px
}

.k-switch-label-off {
    text-align: right;
    right: 7px
}

.k-rtl .k-switch.k-switch-on .k-switch-handle, .k-switch[dir=rtl].k-switch-on .k-switch-handle {
    left: 0
}

.k-rtl .k-switch.k-switch-off .k-switch-handle, .k-switch[dir=rtl].k-switch-off .k-switch-handle {
    left: calc(100% - 2em)
}

.k-rtl .k-switch .k-switch-label-on, .k-switch[dir=rtl] .k-switch-label-on {
    text-align: right;
    left: initial;
    right: 7px
}

.k-rtl .k-switch .k-switch-label-off, .k-switch[dir=rtl] .k-switch-label-off {
    text-align: left;
    left: 7px;
    right: initial
}

.k-filter {
    display: inline-block;
    border-width: 0;
    box-shadow: none
}

    .k-filter ul {
        padding: 0
    }

        .k-filter ul li {
            list-style-type: none
        }

    .k-filter .k-filter-container, .k-filter .k-filter-preview {
        margin-bottom: 2.1em
    }

    .k-filter .k-filter-item, .k-filter .k-filter-lines {
        padding-left: 8px
    }

    .k-filter .k-filter-toolbar {
        display: -ms-inline-flexbox;
        display: inline-flex;
        position: relative;
        padding: 4px 0
    }

        .k-filter .k-filter-toolbar::before {
            content: "";
            position: absolute;
            width: 8px;
            height: 1px;
            top: 50%;
            left: -8px
        }

        .k-filter .k-filter-toolbar .k-toolbar {
            border-style: solid;
            border-width: 1px
        }

        .k-filter .k-filter-toolbar .k-filter-operator .k-dropdown {
            width: 15em
        }

        .k-filter .k-filter-toolbar .k-filter-toolbar-item > .k-textbox {
            margin: 0 .2em;
            vertical-align: middle
        }

    .k-filter .k-filter-item {
        position: relative
    }

        .k-filter .k-filter-item::before {
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            top: -4px;
            left: 0
        }

    .k-filter .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after {
        content: "";
        position: absolute;
        width: 1px;
        top: -5px;
        bottom: 50%;
        left: -8px
    }

    .k-filter .k-filter-group-main::before, .k-filter .k-filter-group-main > .k-filter-toolbar::after, .k-filter .k-filter-group-main > .k-filter-toolbar::before, .k-filter .k-filter-lines .k-filter-item:last-child::before {
        display: none
    }

    .k-filter[dir=rtl] .k-filter-item, .k-filter[dir=rtl] .k-filter-lines, .k-rtl .k-filter .k-filter-item, .k-rtl .k-filter .k-filter-lines, [dir=rtl] .k-filter .k-filter-item, [dir=rtl] .k-filter .k-filter-lines {
        padding-left: 0;
        padding-right: 8px
    }

    .k-filter[dir=rtl] .k-filter-toolbar::before, .k-rtl .k-filter .k-filter-toolbar::before, [dir=rtl] .k-filter .k-filter-toolbar::before {
        left: auto;
        right: -8px
    }

    .k-filter[dir=rtl] .k-filter-item::before, .k-rtl .k-filter .k-filter-item::before, [dir=rtl] .k-filter .k-filter-item::before {
        left: auto;
        right: 0
    }

    .k-filter[dir=rtl] .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after, .k-rtl .k-filter .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after, [dir=rtl] .k-filter .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after {
        left: auto;
        right: -8px
    }

.k-drawer-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-align: start;
    align-items: flex-start
}

.k-drawer {
    height: 100%;
    border-width: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    transition: all .3s ease-in-out
}

    .k-drawer-left.k-drawer-expanded .k-drawer, .k-drawer-left.k-drawer-mini .k-drawer, .k-drawer.k-drawer-start {
        border-right-width: 1px
    }

    .k-drawer-right.k-drawer-expanded .k-drawer, .k-drawer-right.k-drawer-mini .k-drawer, .k-drawer.k-drawer-end {
        border-left-width: 1px
    }

    .k-drawer-left .k-drawer, .k-drawer.k-drawer-start {
        top: 0;
        left: 0
    }

    .k-drawer-right .k-drawer, .k-drawer.k-drawer-end {
        top: 0;
        right: 0
    }

.k-drawer-content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.k-drawer-overlay .k-drawer {
    max-width: 80vw;
    position: fixed;
    z-index: 10002
}

.k-drawer-overlay .k-overlay {
    display: none
}

.k-drawer-push {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row
}

    .k-drawer-push .k-drawer {
        height: auto;
        position: relative;
        -ms-flex-item-align: stretch;
        align-self: stretch
    }

    .k-drawer-push.k-drawer-right .k-drawer {
        -ms-flex-order: 1;
        order: 1
    }

.k-drawer-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    transition: all .3s ease-in-out
}

    .k-drawer-wrapper::-webkit-scrollbar {
        width: 7px
    }

    .k-drawer-wrapper::-webkit-scrollbar-thumb {
        border-radius: 20px
    }

.k-drawer-items, .k-drawer-items ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: stretch;
    align-items: stretch
}

.k-drawer-item {
    padding: 8px 8px;
    color: inherit;
    font-size: 16px;
    line-height: 1.5;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    cursor: pointer
}

    .k-drawer-item .k-icon {
        margin: 0 8px;
        font-size: 18px;
        -ms-flex-negative: 0;
        flex-shrink: 0
    }

    .k-drawer-item .k-item-text {
        padding: 0 8px;
        display: inline-block;
        white-space: nowrap
    }

.k-drawer-separator {
    padding: 0;
    height: 1px
}

.k-drawer-mini .k-drawer-wrapper {
    width: 50px
}

.k-rtl .k-drawer-left.k-drawer-push .k-drawer, [dir=rtl] .k-drawer-left.k-drawer-push .k-drawer {
    -ms-flex-order: 1;
    order: 1
}

.k-rtl .k-drawer-right.k-drawer-push .k-drawer, [dir=rtl] .k-drawer-right.k-drawer-push .k-drawer {
    -ms-flex-order: 0;
    order: 0
}

.k-rating {
    display: -ms-inline-flexbox;
    display: inline-flex;
    box-sizing: border-box;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 14px;
    box-shadow: none;
    outline: 0
}

.k-rating-container {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    position: relative;
    box-sizing: border-box;
    margin: 0 4px
}

    .k-rating-container .k-rating-item {
        display: -ms-inline-flexbox;
        display: inline-flex;
        position: relative;
        padding: 4px 4px;
        -ms-flex-align: center;
        align-items: center;
        vertical-align: middle;
        margin: 0;
        outline: 0;
        overflow: hidden;
        transition: color .2s ease-out 0s
    }

        .k-rating-container .k-rating-item * {
            pointer-events: none
        }

        .k-rating-container .k-rating-item .k-icon {
            font-size: 24px;
            overflow: visible
        }

        .k-rating-container .k-rating-item .k-rating-precision-complement, .k-rating-container .k-rating-item .k-rating-precision-part {
            display: inline-block;
            position: absolute;
            top: 4px;
            left: 4px;
            overflow: hidden
        }

        .k-rating-container .k-rating-item .k-rating-precision-complement {
            direction: rtl
        }

.k-rating-label {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    line-height: 1.5;
    margin: 4px 8px
}

.k-ie9 .k-rating-container, .k-ie9 .k-rating-item, .k-ie9 .k-rating-label {
    display: inline-block
}

.k-rtl .k-rating-precision-part, [dir=rtl] .k-rating-precision-part {
    left: auto;
    right: 4px
}

.k-rtl .k-rating-item .k-rating-precision-complement, [dir=rtl] .k-rating-item .k-rating-precision-complement {
    direction: ltr
}

.k-timeline ul {
    margin: 0;
    padding: 0
}

    .k-timeline ul li {
        list-style-type: none
    }

.k-timeline .k-timeline-flag {
    display: inline-block;
    text-align: center;
    padding: 4px 8px;
    border-radius: 2px;
    line-height: 1.42857143;
    min-width: 80px;
    max-width: 160px;
    box-sizing: border-box;
    position: relative;
    z-index: 1
}

.k-timeline .k-timeline-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    z-index: 1
}

.k-timeline.k-timeline-dates-hidden .k-timeline-date {
    display: none
}

.k-timeline .k-event-collapse {
    display: none
}

.k-timeline.k-timeline-collapsible .k-card-header {
    cursor: pointer
}

.k-timeline.k-timeline-collapsible .k-event-collapse {
    display: -ms-flexbox;
    display: flex
}

.k-timeline .k-timeline-arrow {
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 50%;
    position: absolute;
    bottom: 8px;
    z-index: 3;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center
}

.k-timeline .k-timeline-arrow-left {
    left: 0
}

.k-timeline .k-timeline-arrow-right {
    right: 0
}

.k-timeline-card .k-card {
    position: relative;
    overflow: visible
}

    .k-timeline-card .k-card .k-card-header {
        overflow: visible;
        border-bottom: 0;
        border-radius: 4px
    }

        .k-timeline-card .k-card .k-card-header + .k-card-body {
            padding-top: 0
        }

    .k-timeline-card .k-card .k-card-body {
        overflow-y: auto;
        scrollbar-width: thin
    }

        .k-timeline-card .k-card .k-card-body::-webkit-scrollbar {
            width: 5px
        }

        .k-timeline-card .k-card .k-card-body::-webkit-scrollbar-thumb {
            border-radius: 2px
        }

.k-timeline-card .k-timeline-card-callout.k-callout-e, .k-timeline-card .k-timeline-card-callout.k-callout-w {
    top: 36px
}

.k-timeline-horizontal, .k-timeline-vertical {
    width: 100%;
    padding: 0 40px;
    margin: 40px 0;
    position: relative;
    box-sizing: border-box;
    border: 0
}

    .k-timeline-horizontal .k-timeline-track-wrap::after, .k-timeline-vertical::after {
        content: "";
        position: absolute;
        top: 0;
        border-width: 1px;
        border-style: solid;
        background-clip: padding-box
    }

.k-timeline-vertical {
    padding-left: 127px
}

    .k-timeline-vertical.k-timeline-dates-hidden {
        padding-left: 77px
    }

    .k-timeline-vertical::after {
        height: 100%;
        width: 6px;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        border-radius: 4px
    }

    .k-timeline-vertical .k-timeline-flag-wrap {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }

        .k-timeline-vertical .k-timeline-flag-wrap:first-child {
            padding-top: 40px
        }

    .k-timeline-vertical .k-timeline-flag {
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .k-timeline-vertical .k-timeline-date-wrap {
        position: absolute;
        padding-right: 16px;
        margin-top: 36px;
        -webkit-transform: translate(-100%,-50%);
        -ms-transform: translate(-100%,-50%);
        transform: translate(-100%,-50%);
        text-align: right
    }

    .k-timeline-vertical .k-timeline-event {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: start;
        align-items: flex-start;
        padding: 40px 0
    }

    .k-timeline-vertical .k-timeline-card {
        padding-left: 16px
    }

        .k-timeline-vertical .k-timeline-card .k-card {
            margin-left: 16px;
            min-height: 72px;
            width: 400px;
            max-width: 100%
        }

            .k-timeline-vertical .k-timeline-card .k-card .k-card-title {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-pack: justify;
                justify-content: space-between
            }

                .k-timeline-vertical .k-timeline-card .k-card .k-card-title .k-event-title {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden
                }

        .k-timeline-vertical .k-timeline-card .k-event-collapse {
            margin: -4px 0;
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
            transition: -webkit-transform .2s ease-in-out;
            transition: transform .2s ease-in-out;
            transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out
        }

        .k-timeline-vertical .k-timeline-card.k-collapsed .k-card-actions, .k-timeline-vertical .k-timeline-card.k-collapsed .k-card-body {
            display: none
        }

        .k-timeline-vertical .k-timeline-card.k-collapsed .k-event-collapse {
            -webkit-transform: rotate(0);
            -ms-transform: rotate(0);
            transform: rotate(0)
        }

    .k-timeline-vertical .k-timeline-circle {
        margin-top: 36px;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        margin-right: -8px
    }

    .k-timeline-vertical.k-timeline-alternating {
        padding-left: 40px
    }

        .k-timeline-vertical.k-timeline-alternating::after {
            left: 50%
        }

        .k-timeline-vertical.k-timeline-alternating .k-timeline-flag-wrap {
            -ms-flex-pack: center;
            justify-content: center
        }

        .k-timeline-vertical.k-timeline-alternating .k-timeline-flag {
            -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0)
        }

        .k-timeline-vertical.k-timeline-alternating .k-timeline-event {
            -ms-flex-pack: justify;
            justify-content: space-between
        }

            .k-timeline-vertical.k-timeline-alternating .k-timeline-event.k-reverse {
                -ms-flex-direction: row-reverse;
                flex-direction: row-reverse
            }

                .k-timeline-vertical.k-timeline-alternating .k-timeline-event.k-reverse .k-timeline-date-wrap {
                    text-align: left;
                    padding-right: 0;
                    padding-left: 16px
                }

                .k-timeline-vertical.k-timeline-alternating .k-timeline-event.k-reverse .k-timeline-card {
                    padding-left: 0;
                    padding-right: 16px
                }

                    .k-timeline-vertical.k-timeline-alternating .k-timeline-event.k-reverse .k-timeline-card .k-card {
                        margin-right: 16px;
                        margin-left: auto
                    }

        .k-timeline-vertical.k-timeline-alternating .k-timeline-card, .k-timeline-vertical.k-timeline-alternating .k-timeline-date-wrap {
            -ms-flex-preferred-size: 50%;
            flex-basis: 50%;
            min-width: 0
        }

        .k-timeline-vertical.k-timeline-alternating .k-timeline-date-wrap {
            position: static;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%)
        }

        .k-timeline-vertical.k-timeline-alternating .k-timeline-circle {
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            margin-right: 0
        }

.k-timeline-horizontal .k-timeline-track-item {
    -ms-flex: 1 0 20%;
    flex: 1 0 20%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: start;
    justify-content: flex-start;
    position: relative;
    -ms-flex-direction: column;
    flex-direction: column
}

    .k-timeline-horizontal .k-timeline-track-item:hover {
        cursor: pointer
    }

    .k-timeline-horizontal .k-timeline-track-item.k-timeline-flag-wrap:hover {
        cursor: default
    }

.k-timeline-horizontal .k-timeline-date-wrap {
    margin-top: auto;
    margin-bottom: 8px
}

.k-timeline-horizontal .k-timeline-card {
    height: 100%;
    box-sizing: border-box
}

.k-timeline-horizontal .k-card {
    max-height: 100%;
    box-sizing: border-box
}

.k-timeline-horizontal .k-timeline-events-list {
    overflow-x: hidden;
    padding-top: 16px
}

    .k-timeline-horizontal .k-timeline-events-list .k-timeline-scrollable-wrap {
        position: relative;
        height: 600px
    }

        .k-timeline-horizontal .k-timeline-events-list .k-timeline-scrollable-wrap .k-timeline-event {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%
        }

.k-timeline-horizontal .k-timeline-track-wrap {
    position: relative;
    padding: 15px 0
}

    .k-timeline-horizontal .k-timeline-track-wrap::after {
        top: auto;
        bottom: 15px;
        left: 28px;
        right: 28px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 6px
    }

    .k-timeline-horizontal .k-timeline-track-wrap .k-timeline-track {
        overflow: hidden;
        margin: 0 30px;
        position: relative;
        z-index: 2
    }

        .k-timeline-horizontal .k-timeline-track-wrap .k-timeline-track .k-timeline-scrollable-wrap {
            transition: -webkit-transform 1s ease-in-out;
            transition: transform 1s ease-in-out;
            transition: transform 1s ease-in-out,-webkit-transform 1s ease-in-out
        }

    .k-timeline-horizontal .k-timeline-track-wrap .k-timeline-flag {
        margin-bottom: 22px;
        position: relative;
        min-width: 60px
    }

        .k-timeline-horizontal .k-timeline-track-wrap .k-timeline-flag::after {
            content: "";
            width: 10px;
            height: 10px;
            position: absolute;
            left: 50%;
            bottom: 0;
            -webkit-transform: translate(-50%,50%) rotate(45deg);
            -ms-transform: translate(-50%,50%) rotate(45deg);
            transform: translate(-50%,50%) rotate(45deg)
        }

.k-timeline-horizontal .k-timeline-scrollable-wrap {
    display: -ms-flexbox;
    display: flex
}

.k-timeline-mobile {
    padding: 0 16px;
    margin: 16px 0
}

    .k-timeline-mobile .k-timeline-flag {
        max-width: 112px
    }

    .k-timeline-mobile.k-timeline-horizontal .k-timeline-flag-wrap {
        display: none
    }

    .k-timeline-mobile.k-timeline-vertical {
        padding-left: 103px
    }

        .k-timeline-mobile.k-timeline-vertical.k-timeline-dates-hidden {
            padding-left: 53px
        }

        .k-timeline-mobile.k-timeline-vertical.k-timeline-alternating {
            padding-left: 16px
        }

        .k-timeline-mobile.k-timeline-vertical .k-timeline-card {
            width: 100%
        }

            .k-timeline-mobile.k-timeline-vertical .k-timeline-card .k-card {
                width: auto
            }

        .k-timeline-mobile.k-timeline-vertical .k-card-actions > .k-button.k-flat:first-child {
            margin-left: 0
        }

.k-breadcrumb {
    box-sizing: border-box;
    border: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    background-color: transparent;
    min-height: 2.11em
}

    .k-breadcrumb .k-breadcrumb-container {
        margin: 0 0;
        padding: 0 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        list-style: none;
        overflow: hidden
    }

    .k-breadcrumb .k-breadcrumb-item {
        vertical-align: middle;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: center;
        align-items: center;
        overflow: hidden
    }

    .k-breadcrumb .k-breadcrumb-root-item {
        -ms-flex: 1 0 auto;
        flex: 1 0 auto
    }

    .k-breadcrumb .k-breadcrumb-link, .k-breadcrumb .k-breadcrumb-root-link {
        padding: 4px 8px;
        border-radius: 4px;
        position: relative;
        line-height: 1.6em;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-item-align: stretch;
        align-self: stretch;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
        outline: 0
    }

    .k-breadcrumb .k-breadcrumb-root-link {
        margin-right: 8px
    }

    .k-breadcrumb .k-breadcrumb-icon-link {
        padding: 8px 8px
    }

    .k-breadcrumb .k-breadcrumb-icontext-link .k-icon {
        margin-right: 8px
    }

    .k-breadcrumb .k-breadcrumb-delimiter-icon {
        font-size: 12px
    }

    .k-breadcrumb .k-breadcrumb-input-container, .k-breadcrumb .k-breadcrumb-input-container .k-textbox {
        width: 100%;
        height: 100%
    }

.k-no-flexbox .k-breadcrumb, .k-no-flexbox .k-breadcrumb-container {
    display: block
}

.k-no-flexbox .k-breadcrumb-item {
    display: inline-block
}

.k-listgroup {
    margin: 0;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    font: inherit;
    line-height: 1.42857143;
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    overflow: hidden
}

    .k-listgroup > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .k-listgroup .k-listgroup-item + .k-listgroup-item {
        border-top-width: 1px
    }

.k-listgroup-flush {
    border-left-width: 0;
    border-right-width: 0
}

.k-listgroup-item {
    padding: .5em 1em;
    border-width: 0;
    border-style: solid;
    border-color: inherit;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    position: relative
}

    .k-listgroup-item > .k-link {
        margin: -.5em -1em;
        padding: .5em 1em;
        color: inherit;
        text-decoration: none;
        outline: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        position: relative
    }

        .k-listgroup-item > .k-link > .k-select {
            padding: .5em;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            position: absolute;
            top: 50%;
            right: 0;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%)
        }

.k-listgroup-item-segmented {
    -ms-flex-align: stretch;
    align-items: stretch
}

    .k-listgroup-item-segmented > .k-link {
        margin-right: 0
    }

    .k-listgroup-item-segmented > .k-select {
        margin: -.5em -1em -.5em 0;
        padding: .5em;
        border-width: 0 0 0 1px;
        border-style: solid;
        border-color: inherit;
        cursor: pointer
    }

.k-listgroup-form-row {
    margin: -.5em -1em;
    padding: .5em 1em;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    position: relative
}

    .k-listgroup-form-row .k-listgroup-form-field-label {
        width: 40%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .k-listgroup-form-row .k-listgroup-form-field-wrapper {
        width: 50%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: end;
        justify-content: flex-end;
        text-align: end;
        position: relative
    }

        .k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=text], .k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=number], .k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=password], .k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=date], .k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=time], .k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=datetime-local], .k-listgroup-form-row .k-listgroup-form-field-wrapper select {
            width: 100%;
            box-sizing: border-box;
            -ms-flex: 1 1 100%;
            flex: 1 1 100%
        }

.k-listgroup-item.k-listgroup-form-row {
    margin: 0
}

.k-rtl .k-listgroup-item > .k-link > .k-select, [dir=rtl] .k-listgroup-item > .k-link > .k-select {
    right: auto;
    left: 0
}

    .k-rtl .k-listgroup-item > .k-link > .k-select .k-icon, [dir=rtl] .k-listgroup-item > .k-link > .k-select .k-icon {
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1);
        transform: scaleX(-1)
    }

.k-rtl .k-listgroup-item-segmented > .k-link, [dir=rtl] .k-listgroup-item-segmented > .k-link {
    margin-right: -1em;
    margin-left: 0
}

.k-rtl .k-listgroup-item-segmented > .k-select, [dir=rtl] .k-listgroup-item-segmented > .k-select {
    margin-right: 0;
    margin-left: -1em;
    border-right-width: 1px;
    border-left-width: 0
}

    .k-rtl .k-listgroup-item-segmented > .k-select .k-icon, [dir=rtl] .k-listgroup-item-segmented > .k-select .k-icon {
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1);
        transform: scaleX(-1)
    }

.k-noflexbox .k-listgroup, .k-noflexbox .k-listgroup-item > .k-link, .k-noflexbox .k-listgroup > ul .k-listgroup-item {
    display: block
}

.k-badge {
    margin: 0 0 0 .5em;
    padding: 4px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    font-size: 75%;
    line-height: 1;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

    .k-badge[dir=rtl], .k-rtl .k-badge, [dir=rtl] .k-badge {
        margin: 0 .5em 0 0
    }

    .k-badge > * {
        color: inherit
    }

    .k-badge.k-badge-pill {
        min-width: calc(1em + 10px);
        min-height: calc(1em + 10px);
        border-radius: 5rem
    }

        .k-badge.k-badge-pill:empty {
            min-width: auto;
            min-height: auto
        }

    .k-badge > a, a.k-badge {
        text-decoration: none
    }

        .k-badge > a:hover, a.k-badge:hover {
            text-decoration: underline
        }

.k-badge-overlay {
    position: relative;
    overflow: visible
}

    .k-badge-overlay .k-badge {
        margin: 0;
        position: absolute;
        top: calc(.5em + 5px);
        right: calc(.5em + 5px);
        z-index: 9999;
        -webkit-transform: translate(100%,-100%);
        -ms-transform: translate(100%,-100%);
        transform: translate(100%,-100%)
    }

        .k-badge-overlay .k-badge:empty {
            top: 5px;
            right: 5px
        }

        .k-badge-overlay .k-badge[dir=rtl], .k-rtl .k-badge-overlay .k-badge, [dir=rtl] .k-badge-overlay .k-badge {
            right: auto;
            left: calc(.5em + 5px);
            -webkit-transform: translate(-100%,-100%);
            -ms-transform: translate(-100%,-100%);
            transform: translate(-100%,-100%)
        }

            .k-badge-overlay .k-badge[dir=rtl]:empty, .k-rtl .k-badge-overlay .k-badge:empty, [dir=rtl] .k-badge-overlay .k-badge:empty {
                left: 5px
            }

.k-checkbox {
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    line-height: initial;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    -webkit-appearance: none
}

    .k-checkbox::before {
        content: "\e118";
        width: 12px;
        height: 12px;
        font-size: 12px;
        font-family: WebComponentsIcons,monospace;
        -webkit-transform: scale(0) translate(-50%,-50%);
        -ms-transform: scale(0) translate(-50%,-50%);
        transform: scale(0) translate(-50%,-50%);
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%
    }

    .k-checkbox:hover {
        cursor: pointer
    }

    .k-checkbox:checked::before {
        -webkit-transform: scale(1) translate(-50%,-50%);
        -ms-transform: scale(1) translate(-50%,-50%);
        transform: scale(1) translate(-50%,-50%)
    }

    .k-checkbox.k-state-indeterminate::before, .k-checkbox:indeterminate::before {
        content: "";
        width: 8px;
        height: 8px;
        background-color: currentColor;
        -webkit-transform: scale(1) translate(-50%,-50%);
        -ms-transform: scale(1) translate(-50%,-50%);
        transform: scale(1) translate(-50%,-50%)
    }

    .k-checkbox:disabled, .k-checkbox:disabled + .k-checkbox-label {
        opacity: .6;
        -webkit-filter: grayscale(.8);
        filter: grayscale(.8);
        cursor: default
    }

.k-checkbox-label {
    margin: 0;
    padding: 0;
    line-height: 17px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: start;
    align-items: flex-start;
    vertical-align: middle;
    position: relative;
    cursor: pointer
}

    .k-checkbox-label:empty {
        display: none
    }

    .k-checkbox-label.k-no-text {
        min-width: 1px
    }

    .k-checkbox-label .k-ripple {
        visibility: hidden !important
    }

.k-checkbox + .k-checkbox-label {
    margin-left: 4px
}

.k-checkbox-label > .k-checkbox {
    margin-right: 4px
}

.k-rtl .k-checkbox + .k-checkbox-label, [dir=rtl] .k-checkbox + .k-checkbox-label {
    margin-left: 0;
    margin-right: 4px
}

.k-rtl .k-checkbox-label > .k-checkbox, [dir=rtl] .k-checkbox-label > .k-checkbox {
    margin-right: 0;
    margin-left: 4px
}

.k-checkbox::-ms-check {
    border-width: 0;
    border-color: inherit;
    border-radius: inherit;
    color: inherit;
    background-color: inherit
}

@media screen and (-ms-high-contrast:active),(-ms-high-contrast:none) {
    .k-checkbox::-ms-check {
        border-width: 1px
    }
}

.k-radio {
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    -webkit-appearance: none
}

    .k-radio::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: currentColor;
        -webkit-transform: scale(0) translate(-50%,-50%);
        -ms-transform: scale(0) translate(-50%,-50%);
        transform: scale(0) translate(-50%,-50%);
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%
    }

    .k-radio:hover {
        cursor: pointer
    }

    .k-radio:checked::before {
        -webkit-transform: scale(1) translate(-50%,-50%);
        -ms-transform: scale(1) translate(-50%,-50%);
        transform: scale(1) translate(-50%,-50%)
    }

    .k-radio:disabled, .k-radio:disabled + .k-radio-label {
        opacity: .6;
        -webkit-filter: grayscale(.8);
        filter: grayscale(.8);
        cursor: default
    }

.k-radio-label {
    margin: 0;
    padding: 0;
    line-height: 16px + 1px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: start;
    align-items: flex-start;
    vertical-align: middle;
    position: relative;
    cursor: pointer
}

    .k-radio-label.k-no-text, .k-radio-label:empty {
        display: none
    }

    .k-radio-label .k-ripple {
        display: none !important
    }

.k-radio + .k-radio-label {
    margin-left: 4px
}

.k-radio-label > .k-radio {
    margin-right: 4px
}

.k-rtl .k-radio + .k-radio-label, [dir=rtl] .k-radio + .k-radio-label {
    margin-left: 0;
    margin-right: 4px
}

.k-rtl .k-radio-label > .k-radio, [dir=rtl] .k-radio-label > .k-radio {
    margin-right: 0;
    margin-left: 4px
}

.k-radio::-ms-check {
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background-color: inherit
}

@media screen and (-ms-high-contrast:active),(-ms-high-contrast:none) {
    .k-radio::-ms-check {
        border-width: 1px
    }
}

.k-filemanager {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.k-filemanager-toolbar {
    border-width: 0 0 1px 0;
    border-style: solid
}

.k-filemanager-details-toggle, .k-filemanager-details-toggle .k-switch, .k-filemanager-search-tool {
    margin-left: .2em;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.k-filemanager-search-tool {
    margin-right: .2em
}

.k-filemanager-toolbar .k-button, .k-filemanager-toolbar .k-filemanager-details-toggle, .k-filemanager-toolbar .k-slit-button {
    font-weight: 400
}

.k-filemanager-content-container {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow: hidden
}

.k-filemanager-navigation {
    box-sizing: border-box;
    width: 20%;
    overflow-x: auto;
    border-width: 0 1px 0 0;
    border-style: solid
}

.k-filemanager-navigation-container {
    box-sizing: border-box;
    padding: 16px 0
}

.k-filemanager-treeview {
    margin: 0 16px;
    display: inline-block
}

.k-filemanager-content {
    -ms-flex: 1;
    flex: 1;
    overflow: hidden
}

.k-filemanager-breadcrumb {
    border-width: 0 0 1px 0;
    border-style: solid;
    padding: 4px 8px
}

.k-filemanager-view-list {
    overflow-y: auto
}

.k-filemanager-listview {
    border-width: 0;
    position: initial;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
}

    .k-filemanager-listview .k-item {
        padding: 8px 8px;
        width: 106px;
        height: 80px
    }

    .k-filemanager-listview .file-group-icon {
        padding: 8px 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: center;
        justify-content: center
    }

    .k-filemanager-listview .file-name {
        display: block;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .k-filemanager-listview .k-icon {
        font-size: 32px
    }

    .k-filemanager-listview .file-edit-input input {
        width: 100%
    }

.k-filemanager-view {
    position: relative
}

.k-filemanager-grid {
    border-width: 1px 0 0 0;
    position: initial
}

    .k-filemanager-grid thead tr {
        height: 46px
    }

    .k-filemanager-grid tbody tr {
        height: 46px
    }

    .k-filemanager-grid .k-header {
        vertical-align: middle
    }

    .k-filemanager-grid .file-group-icon, .k-filemanager-grid .file-name {
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-direction: row;
        flex-direction: row;
        vertical-align: middle
    }

    .k-filemanager-grid .file-group-icon {
        margin-right: 16px
    }

.k-filemanager-preview {
    border-width: 0 0 0 1px;
    border-style: solid;
    width: 20%;
    overflow-x: auto
}

.k-filemanager-preview-container {
    box-sizing: border-box;
    padding: 96px 0 16px 0;
    width: 100%;
    height: 100%;
    text-align: center
}

    .k-filemanager-preview-container .k-file-info {
        margin: 0 20px;
        display: inline-block
    }

    .k-filemanager-preview-container .k-file-preview-wrapper, .k-filemanager-preview-container .k-file-title-wrapper {
        text-align: center
    }

        .k-filemanager-preview-container .k-file-preview-wrapper .k-icon {
            font-size: 64px
        }

    .k-filemanager-preview-container .k-file-title-wrapper {
        padding: 16px 0
    }

    .k-filemanager-preview-container .k-file-title {
        font-weight: 700
    }

    .k-filemanager-preview-container .k-file-meta-wrapper {
        box-sizing: border-box;
        margin: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .k-filemanager-preview-container .k-file-meta-label {
        display: inline-block
    }

    .k-filemanager-preview-container .k-file-meta-value {
        margin: 0
    }

    .k-filemanager-preview-container .k-file-size {
        line-height: normal;
        font-size: inherit
    }

    .k-filemanager-preview-container .k-file-type {
        text-transform: capitalize
    }

    .k-filemanager-preview-container .line-break {
        margin-top: 16px;
        width: 100%;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

.k-filemanager .k-splitbar {
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    cursor: e-resize;
    width: 5px;
    border-width: 0 1px
}

.k-filemanager-upload-dialog .k-upload-files {
    max-height: 200px;
    overflow-y: auto
}

.k-filemanager-drag-hint {
    padding: 4px 8px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center
}

    .k-filemanager-drag-hint .k-icon {
        margin-right: 8px
    }

@font-face {
    font-family: "DejaVu Sans";
    src: url(fonts/DejaVu/DejaVuSans.ttf?v=1.1) format("truetype")
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: 700;
    src: url(fonts/DejaVu/DejaVuSans-Bold.ttf?v=1.1) format("truetype")
}

@font-face {
    font-family: "DejaVu Sans";
    font-style: italic;
    src: url(fonts/DejaVu/DejaVuSans-Oblique.ttf?v=1.1) format("truetype")
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: 700;
    font-style: italic;
    src: url(fonts/DejaVu/DejaVuSans-BoldOblique.ttf?v=1.1) format("truetype")
}

@font-face {
    font-family: "DejaVu Serif";
    src: url(fonts/DejaVu/DejaVuSerif.ttf?v=1.1) format("truetype")
}

@font-face {
    font-family: "DejaVu Serif";
    font-weight: 700;
    src: url(fonts/DejaVu/DejaVuSerif-Bold.ttf?v=1.1) format("truetype")
}

@font-face {
    font-family: "DejaVu Serif";
    font-style: italic;
    src: url(fonts/DejaVu/DejaVuSerif-Italic.ttf?v=1.1) format("truetype")
}

@font-face {
    font-family: "DejaVu Serif";
    font-weight: 700;
    font-style: italic;
    src: url(fonts/DejaVu/DejaVuSerif-BoldItalic.ttf?v=1.1) format("truetype")
}

@font-face {
    font-family: "DejaVu Mono";
    src: url(fonts/DejaVu/DejaVuSansMono.ttf?v=1.1) format("truetype")
}

@font-face {
    font-family: "DejaVu Mono";
    font-weight: 700;
    src: url(fonts/DejaVu/DejaVuSansMono-Bold.ttf?v=1.1) format("truetype")
}

@font-face {
    font-family: "DejaVu Mono";
    font-style: italic;
    src: url(fonts/DejaVu/DejaVuSansMono-Oblique.ttf?v=1.1) format("truetype")
}

@font-face {
    font-family: "DejaVu Mono";
    font-weight: 700;
    font-style: italic;
    src: url(fonts/DejaVu/DejaVuSansMono-BoldOblique.ttf?v=1.1) format("truetype")
}

.kendo-pdf-hide-pseudo-elements:after, .kendo-pdf-hide-pseudo-elements:before {
    display: none !important
}

.k-pdf-export sub, .k-pdf-export sup {
    display: inline-block
}
/*# sourceMappingURL=kendo.common.min.css.map */
